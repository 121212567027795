import React, { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Table, Tabs, Empty } from 'antd';

import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { userInfo } from '../../services/UsersService';
import { getAllOrganizations } from '../../services/OrganizationsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddOrganization } from './AddOrganization';

export function OrganizationsHome(props) {
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [showAddOrganization, setShowAddOrganization] = useState(false);

    const [organizations, setOrganizations] = useState({
        data: [],
        loaded: false
    });

    useEffect(() => {
        if (uInfo?.users_ID) {
            if (uInfo.userType !== 'SuperAdmin') {
                navigate('/dashboard', { replace: true });
            } else {
                getAllOrganizations(uInfo.users_ID).then(data => {
                    setOrganizations({
                        loaded: true,
                        data
                    });

                    setLoading(false);
                });
            }
        }
    }, [uInfo]);

    useEffect(() => {
        var alive = true;
        setLoading(true);

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo]) => {

            if (userInfo?.users_ID) {
                if (!uInfo.users_ID) { //prevent reload of user info
                    setUInfo(userInfo);
                }
            }

        });

        return () => { alive = false; }
    }, []);

    function navigateToOrganizationDashboard(row) {
        navigate("/organization-dashboard",
            {
                replace: true,
                state: { selectedOrganization: row }
            });
    }


    const columns = [
        {
            title: 'Organization',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (cell, row) => cell ? <em className="text-success">Active</em> : <em className="text-danger">Inactive</em>
        }
    ];

    return (
        <>
            <div className="d-flex justify-content-end">
                <button className="ant-btn ant-btn-primary float-end mb-4" onClick={() => { setShowAddOrganization(true) }}>
                    <i className="far fa-plus-square"></i>
                    &nbsp;
                    Add Organization
                </button>
            </div>
            <div className="card-container">
                <div>
                    {loading ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <div>
                            {organizations.data.length > 0 ?
                                <div className="email-table">
                                    <Table
                                        rowKey='organizations_ID'
                                        dataSource={organizations.data}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { navigateToOrganizationDashboard(record) }, // click row
                                                onDoubleClick: event => { navigateToOrganizationDashboard(record) }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }} />
                                </div>
                                :
                                <Empty description={<p>There are currently no organizations</p>} />
                            }
                        </div>
                    }
                </div>
            </div>

            <AddOrganization show={showAddOrganization} hidePane={() => setShowAddOrganization(false)} />
        </>
    );
}