import React, { useState, useEffect } from 'react';

import { Document, Page, pdfjs } from "react-pdf";
import { toast } from '@rickylandino/react-messages';
import { Empty } from 'antd';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function ResumeCard(props) {
    

    return (
        <div>
            <Document file={props.resume} noData={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="This candidate has no resume. Add a resume in the attachments section." />}>
                <Page pageNumber={1} />
            </Document>
        </div>    
    );
}