import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Input } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import Globals from '../../../config/globals';
import { activeUserCandidatesState, canSelectedCandidateBeUpdated, editCandidate, userCandidates } from '../../../services/CandidateService';
import { useMediaQuery } from 'react-responsive';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../../css/editor.css';
import { convertToRaw, convertFromHTML, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getChangesToTrack } from '../../../helpers/DetectChanges';

export function CommentsCard(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        formFields: props.selectedCandidate.candidates,
        persistentInformation: props.selectedCandidate.candidates
    });
    var contentBlocks = null;
    var editorContent = EditorState.createEmpty();

    if (props?.selectedCandidate?.candidates?.candidateComments) {
        contentBlocks = convertFromHTML(props.selectedCandidate.candidates.candidateComments);
        editorContent = EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks))
    }

    

    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const [isActive, setIsActive] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    const [editorState, setEditorState] = useState(editorContent);
    const [stickyEditorState, setStickyEditorState] = useState(editorContent);

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        if (edit) {

        }
    }, [edit]);

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setEdit(false);
                        isDirty.current = false;


                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function handleSubmit() {
        let candidatesModel = {
            ...state.persistentInformation,
            candidateComments: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        };

        let postdata = {
            candidatesModel,
            auditChangesList: [getChangesToTrack(state.persistentInformation, candidatesModel, "Candidates", "Comments")]
        };

        toast.loading("Updating candidate...", { key: 'loading' });
        editCandidate(postdata).then(data => {
            toast.destroy('loading');
            toast.success("Candidate has been updated");

            setState({
                ...state,
                persistentInformation: postdata.candidatesModel
            });

            setStickyEditorState(editorState);

            setEdit(false);

            isDirty.current = false;
            Globals.isDirtyPage = false;

            var currentCandidate = data.userCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentInformation?.candidates_ID);
            if (!currentCandidate) {
                currentCandidate = data.companyCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentInformation?.candidates_ID);
            }

            if (currentCandidate) {
                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: currentCandidate, from: location.state.from, activeTab: 'compensation' }
                });
            }

            userCandidates.next(data);
            setActiveUserCandidates(data);
        }).catch(error => {
            toast.error("Something went wrong");

            isDirty = false;
            Globals.isDirtyPage = false;
        });
    }

    function onEditorStateChange(es) {
        isDirty.current = true;
        setEditorState(es);
    }
    return (
        <>
            {isActive &&
                <>
                {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                <Descriptions
                    title="Comments"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size='small'
                    labelStyle={{ width: 300 }}
                    layout={isDesktop ? 'horizontal' : 'vertical'}
                >
                    <Descriptions.Item label='Comments'>
                        <Editor
                            editorState={stickyEditorState}
                            toolbarHidden={true}
                            readOnly={true}
                        />
                    </Descriptions.Item>
                </Descriptions>

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Social Media'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="form-group col-12">
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="custom-editor"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: ['inline', 'list', 'textAlign'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                        bold: { className: 'bordered-option-classname' },
                                        italic: { className: 'bordered-option-classname' },
                                        underline: { className: 'bordered-option-classname' },
                                        strikethrough: { className: 'bordered-option-classname' },
                                        code: { className: 'bordered-option-classname' },
                                    },
                                    list: {
                                        unordered: { className: 'demo-option-custom' },
                                        ordered: { className: 'demo-option-custom' },
                                        indent: { className: 'demo-option-custom' },
                                        outdent: { className: 'demo-option-custom' },
                                    },
                                    textAlign: {
                                        left: { className: 'demo-option-custom' },
                                        center: { className: 'demo-option-custom' },
                                        right: { className: 'demo-option-custom' },
                                        justify: { className: 'demo-option-custom' },
                                    }
                                }}
                                />
                            </div>
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}