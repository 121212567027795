import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Dropdown, Layout, Menu, Switch, Tabs, Avatar, Badge, Popover, List, Typography } from 'antd';
import { NotificationOutlined, MobileOutlined, UserOutlined, BellOutlined, MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import recruiterbalmLogo from '../../img/RecruiterBalmLogo.jpg';
import nchcrLogo from '../../img/nchcr_brand_banner.png';

import './navigation.css';
import Globals from '../../config/globals';
import { confirm, toast } from '@rickylandino/react-messages';
import { useNavigate } from "react-router-dom";
import { CustomSearch } from './CustomSearch';
import { AddCandidate } from '../Candidates/AddCandidate';
import { ManageCampaign } from '../Campaigns/ManageCampaign';
import { logoutAudit } from '../../services/AuditLoginService';
import { activeRoute, userInfo, activeUserPermissions } from '../../services/UsersService';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import $ from 'jquery';
import { getColorShade } from '../../helpers/ColorShades';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { smsHistory, unreadMessages } from '../../services/SmsService';
import Moment from 'moment';
import { dismissEvents, userEvents } from '../../services/EventsService';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { TopNavProfileDropdown } from './TopNavProfileDropdown';
import { AddClient } from '../Clients/AddClient';
import { AddJob } from '../Jobs/AddJob';

const { Header, Sider } = Layout;
const logo = Globals.brand === 'nchcr' ? nchcrLogo : recruiterbalmLogo;
const { Paragraph, Text } = Typography;

export function TopNav(props) {
    const isDesktop = useMediaQuery({ minWidth: Globals.condenseNav });
    const isInAwkwardSize = useMediaQuery({ minWidth: Globals.superCondenseNav, maxWidth: Globals.condenseNav });
    const [collapsed, setCollapsed] = useState(true);
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useState({});
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [showAddCandidate, setShowAddCandidate] = useState(false);
    const [showNewClient, setShowNewClient] = useState(false);
    const [showNewJob, setShowNewJob] = useState(false);
    const [showNewCampaign, setShowNewCampaign] = useState(false);

    const [activeNotificationKey, setActiveNotificationKey] = useState('alerts');



    var urlend = window.api.slice(-1) === '/' ? 'hubs/chat' : '/hubs/chat';
    const url = window.api + urlend;
    var connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

    const [alerts, setAlerts] = useState({
        list: [],
        showNotification: false
    });
    const [alertToPush, setAlertToPush] = useState(null);

    const [events, setEvents] = useState({
        list: [],
        showNotification: false
    });

    useEffect(() => {
        if (isDesktop) {
            setCollapsed(true);
        }
    }, [isDesktop]);

    useEffect(() => {
        if (props.triggerEventNotification?.length > 0) {
            let eventThatHasAlert = props.triggerEventNotification.find(e => e.alert);
            setEvents({
                list: [...props.triggerEventNotification],
                showNotification: eventThatHasAlert ? true : false
            });
            setActiveNotificationKey('events');
        }
    }, [props.triggerEventNotification]);

    useEffect(() => {
        if (alertToPush) {
            let alertList = [...alerts.list];
            alertList.unshift(alertToPush);

            setAlerts({
                list: alertList,
                showNotification: true
            });

            setActiveNotificationKey('alerts');

            setAlertToPush(null);
        }
    }, [alertToPush]);

    useEffect(() => {
        var alive = true;

        activeRoute.pipe(takeWhile(() => alive)).subscribe((route) => {
            route = route.split('/')[1];

            switch (route) {
                case 'candidates':
                case 'candidate-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#candidates").addClass("active");
                    break;
                case 'clients':
                case 'client-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#clients").addClass("active");
                    break;
                case 'jobs':
                case 'job-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#jobs").addClass("active");
                    break;
                case 'organizations':
                case 'organization-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#organizations").addClass("active");
                    break;
                case 'sms-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#sms").addClass("active");
                    break;
                case 'email-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#email").addClass("active");
                    break;
                case 'campaigns':
                case 'campaign-dashboard':
                    $(".nav-item").removeClass("active");
                    $("#campaigns").addClass("active");
                    break;
                case 'account-settings':
                case 'admin-settings':
                    $(".nav-item").removeClass("active");
                    $("#settings").addClass("active");
                    break;
                default:
                    $(".nav-item").removeClass("active");
                    break;
            }
        });

        combineLatest(
            userInfo,
            unreadMessages,
            smsHistory
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo, unreadMessages, smsHist]) => {
            if (userInfo.users_ID) {
                setUInfo(userInfo);
            }

            if (unreadMessages?.length > 0) {
                let alertsList = unreadMessages.map(sms => ({
                    ...sms,
                    type: 'sms',
                    content: sms.messageContent,
                    date: sms.messageDate,
                    relatedEntity: 'candidate',
                    entityObject: smsHist.find(m => m.key === sms.candidates_ID.toString())?.value.candidate,
                    alert: true
                }));

                setAlerts({
                    ...alerts,
                    list: alertsList
                });
            }
        });

        connection.start()
            .then(result => {
                connection.on('ReceiveMessage', sms => {
                    smsHistory.next(sms.allMessages);

                    let alert = {
                        type: 'sms',
                        content: sms.receivedMessage.messageContent,
                        date: sms.receivedMessage.messageDate,
                        relatedEntity: 'candidate',
                        entityObject: sms.allMessages.find(m => m.key === sms.receivedMessage.candidates_ID.toString())?.value.candidate,
                        alert: true,
                        candidates_ID: sms.receivedMessage.candidates_ID
                    }

                    setAlertToPush(alert);
                });
            })
            .catch(e => console.log('Connection failed: ', e));

        $(document.body).on('click', handleClick);

        return () => {
            alive = false;
            $(document.body).off('click', handleClick);

            connection.off('ReceiveMessage');
        };
    }, []);

    function openNotifications() {
        setAlerts({ ...alerts, showNotification: false });
        setEvents({ ...events, showNotification: false });

        props.updateSystemSeen(events.list);
    }

    function handleClick(e) {
        //e.preventDefault();
        if (e.target.classList.contains("ant-avatar-string")
            || (e.target.classList.contains("ant-avatar") && !e.target.classList.contains("fixed-widgets-avatar"))
            || e.target.classList.contains("ant-avatar-image")
            || e.target.parentNode?.classList.contains("ant-avatar-image") || e.target.parentNode?.classList.contains("anticon-user")) {
            $(".nav-item").removeClass("active");
            $("#settings").addClass("active");

            navigate("/account-settings", { replace: true });
        }
    }

    function handleLogout() {
        confirm({
            title: "You are about to logout",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                logoutAudit();

                navigate("/", { replace: true });
                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleNavClick(e) {
        switch (e.target.id) {
            case 'dashboard':
                navigate("/dashboard");
                break;
            case 'candidates':
                navigate("/candidates");
                break;
            case 'clients':
                navigate("/clients");
                break;
            case 'jobs':
                navigate("/jobs");
                break;
            case 'organizations':
                navigate("/organizations");
                break;
            case 'sms':
                navigate("/sms-dashboard");
                break;
            case 'campaigns':
                navigate("/campaigns");
                break;
            case 'settings':
                navigate("/account-settings");
                break;
            case 'email':
                navigate("/email-dashboard");
                break;
            case 'admin-settings':
                navigate("/admin-settings");
                break;
            case 'summary':
                navigate("/summary-overview");
                break;
            default:
                break;
        }
    }

    function directToMessage(selectedCandidates_ID, idx) {

        unreadMessages.next([]);
        navigate("/sms-dashboard", { replace: true, state: { selectedCandidates_ID } });

        let alertList = [...alerts.list];
        alertList.splice(idx, 1);

        setAlerts({
            list: alertList,
            showNotification: false
        });
    }

    function dismissEvent(event) {
        let postdata = {
            eventIDList: [event.events_ID],
            users_ID: uInfo.users_ID
        }

        dismissEvents(postdata).then(data => {
            if (data) {
                userEvents.next(data);
                let evList = [...events.list];
                let evIdx = evList.findIndex(ev => ev.events_ID === event.events_ID);
                evList.splice(evIdx, 1);

                setEvents({
                    list: evList,
                    showNotification: false
                });

                props.updateNotificationEvents(evList);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function dismissAllEvents() {
        let postdata = {
            eventIDList: events.list.map(e => (e.events_ID)),
            users_ID: uInfo.users_ID
        }

        dismissEvents(postdata).then(data => {
            if (data) {
                userEvents.next(data);

                setEvents({
                    list: [],
                    showNotification: false
                });

                props.updateNotificationEvents([]);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function handleSider() {
        props.handleSider(!collapsed);
        setCollapsed(!collapsed);
    }

    const items = [
        { label: <span className="candidate-text">Add Candidate</span>, key: 'addCandidate', onClick: () => setShowAddCandidate(true) }, // remember to pass the key prop
        { label: <span className="client-text">Add Client</span>, key: 'addClient', onClick: () => setShowNewClient(true) },
        { label: <span className="job-text">Add Job</span>, key: 'addJob', onClick: () => setShowNewJob(true) },
        { label: <span className="campaign-text">Add Campaign</span>, key: 'addCampaign', onClick: () => setShowNewCampaign(true) },
    ];

    if (uPermissions?.users_ID && !uPermissions?.createCandidates || (uInfo?.userType === 'SuperAdmin')) {
        let addCandidateIdx = items.findIndex(item => item.key === 'addCandidate');
        items.splice(addCandidateIdx, 1);
    }

    if (uPermissions?.users_ID && !uPermissions?.createClients) {
        let addClientIdx = items.findIndex(item => item.key === 'addClient');
        items.splice(addClientIdx, 1);
    }

    if (uPermissions?.users_ID && !uPermissions?.createJobs) {
        let addJobIdx = items.findIndex(item => item.key === 'addJob');
        items.splice(addJobIdx, 1);
    }

    if (uPermissions?.users_ID && !uPermissions?.createCampaigns) {
        let addCampaignIdx = items.findIndex(item => item.key === 'addCampaign');
        items.splice(addCampaignIdx, 1);
    }


    const alertList = (
        <List
            style={{ width: '400px' }}
            itemLayout="horizontal"
            dataSource={alerts.list}
            size="small"
            renderItem={(item, idx) => (
                <List.Item onClick={() => directToMessage(item.candidates_ID, idx)} className="hover">
                    <List.Item.Meta
                        avatar={<MobileOutlined />}
                        title={(item.entityObject?.candidates_ID && !item.entityObject?.anonymous) ? <span><strong>{item.entityObject?.firstName} {item.entityObject?.lastName}</strong> sent you a message.</span> : <span>You have a new message</span>}
                        description={<Text ellipsis={true}>{item.content}</Text>}
                    />
                </List.Item>
            )}
        />
    );

    const eventList = (
        <List
            style={{ width: '400px' }}
            footer={<div><a className="float-end" key="list-loadmore-more" onClick={dismissAllEvents}>Dismiss All</a></div>}
            itemLayout="horizontal"
            dataSource={events.list}
            size="small"
            renderItem={(item, idx) => (
                <List.Item className="hover"
                    actions={[<a key="list-loadmore-more" onClick={() => dismissEvent(item)}>Dismiss</a>]}
                >
                    <List.Item.Meta
                        avatar={<BellOutlined />}
                        title={item.whatTriggered === 'Reminder' ? "Event Reminder" : <>{item.content} is due</>}
                        description={item.whatTriggered === 'Reminder' ? <Text>{item.content} due on {Moment(item.date).format("L LT")}</Text> : <Text>{item.details}</Text>}
                    />
                </List.Item>
            )}
        />
    );

    const tabItems = [
        { label: <>Alerts ({alerts.list.length})</>, key: 'alerts', children: alertList },
        { label: <>Events ({events.list.length})</>, key: 'events', children: eventList }
    ];

    const content = (
        <Tabs items={tabItems} activeKey={activeNotificationKey} onChange={(key) => setActiveNotificationKey(key)} />
    );

    return (
        <>
            <Header>
                <div className="d-flex ant-menu ant-menu-overflow ps-5 pe-5">
                    <div onClick={handleNavClick} id="dashboard" className={((!isDesktop && !isInAwkwardSize) ? 'me-auto' : '')}>
                        <img onClick={handleNavClick} id="dashboard" src={logo} style={isDesktop ? { height: '45px' } : { height: '34px' }} />

                    </div>
                    <MediaQuery minWidth={Globals.superCondenseNav + 1}>
                        <>
                            {uInfo?.userType === 'SuperAdmin' &&
                                <div className="organization-text organization-hover nav-item me-auto" id="organizations" onClick={handleNavClick}>
                                    <i className="fa-regular fa-user" id="organizations" onClick={handleNavClick}></i>
                                    &nbsp;
                                    Organizations
                                </div>
                            }

                            {uInfo?.emailAddress === 'ricky@landino.net' &&
                                <div className="organization-text organization-hover nav-item me-auto" id="summary" onClick={handleNavClick}>
                                    <i className="fa-regular fa-user" id="summary" onClick={handleNavClick}></i>
                                    &nbsp;
                                    Summary View
                                </div>
                            }

                            {uInfo?.userType !== 'SuperAdmin' &&
                                <>
                                    <div className={((!isDesktop && !isInAwkwardSize) ? 'me-auto ' : ' ') + "candidate-text candidate-hover nav-item"} id="candidates" onClick={handleNavClick}>
                                        <i className="fa-regular fa-user" id="candidates" onClick={handleNavClick}></i>
                                        &nbsp;
                                        Candidates
                                    </div>

                                    <div className={((!isDesktop && !isInAwkwardSize) ? 'me-auto ' : ' ') + "client-text client-hover nav-item"} id="clients" onClick={handleNavClick}>
                                        <i id="clients" className="fa-regular fa-building text-clients" />
                                        &nbsp;
                                        Clients
                                    </div>

                                    <div className={(!isDesktop ? 'me-auto ' : ' ') + "job-text job-hover nav-item"} id="jobs" onClick={handleNavClick}>
                                        <i id="jobs" className="fa-regular fa-id-badge text-jobs" />
                                        &nbsp;
                                        Jobs
                                    </div>

                                    <MediaQuery minWidth={Globals.condenseNav + 1}>
                                        <>
                                            <div className="seperator" />

                                            <div className="nav-item me-auto d-flex align-items-center" style={{ width: '25%' }}>
                                                <CustomSearch />
                                            </div>
                                        </>
                                    </MediaQuery>
                                </>
                            }
                            <MediaQuery minWidth={Globals.lgScreen + 1}>

                                <>
                                    <div className="d-flex align-items-center">

                                        <div className="campaign-text campaign-hover nav-item d-flex align-items-center" id="campaigns" onClick={handleNavClick}>
                                            <NotificationOutlined id="campaigns" onClick={handleNavClick} />
                                            &nbsp;
                                            Campaigns
                                        </div>

                                        <div className="campaign-text campaign-hover nav-item d-flex align-items-center" id="sms" onClick={handleNavClick}>
                                            <MobileOutlined id="sms" onClick={handleNavClick} />
                                            &nbsp;
                                            Text
                                        </div>

                                        <div className="campaign-text campaign-hover nav-item d-flex align-items-center" id="email" onClick={handleNavClick}>
                                            <i className="fa-regular fa-envelope" id="email" onClick={handleNavClick}></i>
                                            &nbsp;
                                            Email
                                        </div>

                                    </div>
                                    <div className='d-flex align-items-center nav-item'>
                                        <Dropdown menu={{ items }} placement='bottom'>
                                            <i className="far fa-plus-square d-flex" style={{ fontSize: '2rem' }}></i>
                                        </Dropdown>

                                    </div>

                                </>
                            </MediaQuery>
                            <MediaQuery minWidth={Globals.mdScreen + 1}>
                            <div className="nav-item">
                                <i onClick={handleNavClick} id="dashboard" className="fa-solid fa-house-user" style={{ fontSize: 'x-large' }}></i>
                            </div>
                            <div className="seperator" />
                            <div className='d-flex align-items-center nav-item' id="settings" onClick={handleNavClick} title='User Settings'>
                                <TopNavProfileDropdown uInfo={uInfo} />
                                {/*<span id="settings" onClick={handleNavClick}>*/}
                                {/*    <Avatar id="settings" onClick={handleNavClick} size='large' style={{ color: uInfo.color, backgroundColor: getColorShade(uInfo.color) }} src={uInfo.profilePicture ? `data:image/jpeg;base64,${uInfo.profilePicture}` : null} icon={<UserOutlined id="settings" onClick={handleNavClick} />}></Avatar>*/}
                                {/*</span>*/}
                                {/*<i className="fa-solid fa-gear d-flex" style={{ fontSize: '1.5rem' }} id="settings" onClick={handleNavClick}></i>*/}
                            </div>
                            </MediaQuery>
                            <div className="seperator" />
                            <MediaQuery minWidth={Globals.condenseNav + 1}>
                                <div className='text-danger d-flex align-items-center' onClick={handleLogout} title='Logout'>
                                    <i className="fas fa-sign-out-alt d-flex" style={{ fontSize: '1.5rem' }} onClick={handleLogout}></i>
                                </div>
                            </MediaQuery>
                        </>
                    </MediaQuery>
                    <MediaQuery maxWidth={Globals.condenseNav}>
                        <div className='d-flex align-items-center nav-item'>
                            <Dropdown menu={{ items }} placement='bottom'>
                                <i className="far fa-plus-square d-flex" style={{ fontSize: '2rem' }}></i>
                            </Dropdown>

                        </div>
                        <div className='d-flex align-items-center' onClick={handleSider}>
                            <MenuOutlined style={{ fontSize: '2rem' }} onClick={handleSider} />
                        </div>
                    </MediaQuery>
                </div>

            </Header>

            <AddCandidate show={showAddCandidate} from='nav' hidePane={() => setShowAddCandidate(false)} />
            <AddClient show={showNewClient} hidePane={() => setShowNewClient(false)} from='nav' />
            <AddJob show={showNewJob} hidePane={() => setShowNewJob(false)} from='nav' />
            <ManageCampaign hideModal={() => setShowNewCampaign(false)} showModal={showNewCampaign} selectedCampaign={null} users_ID={uInfo?.users_ID} />
            {/*<Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="logo" />
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        items={[
                            {
                                key: '1',
                                icon: <UserOutlined />,
                                label: 'nav 1',
                            },
                            {
                                key: '2',
                                icon: <VideoCameraOutlined />,
                                label: 'nav 2',
                            },
                            {
                                key: '3',
                                icon: <UploadOutlined />,
                                label: 'nav 3',
                            },
                        ]}
                    />
                </Sider>*/}
        </>

    );
}