import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Tag, Select } from 'antd';
import statesList from '../../../helpers/StatesList';
import { confirm, toast } from '@rickylandino/react-messages';
import { MultiSelectInput } from '../../../helpers/MultiSelectInput';
import Globals from '../../../config/globals';
import { activeUserCandidatesState, canSelectedCandidateBeUpdated, editCandidate, userCandidates } from '../../../services/CandidateService';
import { useMediaQuery } from 'react-responsive';
import { getChanges, getChangesToTrack } from '../../../helpers/DetectChanges';

export function RelocationCard(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);

    const isDirty = useRef(false);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        states: statesList.filter(obj => { return obj.country_code === props.selectedCandidate.candidates.country }),
        candidateInformation: { ...props.selectedCandidate.candidates },
        persistentCandidateInformation: {...props.selectedCandidate.candidates},
        phoneNumberNotUnique: false
    });

    const [modifiedStatesList, setModifiedStatesList] = useState([]);

    const [selectedStates, setSelectedStates] = useState({
        states: [],
        stickyStates: []
    });
    const [selectedRecords, setSelectedRecords] = useState({
        records: [],
        stickyRecords: []
    });
    const [selectedLicensures, setSelectedLicensures] = useState({
        licensures: [],
        stickyLicensures: []
    });

    const [edit, setEdit] = useState(false);
    const [update, setUpdate] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        var modifiedStates = modifyStates();
        setModifiedStatesList(modifiedStates);

        getSelectedStates(modifiedStates);
        getSelectedPreferredStates(modifiedStates);
        getSelectedLicensure(modifiedStates);
    }, []);

    function modifyStates(states = null) {
        let newList = states ? states : statesList.filter(obj => { return obj.country_code === 'US' });

        newList = newList.map(item => ({
            label: item.name,
            value: item.state_code,
            country: item.country_code
        }));

        return newList;
    }

    function getSelectedStates(sList) {
        let ss = [];
        if (state.candidateInformation?.preferredStates) {
            var splitArr = state.candidateInformation.preferredStates.split('|');
            for (var i = 0; i < splitArr.length - 1; i++) {
                var itemToPush = sList.find(obj => { return obj.value === splitArr[i] && obj.country === (state.candidateInformation?.country || "US") });
                ss.push(itemToPush.value);
                //ss.push(sList.find(obj => { return obj.value === splitArr[i] && obj.country === state.candidateInformation.country }));
            }
        }

        setSelectedStates({
            states: [...ss],
            stickyStates: [...ss]
        })
    }

    function getSelectedPreferredStates(sList) {
        let sr = [];

        if (state.candidateInformation?.preferredStatesToWork) {
            var splitArr = state.candidateInformation.preferredStatesToWork.split('|');
            for (var i = 0; i < splitArr.length - 1; i++) {
                var itemToPush = sList.find(obj => { return obj.value === splitArr[i] && obj.country === (state.candidateInformation?.country || "US") });
                sr.push(itemToPush.value);
            }
        }

        setSelectedRecords({
            records: [...sr],
            stickyRecords: [...sr]
        })
    }

    function getSelectedLicensure(sList) {
        let slr = [];
        if (state.candidateInformation?.licensure) {
            var splitArr = state.candidateInformation.licensure.split('|');
            for (var i = 0; i < splitArr.length - 1; i++) {
                var itemToPush = sList.find(obj => { return obj.value === splitArr[i] && obj.country === state.candidateInformation.country });
                slr.push(itemToPush.value);
            }
        }

        setSelectedLicensures({
            licensures: [...slr],
            stickyLicensures: [...slr]
        });
    }

    function updateSelectedLicensureRecords(selectedLicensureRecords) {
        setSelectedLicensures({
            ...selectedLicensures,
            licensures: [...selectedLicensureRecords]
        });

        setUpdate(!update);

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function updateSelectedStates(ss) {
        setSelectedStates({
            ...selectedStates,
            states: [...ss]
        });

        setUpdate(!update);

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function updateSelectedRecords(sr) {
        setSelectedRecords({
            ...selectedRecords,
            records: [...sr]
        });

        //setUpdate(!update);

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function handleRelocationChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        isDirty.current = true;
        Globals.isDirtyPage = true;

        if (name === 'willingToRelocate') {
            setState({
                ...state,
                candidateInformation: {
                    ...state.candidateInformation, [name]: value === 'yes' ? true : false
                }
            });
        } else {
            setState({
                ...state,
                candidateInformation: {
                    ...state.candidateInformation, [name]: value
                }
            });
        }
    }

    function openEdit() {
        setUpdate(!update);
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {

        var states = statesList.filter(obj => {
            return obj.country_code === state.persistentCandidateInformation.country
        });

        setState({
            ...state,
            states,
            candidateInformation: { ...state.persistentCandidateInformation }
        });

        setSelectedRecords({
            ...selectedRecords,
            records: [...selectedRecords.stickyRecords]
        });
        setSelectedStates({
            ...selectedStates,
            states: [...selectedStates.stickyStates]
        });
        setSelectedLicensures({
            ...selectedLicensures,
            licensures: [...selectedLicensures.stickyLicensures]
        });

        setEdit(false);
        isDirty.current = false;
    }

    function handleSubmit(e) {
        let candidateInformation = state.candidateInformation;

        var preferredStates = "";
        for (var h = 0; h < selectedStates.states.length; h++) {
            preferredStates += selectedStates.states[h] + '|';
        }
        candidateInformation.preferredStates = preferredStates;

        candidateInformation.preferredStatesToWork = '';
        for (var j = 0; j < selectedRecords.records.length; j++) {
            candidateInformation.preferredStatesToWork += selectedRecords.records[j] + '|';

        }

        candidateInformation.licensure = '';
        for (var j = 0; j < selectedLicensures.licensures.length; j++) {
            candidateInformation.licensure += selectedLicensures.licensures[j] + '|';

        }

        var changes = getChangesToTrack(state.persistentCandidateInformation, candidateInformation, "Candidates", "Candidate Details");
        let postdata = {
            candidatesModel: candidateInformation,
            auditChangesList: changes ? [changes] : null
        };

        toast.loading("Updating candidate...", { key: 'loading' });
        editCandidate(postdata).then(data => {
            toast.destroy('loading');
            toast.success("Candidate has been updated");
            
            setState({
                ...state,
                candidateInformation,
                persistentCandidateInformation: { ...candidateInformation }
            });

            setSelectedLicensures({
                ...selectedLicensures,
                stickyLicensures: [...selectedLicensures.licensures]
            });

            setSelectedRecords({
                ...selectedRecords,
                stickyRecords: [...selectedRecords.records]
            });

            setSelectedStates({
                ...selectedStates,
                stickyStates: [...selectedStates.states]
            });

            setEdit(false);

            isDirty.current = false;
            Globals.isDirtyPage = false;

            var currentCandidate = data.userCandidates?.find(uc => uc.candidates?.candidates_ID === state.candidateInformation?.candidates_ID);
            if (!currentCandidate) {
                currentCandidate = data.companyCandidates?.find(uc => uc.candidates?.candidates_ID === state.candidateInformation?.candidates_ID);
            }

            if (currentCandidate) {
                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: currentCandidate, from: location.state.from }
                });
            }

            userCandidates.next(data);
            setActiveUserCandidates(data);
        }).catch(error => {
            toast.error("Something went wrong");

            isDirty.current = false;
            Globals.isDirtyPage = false;
        });
    }

    return (
        <>
            {isActive &&
                <>
                    {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    <Descriptions
                        title="Location Information"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size='small'
                    labelStyle={{ width: 300 }}
                    layout={isDesktop ? 'horizontal' : 'vertical'}
                    >
                        <Descriptions.Item label="Licensure">
                            {selectedLicensures?.stickyLicensures?.map((tag) => {
                                if (tag) {
                                    return (
                                        <Tag key={tag}>
                                            {tag}
                                        </Tag>
                                    );
                                }
                            })}
                        </Descriptions.Item>

                        <Descriptions.Item label="Preferred States to Work">
                            {selectedRecords?.stickyRecords?.map((tag) => {
                                if (tag) {
                                    return (
                                        <Tag key={tag}>
                                            {tag}
                                        </Tag>
                                    );
                                }
                            })}
                        </Descriptions.Item>

                        <Descriptions.Item label="Willing to Relocate?">{state.persistentCandidateInformation?.willingToRelocate ? 'Yes' : 'No'}</Descriptions.Item>
                        {state.persistentCandidateInformation?.willingToRelocate &&
                            <Descriptions.Item labelStyle={{ textAlign: 'right' }} label="To where?">
                                {selectedStates?.stickyStates?.map((tag) => {
                                    if (tag) {
                                        return (
                                            <Tag key={tag}>
                                                {tag}
                                            </Tag>
                                        );
                                    }
                                })}
                            </Descriptions.Item>
                        }
                    </Descriptions>

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Location Preferences'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="col col-12">
                                <label>Licensure (state(s) where this candidate can hold a license)</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedLicensures?.licensures}
                                    onChange={updateSelectedLicensureRecords}
                                    options={modifiedStatesList}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="horizontal-candidates-divider" />

                            <div className="col col-12">
                                <label>Preferred States</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedRecords?.records}
                                    onChange={updateSelectedRecords}
                                    options={modifiedStatesList}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="horizontal-candidates-divider" />
                            <div className="form-group col-lg-6">
                                <label className="d-block">Willing to Relocate?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" name="willingToRelocate" value="yes" checked={state.candidateInformation.willingToRelocate} onChange={handleRelocationChange} />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" name="willingToRelocate" value="no" checked={!state.candidateInformation.willingToRelocate} onChange={handleRelocationChange} />
                                    <label> No</label>
                                </div>
                            </div>

                            {state.candidateInformation?.willingToRelocate &&
                                <div className="col col-12">
                                    <label>To Where?</label>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select"
                                        value={selectedStates?.states}
                                        onChange={updateSelectedStates}
                                        options={modifiedStatesList}
                                        optionFilterProp='label'
                                    />
                                </div>
                            }

                            <div className="form-group col-12">
                                <label className="form-label">Additional Comments</label>
                                <textarea maxLength="2000" type="text" name="relocationComments" value={state.candidateInformation.relocationComments || ''} onChange={handleRelocationChange} className="ant-input" />
                            </div>
                        </div>
                    </Drawer>
                </>
            }
            </>
    );
}