import Axios from '../config/axios';

export function insertTag(postdata) {
    return Axios.post(`/api/InsertTag`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateTag(postdata) {
    return Axios.post(`/api/UpdateTag`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteTag(postdata) {
    return Axios.post(`/api/DeleteTag`, postdata
    ).then(response => response.data).catch(error => error);
}