import Axios from '../config/axios';
import { atom } from 'recoil';

export const canSelectedJobBeUpdated = atom({
    key: 'canSelectedJobBeUpdated',
    default: false
});

export const canSelectedJobBeDeleted = atom({
    key: 'canSelectedJobBeDeleted',
    default: false
});

export const activeUserJobsState = atom({
    key: 'activeUserJobsState',
    default: {}
});

export function getJobsByOrganization(org_ID, users_ID) {
    return Axios.get('/api/GetJobsByOrganization', {
        params: {
            org_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getJobDashboardInformation(jobs_ID, users_ID) {
    return Axios.get('/api/GetJobDashboardInformation', {
        params: {
            jobs_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function addJob(postdata) {
    return Axios.post(`/api/AddJob`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateJob(postdata) {
    return Axios.post(`/api/UpdateJob`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteJob(postdata) {
    return Axios.post(`/api/DeleteJob`, postdata).then(response => response.data).catch(error => error);
}

export function archiveJob(postdata) {
    return Axios.post(`/api/ArchiveJob`, postdata).then(response => response.data).catch(error => error);
}

export function getMatchedJobs(candidates_ID, users_ID) {
    return Axios.get('/api/GetMatchedJobs', {
        params: {
            candidates_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getJobsWithPlacementsByUser(org_ID, users_ID, placementStatus) {
    return Axios.get('/api/GetJobsWithPlacementsByUser', {
        params: {
            org_ID,
            users_ID,
            placementStatus
        }
    }).then(response => response.data).catch(error => error);
}