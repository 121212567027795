import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Skeleton, Tabs, notification, Progress, Popover } from 'antd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activeUserInfo, activeUserPermissions } from "../../services/UsersService";
import { emptyEvent } from "../../services/EventsService";
import Moment from 'moment';
import { activeEventTypes } from "../../services/OrganizationsService";
import * as JobCards from './JobCards/index';
import { EventSlider } from "../Dashboard/EventSlider";
import {toast, confirm} from '@rickylandino/react-messages';
import { archiveJob, canSelectedJobBeDeleted, canSelectedJobBeUpdated, deleteJob, getJobDashboardInformation } from "../../services/JobsService";
import { MatchPane } from "../Placements/MatchPane";
import { useSWRConfig } from "swr";
import { AddJob } from "./AddJob";

export function JobDashboard(props) {
    
    const { mutate } = useSWRConfig()
    const { jobs_ID } = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    
    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);
    const eventTypes = useRecoilValue(activeEventTypes);

    const [activeTab, setActiveTab] = useState('jobInformation');
    
    const setCanUpdateJob = useSetRecoilState(canSelectedJobBeUpdated);
    const [canDelete, setCanDelete] = useRecoilState(canSelectedJobBeDeleted);

    const [selectedJob, setSelectedJob] = useState(null);
    const [matchPaneInfo, setMatchPaneInfo] = useState(null);
    
    const [goBackText, setGoBackText] = useState("Return to my jobs");

    const [showEventsSlider, setShowEventsSlider] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({ ...emptyEvent });

    const [showMatchPane, setShowMatchPane] = useState(false);
    const [showAddJob, setShowAddJob] = useState(false);

    useEffect(() => {
        if(jobs_ID && uInfo?.users_ID) {
            getJobDashboardInformation(jobs_ID, uInfo.users_ID).then(data => {
                setSelectedJob(data);
                setMatchPaneInfo({
                    jobTitle: data.job?.jobTitle,
                    jobs_ID: data.job?.jobs_ID,
                    users_ID: data.job?.users_ID,
                    parentSpecialty: data.parentSpecialty,
                    subSpecialty: data.subSpecialty
                })
                setLoaded(true);
            });
        }
    }, [jobs_ID, uInfo]);

    useEffect(() => {
        if (selectedJob?.job?.jobs_ID && uPermissions?.users_ID) {
            const canUpdate = (uPermissions.updateJobs === 'company' || (uPermissions.updateJobs === 'personal' && uInfo.users_ID === selectedJob.job.users_ID));
            const canBeDeleted = (uPermissions.deleteJobs === 'company' || (uPermissions.deleteJobs === 'personal' && uInfo.users_ID === selectedJob.job.users_ID));

            setCanUpdateJob(canUpdate);
            setCanDelete(canBeDeleted);
        } else {
            setCanUpdateJob(false);
        }
    }, [selectedJob, uPermissions]);

    function goBack() {
        navigate("/jobs", { replace: true });
    }

    function addEvent() {

        let eventToAdd = {
            events: {
                ...emptyEvent,
                eventDateTime: Moment(new Date),
                eventTypes_ID: eventTypes.find(type => type.eventType === 'To-Do')?.eventTypes_ID
            },
            eventTypes: eventTypes.find(type => type.eventType === 'To-Do')
        };

        setSelectedEvent(eventToAdd);
        setShowEventsSlider(true);
    }

    function handleDeleteJob() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this job.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    toast.loading("Deleting job...", { key: 'loading' });

                    deleteJob(selectedJob.job).then(data => {
                        
                        toast.destroy('loading');

                        if (data) {
                            toast.success("Job data has been deleted");

                            navigate("/jobs", { replace: true });
                        } else {
                            toast.error("Something went wrong");
                        }
                    }).catch(error => {
                        toast.error('Something went wrong');
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleArchiveJob() {
        confirm({
            title: <span><i className='fas fa-archive text-warning icon-md pr-1'></i> &nbsp; You are about to archive this job.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    toast.loading("Archiving job...", { key: 'loading' });
                    archiveJob(selectedJob.job).then(data => {
                        toast.destroy('loading');
                        if (data) {
                            toast.success("Job has been archived");

                            navigate("/jobs", { replace: true });
                        } else {
                            toast.error("Something went wrong");
                        }
                    }).catch(error => {
                        toast.error('Something went wrong');
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }

    var tabs = [
        {
            label: <div className="job-text">Job Information</div>,
            key: 'jobInformation',
            children: (
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <JobCards.JobInformationCard {...props} selectedJob={selectedJob} updateJobInformation={(selJob) => setSelectedJob(selJob)} />
                        <div className="horizontal-jobs-divider" />
                        <JobCards.CommentsCard {...props} selectedJob={selectedJob} updateJobInformation={(selJob) => setSelectedJob(selJob)} />
                    </div>
                </div>
            ),
        }, {
            label: <div className="job-text">Job Workflow</div>,
            key: 'workflow',
            children: (
                <div className="row">
                    <div className="col-12">
                        <JobCards.WorkflowCard {...props} showSingleJobID={selectedJob?.job?.jobs_ID} />
                    </div>
                </div>
            )
        }
    ];

    function triggerRefresh() {
        mutate(`/api/getJobsWithPlacementsByUser?org_ID=${uInfo.organizations_ID}&users_ID=${uInfo.users_ID}&placementStatus=Active`);

        notification.info({
            message: `Refreshing data...`,
            description: <div>
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#326085',
                    }}
                    percent={100}
                    showInfo={false}
                    status="active"
                />
            </div>,
            placement: 'bottomRight',
            duration: 2
        });
    }

    return (
        <div>
            <div className="horizontal-jobs-divider-thick" />

            <div className="row mt-3">
                <div className="col col-lg-4 col-12">
                    <div className="margin-0-10">
                        {selectedJob ?
                            <>
                                
                                <h1>
                                    {selectedJob?.job?.externalJob &&
                                        <Popover
                                            content={
                                                <div>
                                                    This is an external job
                                                </div>
                                            }
                                        >
                                            <i className="job-text fa-solid fa-link me-2"></i>
                                        </Popover>
                                    }
                                    {selectedJob?.job?.jobTitle}
                                </h1>
                            
                                <span className="a" onClick={goBack}><i className="fas fa-arrow-left"></i> {goBackText}</span>
                            </>    
                            :
                            <Skeleton active />
                    }
                        
                    </div>
                </div>

                <div className="col col-lg-8 col-12 mb-4">
                    <div className="float-start">
                        <button id="matchButton" className="ant-btn ant-btn-match m-1" onClick={() => setShowMatchPane(true)}> Match Candidates</ button>
                    </div>

                    <div className="float-start">
                        <button className="ant-btn ant-btn-info m-1" onClick={() => setShowAddJob(true)}><i className="fa-regular fa-copy" /> Clone This Job</button>
                    </div>
                    
                    {/* <div className="float-start">
                        <button className="ant-btn ant-btn-primary m-1" onClick={addEvent}><i className="fa-solid fa-plus hover" onClick={addEvent}></i> Add Event</button>
                    </div> */}
                    {canDelete &&
                        <>
                            <div className="float-end">
                                <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteJob}><i className='far fa-trash-alt'></i> &nbsp; Delete this Job</button>
                            </div>

                            <div className="float-end">
                                <button className="ant-btn ant-btn-warning float-right m-1" onClick={handleArchiveJob}><i className='fas fa-archive'></i> &nbsp; Archive this Job</button>
                            </div>
                        </>
                    }

                </div>

            </div>

            <div className="horizontal-jobs-divider" />

            {loaded ?
                <div className="row">
                    <Tabs activeKey={activeTab} type="card" items={tabs} onChange={handleTabChange} />
                </div>
                :
                <div className="col-xl-6 col-12">
                    <Skeleton active paragraph={{ rows: 10 }} />
                </div>
            }

            <EventSlider jobs_ID={selectedJob?.job?.jobs_ID || null} showModal={showEventsSlider} hideModal={() => setShowEventsSlider(false)} selectedEvent={selectedEvent} eventTypes={eventTypes} selectedDate={Moment(new Date)} users_ID={uInfo.users_ID} />
            <MatchPane show={showMatchPane} hidePane={() => setShowMatchPane(false)} entity={matchPaneInfo} from='jobs' placementStatus="Active" triggerRefresh={triggerRefresh} />
            <AddJob show={showAddJob} from='clone-job' hidePane={() => setShowAddJob(false)} jobToClone={selectedJob} />
        </div>
    );
}