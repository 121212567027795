import { useRecoilState, useRecoilValue } from "recoil";
import { activeWorkflowItemsState, subSpecialties } from "../../services/OrganizationsService";
import { Fragment, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Empty, Popover, Skeleton, Table, notification, Progress, Pagination } from "antd";
import { MatchPane } from "../Placements/MatchPane";
import { useNavigate } from "react-router-dom";
import { PlacementWorkflowPane } from "../Placements/PlacementWorkflowPane";
import { useForm } from "react-hook-form";
import { activeUserInfo, activeUserPermissions } from "../../services/UsersService";
import { activeUserJobsState } from "../../services/JobsService";
import { useJobs } from "../../swr/jobs";
import ReactPaginate from "react-paginate";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "../../swr/fetcher";
import { isItemStillMatch } from "../../helpers/Matcher";
import { cityStateFormatter } from "../../helpers/Location";


export default function JobPlacementWorkflow(props) {
    const { mutate } = useSWRConfig()
    const navigate = useNavigate();
    const { register, getValues, setValue, control, watch, formState } = useForm();

    const [activeWorkflowItems, setActiveWorkflowItems] = useRecoilState(activeWorkflowItemsState);
    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);
    const [candidatesListName, setCandidatesListName] = useState('activeCandidates');

    const [workflowJobs, setWorkflowJobs] = useState(null);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [jobsPag, setJobsPag] = useState([]);
    const [showJobs, setShowJobs] = useState(false);
    const [loading, setLoading] = useState(true);

    const [status, setStatus] = useState('Active');

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const [matchPane, setMatchPane] = useState({ show: false, job: null });
    const [placementWorkflowPane, setPlacementWorkflowPane] = useState({ show: false, selectedJob: null, clientName: null, selectedCandidate: null, idx: null, workflowItem: null });

    const key = `/api/getJobsWithPlacementsByUser?org_ID=${uInfo.organizations_ID}&users_ID=${uInfo.users_ID}&placementStatus=${status}&pageSize=${tableParams.pagination.pageSize}&offset=${tableParams.pagination.current - 1}&jobs_ID=${props.showSingleJobID || 'null'}`;

    const { data, error, isLoading, isMutating } = useSWR(key, fetcher);

    useEffect(() => {
        if (data) {
            handlePlacementWorkflow();
        } else {
            setLoading(true);
        }
    }, [data]);

    useEffect(() => {
        mutate(key);
    }, [tableParams]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'placementStatus') {
                filterPlacementStatus(value.placementStatus);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function filterPlacementStatus(status) {
        let localCandidatesListName = '';
        switch (status) {
            case 'Active':
                localCandidatesListName = 'activeCandidates';
                break;
            case 'Inactive':
                localCandidatesListName = 'inactiveCandidates';
                break;
            case 'Terminated':
                localCandidatesListName = 'terminatedCandidates';
                break;
            case 'Completed':
                localCandidatesListName = 'completedCandidates';
                break;
            default:
                localCandidatesListName = 'activeCandidates';
                break;
        }

        setStatus(status);
        setCandidatesListName(localCandidatesListName);
    }

    function getWorkflowTypeByJobsListName(localCandidatesListName) {
        switch (localCandidatesListName) {
            case 'inactiveCandidates':
                return 'inactiveWorkflow';
            case 'completedCandidates':
                return 'completedWorkflow';
            case 'terminatedCandidates':
                return 'terminatedWorkflow';
            case 'activeCandidates':
            default:
                return 'activeWorkflow';
        }
    }

    function handlePlacementWorkflow(localCandidatesListName = null) {
        let filteredJobs = [];

        if (!localCandidatesListName) { localCandidatesListName = candidatesListName; }

        let workflowType = getWorkflowTypeByJobsListName(localCandidatesListName);

        console.log(data);

        //let wfc = { ...localWorkflowJobs };
        if (data[workflowType]?.length > 0) {
            data[workflowType].map(iaw => {
                let localIaw = { ...iaw };
                let itemIdx = filteredJobs.findIndex(fc => fc.jobs_ID === localIaw.jobs_ID);
                let candidates = {
                    candidate: { 
                        candidates_ID: localIaw.candidates_ID, 
                        candidateFirstName: localIaw.candidateFirstName, 
                        candidateLastName: localIaw.candidateLastName, 
                        city: localIaw.candidateCity, 
                        state: localIaw.candidateState, 
                        jobTypes: localIaw.candidateJobTypes, 
                        preferredStatesToWork: localIaw.preferredStatesToWork, 
                        visaTypes_ID: localIaw.visaTypes_ID, 
                        specialty: localIaw.specialty, 
                        subSpecialty: localIaw.subSpecialty, 
                        users_ID: localIaw.users_ID,
                        candidateSpecialties_ID: localIaw.candidateSpecialties_ID,
                        candidateSubSpecialties_ID: localIaw.candidateSubSpecialties_ID
                    },
                    workflow: localIaw.workflowItems
                }

                if (itemIdx < 0) {
                    localIaw[localCandidatesListName] = [candidates];

                    filteredJobs.push(localIaw);
                } else {
                    (filteredJobs[itemIdx][localCandidatesListName] && Array.isArray(filteredJobs[itemIdx][localCandidatesListName])) ? filteredJobs[itemIdx][localCandidatesListName].push(candidates) : filteredJobs[itemIdx][localCandidatesListName] = [candidates];
                }
            });
        }

        console.log(filteredJobs);

        let filteredJobsFinal = [];
        for (var i = 0; i < filteredJobs.length; i++) {
            let item = { ...filteredJobs[i] };
            var isHide = (item[localCandidatesListName]?.length <= 0) ? true : false;
            item.showCandidates = true;
            if (!isHide) {
                var idx = filteredJobsFinal.findIndex(j => j.jobs_ID === item.jobs_ID);

                if (idx >= 0) {
                    filteredJobsFinal[idx] = item
                } else {
                    filteredJobsFinal.push(item);
                }

            }
        }

        if (props.showSingleJobID) {
            filteredJobsFinal = filteredJobsFinal.filter(j => j.jobs_ID === props.showSingleJobID);
        }

        console.log(filteredJobsFinal);

        setFilteredJobs(filteredJobsFinal);
        setJobsPag(filteredJobsFinal);

        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: filteredJobsFinal.length > 0 ? filteredJobsFinal[0].totalCount : 0
            }
        });

        setShowJobs(true);
        setLoading(false);
        setWorkflowJobs(data);
    }

    function showSpecificCandidates(e, item, idx) {
        if (e.target.tagName !== 'A') {
            item.showCandidates = item.showCandidates ? false : true;
            let jobs = [...jobsPag];
            jobs[idx] = item;
            setJobsPag(jobs);
        }
    }

    function showWorkflowPane(job) {
        setMatchPane({ show: true, job });
    }

    function showPlacementWorkflow(candidate, item, idx) {
        var workflowType = getWorkflowTypeByJobsListName(candidatesListName);
        let workflowJobInQuestion = workflowJobs[workflowType].find(wc => wc.candidates_ID === candidate.candidates_ID && wc.jobs_ID === item.jobs_ID);

        setPlacementWorkflowPane({
            show: true,
            selectedJob: item,
            clientName: item.clientName,
            selectedCandidate: candidate,
            idx,
            workflowItem: workflowJobInQuestion
        });
    }

    function hidePlacementPane() {
        setPlacementWorkflowPane({
            show: false,
            selectedJob: null,
            clientName: null,
            selectedCandidate: null,
            idx: null,
            workflowItem: null
        });

        triggerRefresh();
    }

    function triggerRefresh() {
        mutate(key)

        notification.info({
            message: `Refreshing data...`,
            description: <div>
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#326085',
                    }}
                    percent={100}
                    showInfo={false}
                    status="active"
                />
            </div>,
            placement: 'bottomRight',
            duration: 2
        });
    }

    function goToJobDashboard(item) {
        navigate({
            pathname: `/job-dashboard/${item.jobs_ID}`,
            state: { from: 'placementWorkflow' }
        });
    }

    function viewCandidate(candidate) {
        navigate({
            pathname: `/candidate-dashboard/${candidate.candidates_ID}`,
            state: { from: 'placementWorkflow' }
        });
    }

    function linkFormatter(cell, item) {
        return (
            <>
                <span className="a float-right mx-1" onClick={() => showWorkflowPane(item.jobs)}> Match Jobs</span>
                {showViewEntity(item) ?
                    <>{!item.jobs.hideFromSystem && !props.showSingleJobID && <a className="a float-right mx-1" onClick={() => goToJobDashboard(item)}> View Job</a>}</>
                    :
                    <NoPermissionsPopover />
                }
            </>
        );
    }

    const columns = [{
        dataIndex: 'jobTitle',
        title: 'Job Title',
    }, {
        title: 'Actions',
        align: 'right',
        render: linkFormatter
    }];

    //This method determines if a user is seeing a job due to one of their entities being placed in a workflow is also allowed to see the job/candidate not affiliated with them
    const showViewEntity = (entity) => {
        if(uInfo.users_ID === entity.users_ID || uPermissions?.readCandidates === 'company') {
            return true;
        } else {
            return false;
        }
    }

    
    const NoPermissionsPopover = () => {
        return (
            <Popover
                content={
                    <div>
                        You do not have permissions to view this.
                    </div>
                }
            >
                <i className="mx-1 fa-solid fa-lock"></i>
            </Popover>
        );
    }

    const dropdownRender = (item) => {
        return (
            <>
                {item[candidatesListName]?.length > 0 &&
                    <>
                        {item[candidatesListName].map((candidate, cidx) => (
                            <div className="row" key={cidx}>
                                <div className="col col-12">
                                    <span>{candidate.candidate.candidateFirstName} &nbsp;{candidate.candidate.candidateLastName} &nbsp;</span>
                                    {!isItemStillMatch(candidate.candidate, item) &&
                                        <Popover
                                            content={
                                                <div>
                                                    This candidate is not a full match. Job or Candidate info has changed since creating this placement.
                                                </div>
                                            }
                                        >
                                            <i className="fa-solid fa-asterisk text-danger"></i>
                                        </Popover>
                                    }
                                </div>
                                <div className="col col-12">
                                    {showViewEntity(item) ?
                                        <span className="a float-right mx-1" onClick={() => viewCandidate(item)}>View Candidate</span>
                                        :
                                        <NoPermissionsPopover />
                                    }
                                    
                                    <span className="a float-right mx-1" onClick={() => showPlacementWorkflow(candidate.candidate, item, cidx)}>View Workflow</span>
                                    {/* <span className="a float-right mx-1" onClick={() => showRelatedEventsPane(candidate.candidate, item)}>View Related Events</span> */}


                                </div>
                                <div className="horizontal-jobs-divider"></div>
                            </div>
                        ))}
                    </>
                }
            </>
        );
    }

    const onShowSizeChange = (current, pageSize) => { 
        setTableParams({ 
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current,
                pageSize
            }
        });
    }

    console.log(jobsPag);

    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-12 mb-5">
                    <label>Filter by Placement Status</label>
                    <select className="ant-input" {...register('placementStatus')}>
                        <option>Active</option>
                        <option value="Inactive">On Hold/Inactive</option>
                        <option>Terminated</option>
                        <option>Completed</option>
                    </select>
                </div>
            </div>

            {(loading || isLoading) ?
                <Skeleton active paragraph={{ rows: 4 }} />
                :
                <>
                    <MediaQuery maxWidth={1223}>
                        {activeWorkflowItems.length > 0 &&
                            <Table
                                rowKey={(record) => record.jobs_ID}
                                dataSource={jobsPag}
                                columns={columns}
                                size='small'
                                expandable={{
                                    expandedRowRender: (record) => dropdownRender(record),
                                    rowExpandable: (record) => record.jobs_ID !== 'Not Expandable',
                                }}
                            />
                        }
                    </MediaQuery>
                    <MediaQuery minWidth={1224}>
                        {(showJobs && jobsPag?.length > 0) ?
                            <div className="overflow-x-table job">
                                <h4>Jobs with Matched Candidates</h4>
                                <table className="job-table table table-header-rotated table-striped table-hover table-striped-column">
                                    <thead>
                                        <tr>
                                            <th className="row-header">
                                                <div className="legend">
                                                    <i className="fa-solid fa-check text-success fa-1x"></i> = Completed <br />
                                                    <i className="fa-solid fa-repeat fa-1x"></i> = In Progress <br />
                                                    <i className="fa-regular fa-asterisk text-danger"></i> = <span tooltip="This candidate is missing data">Problem found</span> <br />
                                                    <i className="job-text fa-solid fa-link"></i> = External Job
                                                </div>
                                                <p>* Click on <i className="fa-solid fa-plus-circle text-success"></i> to see all matched jobs for a candidate<br />
                                                    * Click on a job to process workflow</p>


                                            </th>
                                            {activeWorkflowItems.map((item, idx) => (
                                                <th className="rotate-45" key={idx}><div><span>{item.workflowItem}</span></div></th>

                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobsPag.map((item, idx) => (
                                            <Fragment key={idx}>
                                                <Fragment>
                                                    <tr onClick={(e) => showSpecificCandidates(e, item, idx)}>
                                                        <td className="y-value" key={idx}>
                                                            <span>{(item.showCandidates) ? (item[candidatesListName]?.length > 0 ? <i className="fa-solid fa-minus-circle text-secondary"></i> : <span />) : (item[candidatesListName] && item[candidatesListName].length > 0) ? <i className="fa-solid fa-plus-circle text-success"></i> : <span />}&nbsp;</span>
                                                            {item.externalJob &&
                                                                <Popover
                                                                    content={
                                                                        <div>
                                                                            It is an external job and may not be visible to the system.
                                                                        </div>
                                                                    }
                                                                >
                                                                    <i className="job-text fa-solid fa-link me-2"></i>
                                                                </Popover>
                                                            }
                                                            <span>{item.jobTitle} &nbsp;</span>
                                                            {(item.visaTypes === '') &&
                                                                <Popover content={<p>This is showing a partial match. This job has an imcomplete record. Preferred visa types is missing.</p>}>
                                                                    <i className="fa-regular fa-asterisk text-danger hover"></i>
                                                                </Popover>
                                                            }

                                                            <a className="a float-right mx-1" onClick={() => showWorkflowPane(item)}> Match Candidates</a>
                                                            {showViewEntity(item) ?
                                                                <>{!item.hideFromSystem && !props.showSingleJobID && <a className="a float-right mx-1" onClick={() => goToJobDashboard(item)}> View Job</a>}</>
                                                                :
                                                                <NoPermissionsPopover />
                                                            }
                                                            
                                                        </td>
                                                        {activeWorkflowItems.map((item, idx) => (
                                                            <td key={idx}>
                                                                <span className="label label-success">
                                                                    <i className="icon-ok icon-white"></i>
                                                                </span>
                                                            </td>

                                                        ))}
                                                    </tr>
                                                    {item.showCandidates &&
                                                        <Fragment>
                                                            {item[candidatesListName]?.length > 0 &&
                                                                <Fragment>
                                                                    {item[candidatesListName].map((candidate, cidx) => (
                                                                        <tr key={cidx}>
                                                                            <td className="indented-y-value">
                                                                                <span>{candidate.candidate.candidateFirstName}&nbsp;{candidate.candidate.candidateLastName}&nbsp;</span>
                                                                                {!isItemStillMatch(candidate.candidate, item) &&
                                                                                    <Popover
                                                                                        content={
                                                                                            <div>
                                                                                                This candidate is not a full match. Job or Candidate info maybe have changed since creating this placement.
                                                                                            </div>
                                                                                        }
                                                                                    >
                                                                                        <i className="fa-regular fa-asterisk text-danger"></i>
                                                                                    </Popover>
                                                                                }

                                                                                <a className="a float-right mx-1" onClick={() => showPlacementWorkflow(candidate.candidate, item, cidx)}>View Workflow</a>
                                                                                {showViewEntity(item) ?
                                                                                    <span className="a float-right mx-1" onClick={() => viewCandidate(item)}>View Candidate</span>
                                                                                    :
                                                                                    <NoPermissionsPopover />
                                                                                }
                                                                                {/* <a className="a float-right mx-1" onClick={() => showRelatedEventsPane(item, candidate)}>View Related Events</a> */}
                                                                                <br />
                                                                                {(item.candidateState || item.candidateCity) &&
                                                                                    <small className="ms-4"><em>{cityStateFormatter(item)}</em></small>
                                                                                } 
                                                                            </td>
                                                                            {candidate.workflow && candidate.workflow.map((wf, idx) => (
                                                                                <td className="text-center" key={idx}>
                                                                                    <span className="label label-success" title={wf.placementsWorkflowModel && wf.placementsWorkflowModel.workflowStatus}>
                                                                                        <i className="icon-ok icon-white"></i>
                                                                                        {wf.placementsWorkflowModel ? wf.placementsWorkflowModel.workflowStatus === 'Completed' ? < i className="fa-solid fa-check text-success fa-1x"></i> : <i className="fa-solid fa-repeat fa-1x"></i> : <span />}
                                                                                    </span>
                                                                                </td>))}

                                                                        </tr>
                                                                    ))}
                                                                </Fragment>}
                                                        </Fragment>
                                                    }
                                                </Fragment>

                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>

                                <Pagination
                                    showSizeChanger
                                    onChange={onShowSizeChange}
                                    pageSize={tableParams.pagination?.pageSize}
                                    defaultCurrent={tableParams.pagination?.current}
                                    total={tableParams.pagination?.total}
                                    size="small"
                                    className="float-end pagination"
                                />


                            </div>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Placements To Show" />
                        }
                    </MediaQuery>
                </>
            }



            <MatchPane show={matchPane.show} hidePane={() => { setMatchPane({ show: false, job: null }); }} entity={matchPane.job} from='jobs' placementStatus={status} triggerRefresh={triggerRefresh} />
            <PlacementWorkflowPane show={placementWorkflowPane?.show} hidePane={hidePlacementPane} workflowItem={placementWorkflowPane?.workflowItem} job={placementWorkflowPane?.selectedJob} candidate={placementWorkflowPane?.selectedCandidate} clientName={placementWorkflowPane?.clientName} placementStatus={status} />
        </div>
    );
}