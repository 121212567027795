import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Card, Drawer, Select, Alert, Input } from 'antd';
import statesList from '../../helpers/StatesList';
import countryStatesList from '../../helpers/CountriesStates';
import Globals from '../../config/globals';

import { useForm, Controller } from 'react-hook-form';
import { jobTypes, visaTypes, parentSpecialties, subSpecialties, orgTags, getOrganizationInfo, typesOfPractice, activeOrgTagsState } from '../../services/OrganizationsService';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat, NumericFormat } from 'react-number-format';
import MediaQuery from 'react-responsive';
import { addCandidate, isPhoneNumberUnique, userCandidates } from '../../services/CandidateService';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { insertTag } from '../../services/TagsService';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/editor.css';
import { convertToRaw, convertFromHTML, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useRecoilState } from 'recoil';
import { addClient } from '../../services/ClientsService';

export function AddClient(props) {
    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch, formState } = useForm();
    const formFieldsDefault = {
        users_ID: Globals.userInfo.users_ID,
        facebook: '',
        twitter: '',
        linkedIn: '',
        url: '',
        country: 'US',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        clientType: 'client',
        confidential: false,
        name: '',
        cellPhone: '',
        workPhone: '',
        emailAddress: ''
    }

    const facebookPrefix = "https://facebook.com/";
    const linkedInPrefix = "https://www.linkedin.com/in/";
    const twitterPrefix = "https://www.twitter.com/";
    const urlPrefix = "https://";

    const [show, setShow] = useState(false);
    const [activeOrgTags, setActiveOrgTags] = useRecoilState(activeOrgTagsState);


    const [listData, setListData] = useState({
        orgTags: [],
        unModifiedTags: []
    });

    // const [specialtyInfo, setSpecialtyInfo] = useState({
    //     subSpecialties: [],
    //     selectedAdditionalSpecialties: []
    // });

    // const [candidates, setCandidates] = useState([]);


    const [modifiedStatesList, setModifiedStatesList] = useState([]);

    // const [preferredStates, setPreferredStates] = useState([]);

    // const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    // const [showDuration, setShowDuration] = useState(false);

    // const [selectedTypesOfPractice, setSelectedTypesOfPractice] = useState([]);

    // const [phoneNumbers, setPhoneNumbers] = useState(phoneNumbersDefault);
    const [phoneNumberNotUnique, setPhoneNumberNotUnique] = useState(false);

    // const [emailAddresses, setEmailAddresses] = useState([emailDefault]);

    // const [selectedCertifications, setSelectedCertifications] = useState([certificationDefault]);
    // const [selectedLicensureRecords, setSelectedLicensureRecords] = useState([]);
    // const [selectedRelocationStates, setSelectedRelocationStates] = useState([]);

    // const [educationHistory, setEducationHistory] = useState([educationDefault]);

    // const [workHistory, setWorkHistory] = useState([workHistoryDefault]);

    // const [references, setReferences] = useState([referenceDefault]);

    const [selectedTags, setSelectedTags] = useState([]);

    // const [willingToRelocate, setWillingToRelocate] = useState(false);
    // const [showProblems, setShowProblems] = useState(false);
    // const [showMalpractice, setShowMalpractice] = useState(false);
    // const [showAffectPlacement, setShowAffectPlacment] = useState(false);

    // const [duplicateInfo, setDuplicateInfo] = useState({
    //     duplicates: [],
    //     chosenDuplicate: {},
    //     isChosenDuplicate: false
    // });

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    var isDirty = useRef(false);

    useEffect(() => {
        //var alive = true;

        setValue('formFields', formFieldsDefault);

        // combineLatest(
        //     orgTags
        // ).pipe(takeWhile(() => alive)).subscribe(([orgTags]) => {            
        //     setListData({
        //         orgTags: orgTags.map(ot => ({ label: ot.tag, value: ot.tags_ID })),
        //         unModifiedTags: orgTags
        //     });
        // });

        modifyStates();

        // return () => { alive = false; }
    }, []);

    useEffect(() => {
        setListData({
            orgTags: [...activeOrgTags].map(ot => ({ label: ot.tag, value: ot.tags_ID })),
            unModifiedTags: [...activeOrgTags],
            loaded: true
        });
    }, [activeOrgTags]);

    useEffect(() => {
        if (listData.loaded) {
            const subscription = watch((value, { name, type }) => {
                if (type === 'change') {
                    isDirty.current = true;
                }

                if (name === 'formFields.country') {
                    var states = statesList.filter(obj => {
                        return obj.country_code === value.formFields.country
                    });

                    modifyStates(states);
                }

                // if (name === 'formFields.firstName' || name === 'formFields.lastName') {
                //     const duplicates = candidates.filter(candidate => {
                //         return (
                //             (((name === 'formFields.firstName' && candidate['lastName']?.toLowerCase() === value.formFields.lastName?.toLowerCase()))) && candidate['firstName']?.toLowerCase().startsWith(value.formFields.firstName.toLowerCase())
                //             ||
                //             (((name === 'formFields.lastName' && value.formFields.firstName === '') || (name === 'formFields.lastName' && candidate['firstName']?.toLowerCase() === value.formFields.firstName?.toLowerCase()))) && candidate['lastName']?.toLowerCase().startsWith(value.formFields.lastName?.toLowerCase())
                //         );
                //     }
                //     );

                //     setDuplicateInfo({
                //         duplicates,
                //         chosenDuplicate: duplicates.length === candidates.length ? null : duplicates[0],
                //         isChosenDuplicate: (duplicates.length !== candidates.length && duplicates.length > 0) ? true : false
                //     });
                // }

            });


            return () => subscription.unsubscribe();
        }

    }, [watch, listData]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    function modifyStates(states = null) {
        let newList = states ? states : statesList.filter(obj => { return obj.country_code === 'US' });

        newList = newList.map(item => ({
            label: item.name,
            value: item.state_code
        }));

        setModifiedStatesList(newList);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setShow(false);
        props.hidePane();
        isDirty.current = false;
        Globals.isDirtyPage = false;

        setEditorState(EditorState.createEmpty());

        setValue('formFields', formFieldsDefault);
        setSelectedTags([]);
    }

    function handleSubmit() {
        let clientData = { ...getValues().formFields };

        clientData.facebook = facebookPrefix + clientData.facebook;
        clientData.linkedIn = linkedInPrefix + clientData.linkedIn;
        clientData.twitter = twitterPrefix + clientData.twitter;
        clientData.url = urlPrefix + clientData.url;

        clientData.comments = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        toast.loading("Adding Client...", { key: 'loading' });
        addClient(clientData).then(data => {
            if (data) {
                isDirty.current = false;

                toast.destroy('loading');
                toast.success('Client successfully added');

                // let selectedCandidate = {
                //     candidates: data.dashboardInformation.client
                // }

                handleClose();

                navigate(`/client-dashboard/${data}`,
                    {
                        replace: true
                    });
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => toast.error("Something went wrong"));
    }


    function goToClient() {
        // let selectedCandidate = {
        //     candidates: duplicateInfo.chosenDuplicate
        // }
        // isDirty.current = false;
        // Globals.isDirtyPage = false;

        // handleClose();
        // navigate("/candidate-dashboard",
        // {
        //     replace: true,
        //     state: { selectedCandidate, from: 'addCandidate' }
        // });
    }

    function updateTags(value) {
        isDirty.current = true;
        setSelectedTags(value);
    }

    function createTag(e) {
        if (e.keyCode === 13) {
            if (!listData.orgTags.find(tg => tg.label.includes(e.target.value))) {
                //let postdata = {
                //    tagsModel: {}
                //};

                //postdata.tagsModel.tag = e.target.value;
                //postdata.tagsModel.organizations_ID = Globals.userInfo.organizations_ID;

                let tag = {
                    tag: e.target.value,
                    organizations_ID: Globals.userInfo.organizations_ID
                }

                insertTag(tag).then(data => {
                    if (data === 'duplicate') {
                        toast.error("This tag already exists");
                    } else {
                        let st = [...selectedTags];
                        let tagsList = [...listData.unModifiedTags];

                        let tags_ID = data;

                        let t = {
                            tags_ID,
                            organizations_ID: Globals.userInfo.organizations_ID,
                            tag: e.target.value
                        };

                        st.push(tags_ID);
                        tagsList.push(t);

                        setSelectedTags([...st]);
                        orgTags.next(tagsList);
                        setActiveOrgTags(tagsList);

                        //setUpdate(!update);
                        e.target.value = '';

                        isDirty.current = true;
                    }
                }).catch(error => {
                    toast.error("Something went wrong");
                });
            }
        }

    }

    function onEditorStateChange(es) {
        isDirty.current = true;
        setEditorState(es);
    }

    const saveDisabled = phoneNumberNotUnique;

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Add Client'
            placement={'right'}
            onClose={handleClose}
            maskClosable={false}
            open={show}
            key='slider'
            width='50%'
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit} disabled={saveDisabled} title={saveDisabled ? 'Your data has errors' : ''}>Save</button>
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
            className="client"
        >
            <div className="row">
                <div className="col col-12">
                    <Card title="Client Information" className="mb-3">
                        <div className="row">
                            {/* {duplicateInfo.isChosenDuplicate &&
                                <div className="col-12">
                                    <Alert className="col-12 mb-3" message={<><strong>Possible Duplicate Entry.</strong> A <em>{duplicateInfo.chosenDuplicate?.firstName} {duplicateInfo.chosenDuplicate?.lastName}</em> already exists. <span className="a hover" onClick={goToCandidate}>Click to view candidate.</span></>} type="warning" showIcon />

                                </div>
                            } */}

                            <div className="form-group col-12">
                                <label>Client Name <span className="text-danger">*</span></label>
                                <input maxLength="100" className="ant-input" {...register('formFields.name')} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Client Type</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.clientType')} value='client' />
                                    <label> Client</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.clientType')} value='prospect' />
                                    <label> Prospect</label>
                                </div>
                            </div>

                            {Globals.userInfo.userType === 'OrgAdmin' &&
                                <div className="form-group col-lg-6 col-12">
                                    <label className="d-block">Keep this client confidential?</label>
                                    <input type="checkbox" className="custom-checkbox" {...register('formFields.confidential')} />
                                </div>
                            }
                            <div className="horizontal-clients-divider" />

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Cell Phone Number</label>
                                <Controller
                                    control={control}
                                    name="formFields.cellPhone"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                                    }
                                />

                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Work Phone Number</label>
                                <Controller
                                    control={control}
                                    name="formFields.workPhone"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                                    }
                                />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Email</label>
                                <input maxLength="100" className="ant-input" type="email" {...register('formFields.emailAddress')} />
                            </div>

                            <div className="horizontal-clients-divider" />

                            <div className="form-group col-lg-12">
                                <label>Country</label>
                                <select className="ant-input" {...register('formFields.country')} >
                                    {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>Address</label>
                                <input maxLength="100" type="text" {...register('formFields.address1')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>Address 2</label>
                                <input maxLength="100" type="text" {...register('formFields.address2')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>City</label>
                                <input maxLength="100" type="text" {...register('formFields.city')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label>State</label>
                                <select className="ant-input" {...register('formFields.state')}>
                                    <option></option>
                                    {modifiedStatesList.map((state, idx) => <option key={idx} value={state.value}>{state.label}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label>Zip</label>
                                <input maxLength="20" type="text" {...register('formFields.zip')} className="ant-input" />
                            </div>
                        </div>
                    </Card>

                    {/* <Card title='Tags' className="mb-3">
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Tags</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedTags}
                                    onChange={updateTags}
                                    options={listData.orgTags}
                                    optionFilterProp='label'
                                    onInputKeyDown={createTag}
                                    notFoundContent='Press enter to create a new item'
                                />
                            </div>
                        </div>
                    </Card> */}

                    <Card title='Social Media'>
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Facebook</label>
                                <Controller
                                    control={control}
                                    name="formFields.facebook"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="29" addonBefore={facebookPrefix} onChange={onChange} value={value} />
                                    }
                                />

                            </div>
                            <div className="form-group col-12">
                                <label>LinkedIn</label>
                                <Controller
                                    name="formFields.linkedIn"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="22" addonBefore={linkedInPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>Twitter</label>
                                <Controller
                                    name="formFields.twitter"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="26" addonBefore={twitterPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>URL</label>
                                <Controller
                                    name="formFields.url"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="40" addonBefore={urlPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                        </div>
                    </Card>

                    <Card title='Comments'>
                        <div className="row">
                            <div className="form-group col-12">
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="custom-editor"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'list', 'textAlign'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                            bold: { className: 'bordered-option-classname' },
                                            italic: { className: 'bordered-option-classname' },
                                            underline: { className: 'bordered-option-classname' },
                                            strikethrough: { className: 'bordered-option-classname' },
                                            code: { className: 'bordered-option-classname' },
                                        },
                                        list: {
                                            unordered: { className: 'demo-option-custom' },
                                            ordered: { className: 'demo-option-custom' },
                                            indent: { className: 'demo-option-custom' },
                                            outdent: { className: 'demo-option-custom' },
                                        },
                                        textAlign: {
                                            left: { className: 'demo-option-custom' },
                                            center: { className: 'demo-option-custom' },
                                            right: { className: 'demo-option-custom' },
                                            justify: { className: 'demo-option-custom' },
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Drawer>
    );
}