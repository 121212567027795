import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Table, Tabs, Input, Empty, Divider, Alert } from 'antd';
import { filterTable, filterTableFromColumns } from '../../helpers/FilterTable';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { orgInfoLoading } from '../../services/OrganizationsService';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { activeUserJobsState, getJobsByOrganization } from '../../services/JobsService';
import { AddJob } from './AddJob';
import { show } from 'antd-mobile/es/components/dialog/show';
import { MatchPane } from '../Placements/MatchPane';
import JobPlacementWorkflow from './JobPlacementWorkflow';
import { useJobs } from '../../swr/jobs';
import { cityStateFormatter } from '../../helpers/Location';

export function JobsHome(props) {

    const navigate = useNavigate();

    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const [loading, setLoading] = useState(false);

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [jobsToFilterAgainst, setJobsToFilterAgainst] = useState([]);

    const [activeTab, setActiveTab] = useState('myJobs');

    const [activeUserJobs, setActiveUserJobs] = useRecoilState(activeUserJobsState);

    const [jobLists, setJobLists] = useState({
        jobList: [],
        allUserJobs: [],
        filterUserJobs: [],
        allJobs: [],
        loaded: false
    });

    const [workflowPane, setWorkflowPane] = useState({
        show: false,
        job: null
    });

    const [showAddJob, setShowAddJob] = useState(false);

    const [status, setStatus] = useState('Active');

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const [searchValue, setSearchValue] = useState('');

    const { data, isLoading, error } = useJobs(uInfo.organizations_ID, uInfo.users_ID, status, tableParams.pagination.pageSize, tableParams.pagination.current - 1, searchValue);


    useEffect(() => {
        var alive = true;

        combineLatest(
            orgInfoLoading
        ).pipe(takeWhile(() => alive)).subscribe(([orgInfoLoading]) => {
            setLoading(orgInfoLoading);
        });

        return () => { alive = false; }
    }, []);

    useEffect(() => {
        if (!data) return;
        setActiveUserJobs(data);
    }, [data, isLoading]);

    useEffect(() => {
        if (!loading && activeUserJobs) {
            let jobsToSet = activeUserJobs.userJobs ? [...activeUserJobs.userJobs] : [];

            if(activeTab === 'companyJobs') { 
                jobsToSet = activeUserJobs.companyJobs ? [...activeUserJobs.companyJobs] : [];
            }

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: jobsToSet.length > 0 ? jobsToSet[0].totalCount : 0
                }
            });

            setJobLists({
                jobList: jobsToSet,
                allUserJobs: activeUserJobs.userJobs ? [...activeUserJobs.userJobs] : [],
                filterUserJobs: jobsToSet,
                allJobs: activeUserJobs.companyJobs ? [...activeUserJobs.companyJobs] : [],
                loaded: true
            });

            setJobsToFilterAgainst(jobsToSet);
        }

        setLoading(false);
    }, [activeUserJobs, activeTab, loading]);

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }


    function navigateToJobDashboard(row) {
        navigate(`/job-dashboard/${row.jobs_ID}`,
            {
                replace: true
            });
    }

    function showWorkflowPane(job) {
        setWorkflowPane({
            show: true,
            job
        });
    }

    function matchFormatter(cell, row) {
        return (
            <button id="matchButton" className="ant-btn ant-btn-match btn-sm" onClick={() => showWorkflowPane(row)}> Match Candidates</ button>
        );
    }

    var jobColumns = [
        {
            title: 'Confidential',
            dataIndex: 'confidential',
            key: 'confidential',
            render: (cell) => cell ? <i class="fa-solid fa-key"></i> : <span>No</span>
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: 'Job Name',
            dataIndex: 'jobTitle',
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'Specialty',
            dataIndex: 'parentSpecialty',
            key: 'parentSpecialty',
            ellipsis: true,
        },
        {
            title: 'Sub-Specialty',
            dataIndex: 'subSpecialty',
            key: 'subSpecialty',
            ellipsis: true,
        },
        {
            dataIndex: 'city',
            title: 'Location',
            render: (cell, row) => cityStateFormatter(row)
        },
        {
            title: 'Recruiter',
            dataIndex: 'recruiter',
            key: 'recruiter',
        },
        {
            title: 'Match',
            render: matchFormatter
        },
    ];

    function handleSearch(value) {
        setSearchValue(value);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const columns = jobColumns.filter(c => !c.hide);

    var tabs = [
        {
            label: <div className="job-text">Job Workflow Summary</div>,
            key: 'jobWorkflowSummary',
            children: (
                <JobPlacementWorkflow />
            )
        },
        {
            label: <div className="job-text">My Jobs</div>,
            key: 'myJobs',
            children: (
                <>
                    {(loading) ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider>
                            {jobLists.filterUserJobs?.length > 0 ?
                                <>


                                    <Table
                                        rowKey={(record) => record.jobs_ID}
                                        dataSource={jobLists.filterUserJobs}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToJobDashboard(record) : console.log("Selected") }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no jobs - to begin adding a job, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Job'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        },
        {
            label: <div className="job-text">Organizational Jobs</div>,
            key: 'companyJobs',
            children: (
                <>
                    {(loading) ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider>
                            {jobLists.filterUserJobs?.length > 0 ?
                                <>


                                    <Table
                                        rowKey={(record) => record.jobs_ID}
                                        dataSource={jobLists.filterUserJobs}
                                        columns={columns.filter(c => c.key !== 'confidential')}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToJobDashboard(record) : console.log("Selected") }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no jobs - to begin adding a job, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Job'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        }
    ];

    if (uPermissions.users_ID && uPermissions.readJobs !== 'company') {
        let companyJobsIndex = tabs.findIndex(item => item.key === 'companyJobs');
        tabs.splice(companyJobsIndex, 1);
    }

    return (
        <>
            {(uPermissions.users_ID && uPermissions.readJobs === 'none') ?
                <Empty description={<p>You do you have the appropriate access to view jobs. Please contact your system administrator if you believe this is a mistake.</p>} />
                :
                <>
                    <div className="d-flex justify-content-start">
                        <Alert className="float-start mb-3" message="Full job functionality is still in progress. More robust features are on the way." type="info" showIcon closable />
                    </div>
                    {uPermissions?.users_ID && uPermissions?.createJobs &&
                        <div className="d-flex justify-content-end">
                            <button className="ant-btn ant-btn-primary float-end" onClick={() => setShowAddJob(true)}>
                                <i className="far fa-plus-square"></i>
                                &nbsp;
                                Add Job
                            </button>
                        </div>
                    }
                    <div className="card-container">
                        <Tabs activeKey={activeTab} type="card" items={tabs} onChange={handleTabChange} />
                    </div>

                    <AddJob show={showAddJob} from='jobs-dashboard' hidePane={() => setShowAddJob(false)} />
                    <MatchPane show={workflowPane.show} hidePane={() => setWorkflowPane({ show: false, job: null })} entity={workflowPane.job} from='jobs' placementStatus="Active" />
                </>
            }
        </>
    );
}