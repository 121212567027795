import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Layout, Menu, Table } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';

import { activeUserInfo } from '../../services/UsersService';
import { getRecruiterbalmNumbers } from '../../services/SmsService';

export function AdminHome(props) {
    const navigate = useNavigate();
    const uInfo = useRecoilValue(activeUserInfo);
    const { Content, Sider } = Layout;

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    const [mappedNumbers, setMappedNumbers] = useState([]);

    useEffect(() => {
        getRecruiterbalmNumbers().then(data => {
            setMappedNumbers(data);
        });
    }, []);

    useEffect(() => {
        if (uInfo?.users_ID) {
            if (uInfo.userType !== 'SuperAdmin') {
                navigate('/dashboard', { replace: true });
            }
        }
    }, [uInfo]);

    var columns = [
        {
            title: 'Phone Number',
            dataIndex: ['phoneNumber'],
            key: 'phoneNumber',
            ellipsis: true,
            sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber)
        }, {
            title: 'User',
            dataIndex: ['user', 'firstName'],
            key: 'firstName',
            ellipsis: true,
            render: (cell, row) => <span>{row.user?.firstName} {row.user?.lastName}</span>,
            sorter: (a, b) => a.user?.firstName?.localeCompare(b.user?.firstName)
        }, {
            title: 'Organization',
            dataIndex: ['organization', 'name'],
            key: 'name',
            ellipsis: true,
            sorter: (a, b) => a.organization?.name?.localeCompare(b.organization?.name)
        }
    ];

    var menuItems = [
        {
            key: 'manage-sms-numbers',
            icon: <NotificationOutlined />,
            label: 'Bandwith Phone Numbers'
        }
    ];

    const menu = (<Menu
        mode={isDesktop ? "inline" : "horizontal"}
        defaultSelectedKeys={['manage-sms-numbers']}
        style={{
            height: '100%',
        }}
        items={menuItems}
    />);

    return (
        <div><Content style={{ border: '1px solid' }}>
            <Layout
                className="site-layout-background"
                style={{
                    padding: '24px 0',
                }}
            >
                {isDesktop ?
                    <Sider className="settings-sider" width={200} theme='light'>
                        {menu}
                    </Sider>
                    :
                    menu
                }
                <Content
                    style={{
                        padding: '10px 24px',
                        minHeight: 280,
                    }}
                >
                    <Table
                        rowKey={(row) => row.phoneNumber}
                        dataSource={mappedNumbers}
                        columns={columns}
                        size='small'
                    />
                </Content>
            </Layout>
        </Content></div>    
    );
}