import Axios from '../config/axios';
import { BehaviorSubject } from 'rxjs';
import Globals from '../config/globals';
import { activeUserCandidatesState, userCandidates } from './CandidateService';
import { unreadMessages } from './SmsService';
import { userEvents } from './EventsService';
import { setRecoil } from "recoil-nexus"
import { atom } from 'recoil';

export const jobTypes = new BehaviorSubject([]);
export const eventTypes = new BehaviorSubject([]);
export const orgTags = new BehaviorSubject([]);
export const visaTypes = new BehaviorSubject([]);
export const parentSpecialties = new BehaviorSubject([]);
export const subSpecialties = new BehaviorSubject([]);
export const typesOfPractice = new BehaviorSubject([]);
export const organizationInfo = new BehaviorSubject({});
export const orgInfoLoading = new BehaviorSubject(false);


export const activeEventTypes = atom({
    key: 'activeEventTypes',
    default: []
});

export const activeJobTypesState = atom({
    key: 'activeJobTypesState',
    default: []
});

export const activeOrgTagsState = atom({
    key: 'activeOrgTagsState',
    default: []
});

export const activeParentSpecialtiesState = atom({
    key: 'activeParentSpecialtiesState',
    default: []
});

export const activeSubSpecialtiesState = atom({
    key: 'activeSubSpecialtiesState',
    default: []
});

export const activeVisaTypesState = atom({
    key: 'activeVisaTypesState',
    default: []
});

export const activeWorkflowItemsState = atom({
    key: 'activeWorkflowItemsState',
    default: []
});


export function getOrganizationInfo() {
    orgInfoLoading.next(true);
    return Axios.get('/api/GetOrganizationInfo', {
        params: {
            org_ID: Globals.userInfo.organizations_ID,
            users_ID: Globals.userInfo.users_ID,
            userType: Globals.userInfo.userType
        }
    }).then(response => {
        updateOrgInfo(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function updateOrgInfo(data) {
    userCandidates.next(data.userCandidates);
    jobTypes.next(data.orgJobTypes);
    orgTags.next(data.orgTags);
    visaTypes.next(data.visaTypes);
    parentSpecialties.next(data.parentSpecialties);
    subSpecialties.next(data.subSpecialties);
    typesOfPractice.next(data.typesOfPractice);
    organizationInfo.next(data.organizationInfo);
    orgInfoLoading.next(false);
    unreadMessages.next(data.unreadMessages);
    eventTypes.next(data.eventTypes);
    userEvents.next(data.events);

    setRecoil(activeEventTypes, data.eventTypes);
    setRecoil(activeJobTypesState, data.orgJobTypes);
    setRecoil(activeUserCandidatesState, data.userCandidates);
    setRecoil(activeOrgTagsState, data.orgTags);
    setRecoil(activeParentSpecialtiesState, data.parentSpecialties);
    setRecoil(activeSubSpecialtiesState, data.subSpecialties);
    setRecoil(activeVisaTypesState, data.visaTypes);
    setRecoil(activeWorkflowItemsState, data.workflowItems);
}

export function insertOrganization(postdata) {
    return Axios.post(`/api/InsertOrganization`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateOrganization(postdata) {
    return Axios.post(`/api/UpdateOrganization`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getUsersByOrganization(organizations_ID) {
    return Axios.get('/api/GetUsersByOrganization', { params: { organizations_ID } }).then(response => response.data).catch(error => error);
}

export function getAllOrganizations(users_ID) {
    return Axios.get(`/api/GetAllOrganizations`, {
        params: {
            users_ID
        }
    })
        .then(response => response.data).catch(error => error);
}