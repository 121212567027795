import React, { useEffect, useState, useRef } from 'react';

import { Input, AutoComplete, Popover } from 'antd';
import { MobileOutlined } from '@ant-design/icons'

import { highlightContent } from '../../helpers/Highlight';
import { getCampaignMembersSearchResults } from '../../services/CampaignService';
import { Constants } from '../../config/globals';

export function CandidateSearch(props) {

    const [state, setState] = useState({
        allCompanies: [],
        results: [],
        searchValue: ''
    });

    const [searchResults, setSearchResults] = useState({
        results: [],
        loaded: false
    });

    const [showDropdown, setShowDropdown] = useState(false);

    var timeout = useRef(null);

    useEffect(() => {
        handleFilterOut();
        
    }, [props.selectedCandidates, props.selectedGroups, props.selectedUsers]);

    function handleSearch_v2(value) {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        setState({
            ...state,
            searchValue: value
        });

        if (value) {
            timeout.current = setTimeout(() => performSearch(value), 500);
        } else {
            setSearchResults({
                results: [],
                loaded: true
            });
        }
    }

    function handleFilterOut() {
        let results = [];
        let candIdx = searchResults.results.findIndex(res => res.label === 'Candidates');
        let groupIdx = searchResults.results.findIndex(res => res.label === 'Groups')
        let userIdx = searchResults.results.findIndex(res => res.label === 'Users')

        if (candIdx >= 0) {
            let revisedData = searchResults.results[candIdx].options?.map(val => ({
                ...val,
                candidateinfo: {
                    ...val.candidateinfo,
                    isSelected: props.selectedCandidates.find(arr1Obj => arr1Obj?.candidates?.candidates_ID.toString() === val?.candidatesid.toString()) ? true : false
                }
            }));

            var candidateObj = {
                label: 'Candidates',
                options: revisedData.map(item => renderCandidate(item.candidateinfo, state.searchValue))
            }

            results.push(candidateObj);
        }    

        if (groupIdx >= 0) {

            let revisedData = searchResults.results[groupIdx].options?.map(val => ({
                ...val,
                groupinfo: {
                    ...val.groupinfo,
                    isSelected: props.selectedGroups.find(arr1Obj => arr1Obj?.groups_ID.toString() === val?.groupsid.toString()) ? true : false
                }
            }));

            var groupObj = {
                label: 'Groups',
                options: revisedData.map(item => renderGroup(item.groupinfo, state.searchValue))
            }

            results.push(groupObj);
        }    

        if (userIdx >= 0) {
            let revisedData = searchResults.results[userIdx].options?.map(val => ({
                ...val,
                userinfo: {
                    ...val.userinfo,
                    isSelected: props.selectedUsers.find(arr1Obj => arr1Obj?.user?.users_ID.toString() === val?.usersid.toString()) ? true : false
                }
            }));

            var userObj = {
                label: 'Users',
                options: revisedData.map(item => renderUser(item.userinfo, state.searchValue))
            }

            results.push(userObj);
        }   

        setSearchResults({
            results,
            loaded: true
        });
    }

    function performSearch(value) {
        getCampaignMembersSearchResults(value, props.users_ID).then(data => {
            let results = [];
            if (data.candidates) {
                let revisedData = data.candidates.map(val => ({
                    ...val,
                    isSelected: props.selectedCandidates.find(arr1Obj => arr1Obj?.candidates?.candidates_ID === val?.candidates?.candidates_ID) ? true : false
                }));

                var candidateObj = {
                    label: 'Candidates',
                    options: revisedData.map(item => renderCandidate(item, value))
                }

                results.push(candidateObj);
            }

            if (data.groups) {
                let revisedData = data.groups.map(val => ({
                    ...val,
                    isSelected: props.selectedGroups.find(arr1Obj => arr1Obj?.groups_ID === val?.groups_ID) ? true : false
                }));

                var groupObj = {
                    label: 'Groups',
                    options: revisedData.map(item => renderGroup(item, value))
                }

                results.push(groupObj);
            }

            if (data.users) {
                let revisedData = data.users.map(val => ({
                    ...val,
                    isSelected: props.selectedUsers.find(arr1Obj => arr1Obj?.user.users_ID === val?.user.users_ID) ? true : false
                }));

                var userObj = {
                    label: 'Users',
                    options: revisedData.map(item => renderUser(item, value))
                }

                results.push(userObj);
            }

            if (data.organizations) {
                let revisedData = data.organizations.map(val => ({
                    ...val,
                    isSelected: props.selectedOrganizations?.find(arr1Obj => arr1Obj?.organizations_ID === val?.organizations_ID) ? true : false
                }));

                var orgObj = {
                    label: 'Organizations',
                    options: revisedData.map(item => renderOrganization(item, value))
                }

                results.push(orgObj);
            }

            setSearchResults({
                results,
                loaded: true
            });
        });
    }

    function renderCandidate(candidate, searchValue) {

        var highlight = highlightContent(candidate.candidates.firstName + " " + candidate.candidates.lastName, searchValue);

        return {
            value: candidate.candidates.candidates_ID.toString(),
            candidatesid: candidate.candidates.candidates_ID,
            candidateinfo: candidate,
            type: 'candidate',
            label: (
                <div>
                    <span>{candidate?.textNumber && <MobileOutlined className="icon-sm text-success" />} </span>
                    {highlight ? highlight.map((item, idx) => <span key={'c' + idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>) : candidate.candidates.firstName + " " + candidate.candidates.lastName}
                    {candidate.candidates.users_ID !== props.users_ID &&
                        <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This is an {Constants.companyCandidateText}</p>}>
                            &nbsp;<i className="candidate-text fa-solid fa-users-between-lines"></i>
                        </Popover>}
                    <div className="row">
                        {(!highlight || highlight.length === 1) && <div className="col-3">{candidate.candidates?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>Specialty: {highlightContent(candidate.candidates.firstName, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {(!highlight || highlight.length === 1) && <div className="col-3">{candidate.candidates?.lastName?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>Specialty: {highlightContent(candidate.candidates.lastName, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {(!highlight || highlight.length === 1) && <div className="col-3">{candidate.parentSpecialty?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>Specialty: {highlightContent(candidate.parentSpecialty, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {(!highlight || highlight.length === 1) && <div className="col-3">{candidate.subSpecialty?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>Sub Specialty: {highlightContent(candidate.subSpecialty, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {(!highlight || highlight.length === 1) && <div className="col-2">{candidate.candidates.city?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>City: {highlightContent(candidate.candidates.city, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {(!highlight || highlight.length === 1) && <div className="col-2">{candidate.candidates.state?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>State: {highlightContent(candidate.candidates.state, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {candidate.isSelected ? <div className="col"><em className="text-success float-end">Selected</em></div> : <div className="col"><a className="float-end a hover">Select</a></div>}
                    </div>
                </div>
            )
        }
    }

    function renderGroup(group, searchValue) {

        var highlight = highlightContent(group.group, searchValue);

        return {
            value: group.groups_ID.toString(),
            groupsid: group.groups_ID,
            groupinfo: group,
            type: 'group',
            label: (
                <div>
                    {highlight ? highlight.map((item, idx) => <span key={'g'+idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>) : group.group}
                    {group.isSelected ? <div className="col"><em className="text-success float-end">Selected</em></div> : <div className="col"><a className="float-end a hover">Select</a></div>}
                </div>
            )
        }
    }

    function renderUser(user, searchValue) {

        var highlight = highlightContent(user.user.firstName + " " + user.user.lastName, searchValue);

        return {
            value: user.user.users_ID.toString(),
            usersid: user.user.users_ID.toString(),
            userinfo: user,
            type: 'user',
            label: (
                <div>
                    <span>{user?.user.cellPhone && <MobileOutlined className="icon-sm text-success" />} </span>
                    {highlight ? highlight.map((item, idx) => <span key={'c' + idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>) : user.user.firstName + " " + user.user.lastName}
                    <div className="row">
                        {(!highlight || highlight.length === 1) && <div className="col-3">{user.organization?.name?.toLowerCase().includes(searchValue.toLowerCase()) && <sub>Organization: {highlightContent(user.organization.name, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub>}</div>}
                        {user.isSelected ? <div className="col"><em className="text-success float-end">Selected</em></div> : <div className="col"><a className="float-end a hover">Select</a></div>}
                    </div>
                </div>
            )
        }
    }

    function renderOrganization(org, searchValue) {

        var highlight = highlightContent(org.name, searchValue);

        return {
            value: org.organizations_ID.toString(),
            orgid: org.organizations_ID,
            orginfo: org,
            type: 'organization',
            label: (
                <div>
                    {highlight ? highlight.map((item, idx) => <span key={'g' + idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>) : org.name}
                    {org.isSelected ? <div className="col"><em className="text-success float-end">Selected</em></div> : <div className="col"><a className="float-end a hover">Select</a></div>}
                </div>
            )
        }
    }

    function handleSelect(option, value) {
        console.log(value);
        if (!(value.groupinfo?.isSelected || value.candidateinfo?.isSelected || value.userinfo?.isSelected)) {
            props.addCandidate(value);
        }
    }

    return (
            <div className="form-group">
                <div className="form-label">Search</div>
                <AutoComplete
                    popupClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={500}
                    style={{
                        width: '100%',
                    }}
                    options={searchResults.results}
                    onSearch={handleSearch_v2}
                    notFoundContent="No Results"
                    onSelect={handleSelect}
                    value={state.searchValue}
                    open={showDropdown}
                    onBlur={() => setShowDropdown(false)}
                    onFocus={() => setShowDropdown(true)}
                >
                    <Input.Search size="large" placeholder="Search here..." />
                </AutoComplete>
            </div>
    );
}