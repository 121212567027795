import Axios from '../config/axios';
import { BehaviorSubject } from 'rxjs';

export const userEvents = new BehaviorSubject([]);

export const emptyEvent = {
    eventTypes_ID: null,
    subject: '',
    details: '',
    priority: 'normal',
    eventDateTime: null,
    status: 'not started',
    completedDate: null,
    eventReminder: null
}

export function addEvent(postdata) {
    return Axios.post(`/api/AddEvent`, postdata
    ).then(response => response.data).catch(error => error);
}

export function editEvent(postdata) {
    return Axios.post(`/api/EditEvent`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteEvent(postdata) {
    return Axios.post(`/api/DeleteEvent`, postdata
    ).then(response => response.data).catch(error => error);
}

export function setEventsAsSeenBySystem(postdata) {
    return Axios.post('/api/SetEventsAsSeenBySystem', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function setEventsAsNotSeenBySystem(postdata) {
    return Axios.post('/api/SetEventsAsNotSeenBySystem', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function dismissEvents(postdata) {
    return Axios.post('/api/DismissEvents', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function setNotificationsActive(postdata) {
    return Axios.post('/api/SetNotificationsActive', postdata)
        .then(response => response.data)
        .catch(error => error);
}