import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';

import JoditEditor, { Jodit } from 'jodit-react';
import { deleteEmailTmplate, getEmailTemplate, insertEmailTemplate, updateEmailTemplate } from '../../services/EmailService';
import { toast, confirm } from '@rickylandino/react-messages';
import { activeUserInfo } from '../../services/UsersService';

export function EmailTemplates(props) {

    const { register, getValues, setValue, watch } = useForm();

    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateDropdown, setTemplateDropdown] = useState(props?.templates?.filter(t => t.templateType === 'candidate') || []);
    const [emailSignatures, setEmailSigantures] = useState({});
    const uInfo = useRecoilValue(activeUserInfo);
    const [disableInput, setDisableInput] = useState(false);
    

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'templateType') {
                setTemplateDropdown(props?.templates?.filter(t => t.templateType === value[name]));
                setSelectedTemplate(null);
                setValue('templateName', '');
                setContent('');
            }
        });

        return () => subscription.unsubscribe();

    }, [watch]);

    useEffect(() => {
        setTemplateDropdown(props?.templates?.filter(t => t.templateType === 'candidate') || []);

        let signatures = {};
        props?.templates?.filter(et => et.templateType === 'signature')
            .forEach(et => {
                signatures[et.emailTemplates_ID] = et.templateName
            });

        setEmailSigantures(signatures);
    }, [props.templates]);

    function selectTemplate(e) {
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;

        if (value) {
            let foundTemplate = templateDropdown.find(t => t.emailTemplates_ID === parseInt(value));
            if (foundTemplate) {
                setDisableInput(true);
                toast.loading("Fetching Template...", { key: 'loading' });
                
                setValue('templateName', foundTemplate.templateName);
                setSelectedTemplate(foundTemplate);
                
                getEmailTemplate(foundTemplate.emailTemplates_ID).then(data => {
                    setSelectedTemplate(data);
                    setContent(data.templateContentString);
                    toast.destroy('loading');
                    setDisableInput(false);
                });
            } else {
                setSelectedTemplate(null);
                setValue('templateName', '');
                setContent('');
            }
        } else {
            setSelectedTemplate(null);
            setValue('templateName', '');
            setContent('');
        }
    }
   
    function handleSubmit() {
        if (getValues().templateName) {
            if (selectedTemplate) {
                let postdata = {
                    ...selectedTemplate,
                    templateContentString: content
                }

                updateEmailTemplate(postdata).then(data => {
                    if (data?.emailTemplates_ID) {
                        let newList = [...props.templates];
                        let idx = newList.findIndex(item => item.emailTemplates_ID === parseInt(data.emailTemplates_ID));
                        if (idx >= 0) {
                            toast.success("Your email template has been updated");
                            newList[idx] = data;
                            props.updateTemplates(newList);
                        }
                    } else {
                        toast.error("Something went wrong");
                    }
                });

            } else {
                //add template
                let postdata = {
                    templateType: getValues().templateType,
                    templateName: getValues().templateName,
                    templateContentString: content,
                    users_ID: props.users_ID,
                    isPrivate: true
                };

                insertEmailTemplate(postdata).then(data => {
                    if (data?.emailTemplates_ID) {
                        toast.success("Your email template has been created");

                        setSelectedTemplate(data);

                        let newList = [...props.templates];

                        newList.push(data);

                        props.updateTemplates(newList);
                    } else {
                        toast.error("Something went wrong")
                    }
                });
            }
        } else {
            toast.error("Please fill in all required fields");
        }
    }

    function handleDeleteEmailTemplate() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this email template.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {
                    id: selectedTemplate.emailTemplates_ID,
                    id2: uInfo.users_ID
                }
                deleteEmailTmplate(postdata).then(data => {
                    if (data) {
                        setSelectedTemplate(null);
                        setValue('templateName', '');
                        setContent('');
                        props.updateTemplates(data);

                        toast.success("Email Template has been deleted");
                    } else {
                        toast.error("Something went wrong");
                    }
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    //var joditButtons = [...Jodit.defaultOptions.buttons, {
    //    name: 'Signatures',
    //    list: emailSignatures,
    //    exec: (ed, nodeOrFalse, control, origEvent, btn) => {
    //        let foundTemp = props.templates?.find(et => et.emailTemplates_ID === parseInt(control.control.name));
    //        if (foundTemp) {
    //            ed.s.insertHTML(foundTemp.templateContentString);
    //        }
    //    },
    //    template: function (ed, key, value) {
    //        return '<div>' + key + '</div>';
    //    }
    //}];

    var joditButtons = [...Jodit.defaultOptions.buttons];

    if (getValues().templateType !== 'signature') {
        joditButtons.push({
            name: 'Signatures',
            list: emailSignatures,
            exec: (ed, nodeOrFalse, control, origEvent, btn) => {
                let foundTemp = props.templates?.find(et => et.emailTemplates_ID === parseInt(control.control.name));
                if (foundTemp) {
                    ed.s.insertHTML(foundTemp.templateContentString);
                }
            }
        });
    } else {
        let sigIdx = joditButtons.findIndex(jb => jb.name === 'Signatures');
        if (sigIdx >= 0) {
            joditButtons.splice(sigIdx, 1);
        }
    }

    return (
        <div className="row">
            <div className="form-group col-lg-3 col-12">
                <label>Template Type</label>
                <select className="ant-input" {...register('templateType')} disabled={disableInput}>
                    <option value='candidate'>Candidate</option>
                    <option value='recruiter'>Recruiter</option>
                    <option value='generic'>Generic</option>
                    <option value='signature'>Signature</option>
                </select>
            </div>

            <div className="horizontal-campaigns-divider"></div>

            <div className="form-group col-lg-5 col-12">
                <label>Selected Template</label>
                <select className="ant-input" onChange={selectTemplate} value={selectedTemplate?.emailTemplates_ID || 0} disabled={disableInput}>
                    <option value={null}>{'<Add New>'}</option>
                    {templateDropdown.map((t, idx) => <option key={idx} value={t.emailTemplates_ID}>{t.templateName}</option>)}
                    
                </select>
            </div>

            <div className="form-group col-lg-8 col-12">
                <label>Template Name <span className="text-danger">*</span></label>
                <input className="ant-input" {...register('templateName')}  disabled={disableInput} />
            </div>

            <div className="col col-12">
                <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => { }}
                    config={{
                        readonly: disableInput,
                        height: "500",
                        allowResizeY: true,
                        uploader: {
                            insertImageAsBase64URI: true
                        },
                        buttons: joditButtons,
                        removeButtons: ['source', 'about', 'video', 'file', 'fullsize', 'preview'],
                    }}
                />
            </div>

            <div className="col col-12 mt-3">
                <button className="ant-btn ant-btn-primary" onClick={handleSubmit}>Save Template</button>
                {selectedTemplate?.emailTemplates_ID &&
                    <div className="float-end">
                        <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteEmailTemplate}><i className='far fa-trash-alt'></i> &nbsp; Delete this Template</button>
                    </div>
                }
            </div>
        </div> 
    );
}