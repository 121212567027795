import React, { useEffect, useState } from 'react';
import { dateWithNoTimezone } from './DateFormat';
import Moment from 'moment';
import statesList from './StatesList';

export function ManipulateChangeList(props) {

    const [state, setState] = useState({
        loaded: false,
        objCount: 0
    });

    useEffect(() => {
        let oldValues = props?.oldValues && JSON.parse(props.oldValues);
        let changedValues = props.changeValues && JSON.parse(props.changeValues);

        setState({
            oldValues,
            changedValues,
            loaded: true,
            objCount: changedValues ? Object.keys(changedValues).length : 0
        });
    }, []);

    function replaceKeyWithDisplayName(key) {
        key = key?.replace(/([a-z])([A-Z])/g, '$1 $2') || key;
        return key?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    function replaceValueWithDisplayValue(key, value, listData) {
        let retval = "";
        var isValEmpty = value === "";

        if (isValEmpty) {
            return "Empty";
        }

        switch (key) {
            case 'jobTypes':
                var splitval = value.split("|");
                splitval.forEach(item => {
                    var entity = listData?.jobTypes?.find(listItem => listItem.jobTypes_ID === parseInt(item));
                    if (entity) {
                        retval += entity.jobType + ", ";
                    }
                });

                retval = retval.substring(0, retval.length - 2);
                break;
            case 'typesOfPractice':
                var splitval = value.split("|");
                splitval.forEach(item => {
                    var entity = listData?.typesOfPractice?.find(listItem => listItem.typesOfPractice_ID === parseInt(item));
                    if (entity) {
                        retval += entity.typeOfPractice + ", ";
                    }
                });

                retval = retval.substring(0, retval.length - 2);
                break;
            case 'visaTypes_ID':
                var entity = listData?.visaTypes?.find(listItem => listItem.visaTypes_ID === parseInt(value))
                if (entity) {
                    retval = entity.visaType;
                }

                break;
            case 'medicalSchoolGraduationDate':
            case 'schoolDate':
                retval = value ? Moment(dateWithNoTimezone(value)).format('MM/YYYY') : "Empty";
                break;
            case 'licensure':
            case 'preferredStatesToWork':
            case 'preferredStates':
                var splitval = value.split("|");
                splitval.forEach(item => {
                    var entity = statesList?.find(listItem => listItem.state_code === item);
                    if (entity) {
                        retval += entity.state_code + ", ";
                    }
                });

                retval = retval.substring(0, retval.length - 2);
                break;
            default:
                switch (value?.toString()) {
                    case 'true':
                        retval = 'Yes';
                        break;
                    case 'false':
                        retval = 'No';
                        break;
                    default:
                        retval = value?.toString();
                        break;
                }
                break;
        }

        return retval;
    }

    const returnDisplayFirstRow = () => {
        if (Object.keys(state?.changedValues)[0]?.includes('Removed') || Object.keys(state?.changedValues)[0]?.includes('Added')) {
            return (
                <>
                    <strong>{replaceKeyWithDisplayName(Object.keys(state?.changedValues)[0])}</strong>: <em className="font-weight-bold">{replaceValueWithDisplayValue(Object.keys(state?.changedValues)[0], state.changedValues[Object.keys(state.changedValues)[0]], props.listData)}</em>
                </>
            );
        } else {
            return (
                <>
                    <strong>{replaceKeyWithDisplayName(Object.keys(state?.changedValues)[0])}</strong> was updated from <em className="font-weight-bold">{replaceValueWithDisplayValue(Object.keys(state?.changedValues)[0], state.oldValues[Object.keys(state.oldValues)[0]], props.listData)}</em> to <em className="font-weight-bold">{replaceValueWithDisplayValue(Object.keys(state?.changedValues)[0], state.changedValues[Object.keys(state.changedValues)[0]], props.listData)}</em> {state.objCount > 1 && "..."}
                </>    
            );
        }
    }

    return (
        <>
            {(props.changeLocation !== 'Candidate Details' && props.changeLocation !== 'Compensation') ?
                <div>A change was made to {props.changeLocation}</div>
                :
                <>
                    {(state?.loaded && state.changedValues) &&
                        <>
                            {props.onlyShowFirst ?
                                <div>
                                    {returnDisplayFirstRow()}
                                </div>
                                :
                                <ul className="row">
                                    {Object.keys(state.changedValues).map((key, i) => {
                                        return (
                                            <li className="col col-lg-6 col-12" key={i}><strong>{replaceKeyWithDisplayName(key)}</strong> was updated from <em className="font-weight-bold">{replaceValueWithDisplayValue(key, state.oldValues[key], props.listData)}</em> to <em className="font-weight-bold">{replaceValueWithDisplayValue(key, state.changedValues[key], props.listData)}</em></li>
                                        )
                                    })}
                                </ul>
                            }
                        </>
                    }
                </>
            }
        </>
    );

}

export function CapitalizeFirstLetter(string) {
    if(!string) {
        return '';
    }
    
    return string.charAt(0).toUpperCase() + string.slice(1);
}