import React, { useState, useEffect, useRef } from 'react';

import { Descriptions, Drawer, Alert, Select } from 'antd';
import statesList from '../../../helpers/StatesList';
import countryStatesList from '../../../helpers/CountriesStates';
import Globals from '../../../config/globals';

import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CapitalizeFirstLetter } from '../../../helpers/ManipulateText';
import { getChangesToTrack } from '../../../helpers/DetectChanges';
import { canSelectedJobBeUpdated, updateJob } from '../../../services/JobsService';
import { activeJobTypesState, activeParentSpecialtiesState, activeSubSpecialtiesState, activeVisaTypesState } from '../../../services/OrganizationsService';
import { getClientsByRecruiter } from '../../../services/ClientsService';
import { activeUserInfo } from '../../../services/UsersService';

export function JobInformationCard(props) {

    const { register, getValues, setValue, control, watch, formState } = useForm();

    const canUpdate = useRecoilValue(canSelectedJobBeUpdated);

    const uInfo = useRecoilValue(activeUserInfo);

    const [activeJobTypes, setActiveJobTypes] = useRecoilState(activeJobTypesState);
    const [clientList, setClientList] = useState([]);

    const [activeParentSpecialties, setActiveParentSpecialties] = useRecoilState(activeParentSpecialtiesState);
    const [activeSubSpecialties, setActiveSubSpecialties] = useRecoilState(activeSubSpecialtiesState);
    const [subSpecialtiesByParent, setSubSpecialtiesByParent] = useState([]);

    const [activeVisaTypes, setActiveVisaTypes] = useRecoilState(activeVisaTypesState);

    const [modifiedJobTypes, setModifiedJobTypes] = useState([]);

    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [stickyJobTypes, setStickyJobTypes] = useState([]);
    const [showDuration, setShowDuration] = useState(false);

    const [showVisaTypes, setShowVisaTypes] = useState(false);
    const [selectedVisaTypes, setSelectedVisaTypes] = useState([]);
    const [stickyVisaTypes, setStickyVisaTypes] = useState([]);

    const [modifiedStatesList, setModifiedStatesList] = useState([]);

    const isDirty = useRef(false);
    const [state, setState] = useState({
        selectedJob: props.selectedJob.job,
        states: statesList.filter(obj => { return obj.country_code === (props.selectedJob?.job?.country || 'US') }),
        persistentContactInformation: props.selectedJob.job,
        persistentJobInformation: props.selectedJob
    });

    const [edit, setEdit] = useState(false);

    useEffect(() => {

        getClientsByRecruiter(uInfo.organizations_ID, uInfo.users_ID).then(data => {
            if (data) {
                setClientList(data);
            }
        });

        getSubSpecialtiesByParent(props.selectedJob.job.specialty);

        var states = statesList.filter(obj => {
            return obj.country_code === props.selectedJob.job.country
        });

        modifyStates(states);

    }, []);

    useEffect(() => {
        if (edit) {
            let ff = { ...props.selectedJob.job };
            ff.acceptVisa = ff.acceptVisa ? 'yes' : 'no';
            ff.acceptNewGrads = ff.acceptNewGrads ? 'yes' : 'no';
            ff.allowRemote = ff.allowRemote ? 'yes' : 'no';
            setValue('formFields', ff);

            if(ff.visaTypes){
                let visaTypes = ff.visaTypes.split('|');
                let selectedVisaTypesToSet = [];
                visaTypes.forEach(vt => vt !== '' && selectedVisaTypesToSet.push(parseInt(vt)));

                setSelectedVisaTypes(selectedVisaTypesToSet);
            }
        }
    }, [edit]);

    useEffect(() => {
        setStickyJobTypes([...activeJobTypes]);
        setModifiedJobTypes(activeJobTypes.map(jt => ({ label: jt.jobType, value: jt.jobTypes_ID })));
    }, [activeJobTypes]);


    useEffect(() => {
        setStickyVisaTypes([...activeVisaTypes]);
    }, [activeVisaTypes]);

    function modifyStates(states = null) {
        let newList = states ? states : statesList.filter(obj => { return obj.country_code === 'US' });

        newList = newList.map(item => ({
            label: item.name,
            value: item.state_code
        }));

        setModifiedStatesList(newList);
    }

    useEffect(() => {
        setState({
            ...state,
            selectedJob: props.selectedJob.job,
            persistentContactInformation: props.selectedJob.job,
            persistentJobInformation: props.selectedJob
        });

        let ff = { ...props.selectedJob.job };

        ff.acceptVisa = ff.acceptVisa ? 'yes' : 'no';
        ff.acceptNewGrads = ff.acceptNewGrads ? 'yes' : 'no';
        ff.allowRemote = ff.allowRemote ? 'yes' : 'no';

        if (ff.acceptVisa === 'yes') {
            setShowVisaTypes(true);
        } else {
            setShowVisaTypes(false);
        }

        setValue('formFields', ff);
    }, [props.selectedJob]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
                Globals.isDirtyPage = true;
            }

            if (name === 'formFields.country') {
                var states = statesList.filter(obj => {
                    return obj.country_code === value.formFields.country
                });

                setState({
                    ...state,
                    states
                });

                setValue('formFields.state', '');
            }

            if (name === 'formFields.specialty' && type === 'change') {
                setValue('formFields.subSpecialty', null);
                getSubSpecialtiesByParent(getValues().formFields.specialty);
            }

            if (name === 'formFields.acceptVisa') {
                if (value.formFields.acceptVisa === 'yes' || value.formFields.acceptVisa === true) {
                    setShowVisaTypes(true);
                } else {
                    setShowVisaTypes(false);
                }
            }

            if (name === 'formFields.jobType') {
                let selectedJT = modifiedJobTypes.find(jt => jt.value === parseInt(value.formFields.jobType));
                var toShowDuration = false;
                if (selectedJT) {
                    if (selectedJT.label === 'Contract' || selectedJT.label === 'Temporary') {
                        toShowDuration = true;
                    }
                }
                setShowDuration(toShowDuration);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, modifiedJobTypes]);

    function getSubSpecialtiesByParent(id) {

        let subSpecialties = activeSubSpecialties[id];

        setSubSpecialtiesByParent(subSpecialties);
    }

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setValue('formFields', state.persistentContactInformation);

        var states = statesList.filter(obj => {
            return obj.country_code === state.persistentContactInformation.country
        });

        setState({
            ...state,
            states,
            formFields: { ...state.persistentContactInformation },
        });

        setEdit(false);
        isDirty.current = false;
    }

    function handleSubmit() {
        let job = { ...getValues().formFields };

        job.clients_ID = job.clients_ID ? parseInt(job.clients_ID) : 0;
        job.specialty = job.specialty ? parseInt(job.specialty) : 0;
        job.subSpecialty = job.subSpecialty ? parseInt(job.subSpecialty) : 0;
        job.jobType = job.jobType ? parseInt(job.jobType) : 0;
        job.acceptVisa = job.acceptVisa === 'yes' ? true : false;
        job.acceptNewGrads = job.acceptNewGrads === 'yes' ? true : false;
        job.allowRemote = job.allowRemote === 'yes' ? true : false;

        job.visaTypes = '';
        for (var j = 0; j < selectedVisaTypes.length; j++) {
            job.visaTypes += selectedVisaTypes[j] + '|';
        }

        if (!job.jobTitle || (!state.selectedJob.externalJob && !job.clients_ID) || !job.specialty || !job.subSpecialty) {
            toast.warning("Please make sure all required fields are filled in");
        } else {
            let postdata = {
                job,
                auditChangesList: [getChangesToTrack(state.persistentContactInformation, { ...getValues().formFields }, "Jobs", "Job Details")]
            };

            updateJob(postdata).then(data => {
                if (data?.code === 'ERR_BAD_REQUEST') {
                    return toast.error(data.response.data);
                } else if (data) {
                    toast.success("Job information has been updated");
                    setState({
                        ...state,
                        persistentContactInformation: postdata.job
                    });
                    isDirty.current = false;
                    setEdit(false);

                    props.updateJobInformation(data);
                } else {
                    toast.error("Something went wrong");
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function updateSelectedJobTypes(value) {
        setSelectedJobTypes(value);
        isDirty.current = true;

        var toShowDuration = false;

        value.forEach(item => {
            let jt = modifiedJobTypes.find(j => j.value === item);
            if (jt?.label === 'Contract' || jt?.label === 'Temporary') {
                toShowDuration = true;
            }
        });

        setShowDuration(toShowDuration);
    }


    function updateSelectedVisaTypes(value) {
        setSelectedVisaTypes(value);
        isDirty.current = true;
    }


    const specialtyText = activeParentSpecialties?.find(element => element.specialties_ID === parseInt(state.persistentContactInformation.specialty));
    const subSpecialtyText = activeSubSpecialties[parseInt(state.persistentContactInformation.specialty)]?.find(element => element.specialties_ID === parseInt(state.persistentContactInformation.subSpecialty));


    const customRegister = (name, options) => {
        const { ref, ...field } = register(name, options);

        const customRef = (...args) => {
            setTimeout(() => ref(...args));
        }

        return { ...field, ref: customRef };
    }

    return (
        <>
            {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square job-text icon-md hover" onClick={openEdit}></i>}

            <Descriptions
                title="General Information"
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size='small'
                labelStyle={{ width: 300 }}
            >
                <Descriptions.Item label="Recruiter">{props.selectedJob?.user?.firstName} {props.selectedJob?.user?.lastName}</Descriptions.Item>
                {!state.selectedJob?.externalJob && <Descriptions.Item label="Client">{state.persistentJobInformation?.client?.name}</Descriptions.Item>}
                {Globals.userInfo.userType === 'OrgAdmin' &&
                    <Descriptions.Item label="Confidential Job">{state.persistentContactInformation.confidential ? 'Yes' : 'No'}</Descriptions.Item>
                }
                <Descriptions.Item label="Specialty">{specialtyText && specialtyText.specialty}</Descriptions.Item>

                <Descriptions.Item label="Sub-Specialty">
                    {subSpecialtyText && subSpecialtyText.specialty}
                </Descriptions.Item>
                <Descriptions.Item label="Job Type">{state.persistentContactInformation?.jobType > 0 && activeJobTypes.find((type, idx) => type.jobTypes_ID === parseInt(state.persistentContactInformation.jobType)).jobType}</Descriptions.Item>
                <Descriptions.Item label="Geographic Location">{state.persistentContactInformation?.geographicLocation}</Descriptions.Item>
                <Descriptions.Item label="Country">{state.persistentContactInformation?.country}</Descriptions.Item>
                <Descriptions.Item label="Address">
                    {state.persistentContactInformation.city && <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.city}, {state.persistentContactInformation.state} {state.persistentContactInformation.zip}</p>}
                    {(!state.persistentContactInformation.city && state.persistentContactInformation.state) && <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.state} {state.persistentContactInformation.zip}</p>}
                </Descriptions.Item>
                <Descriptions.Item label="Accept New Grads">{state.persistentContactInformation.acceptNewGrads ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Allow Remote">{state.persistentContactInformation.allowRemote ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Accept Visa">{state.persistentContactInformation.acceptVisa ? 'Yes' : 'No'}</Descriptions.Item>
                {state.persistentContactInformation.acceptVisa && <Descriptions.Item label="Visa Type(s)">{activeVisaTypes.map((type, idx) => state.persistentContactInformation.visaTypes?.includes(type.visaTypes_ID) && <p key={idx} className="no-bottom-margin line-height-1-5">{type.visaType}</p>)}</Descriptions.Item>}
                <Descriptions.Item label="Priority">{state.persistentContactInformation?.priority}</Descriptions.Item>
                <Descriptions.Item label="Community Attractions">{state.persistentContactInformation?.communityAttractions}</Descriptions.Item>
            </Descriptions>

            <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title='Edit Contact Information'
                placement={'right'}
                onClose={handleClose}
                open={edit}
                key='slider'
                size='large'
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                        </div>
                    </div>
                }
            >
                <div className="row">
                    <div className="form-group col-12">
                        <label>Job Title <span className="text-danger">*</span></label>
                        <input maxLength="100" className="ant-input" {...register('formFields.jobTitle')} />
                    </div>

                    {!state.selectedJob.externalJob &&
                        <>
                            <div className="form-group col-lg-12">
                                <label>Associated Client <span className="text-danger">*</span></label>

                                {clientList?.length > 0 ?
                                    <Controller
                                        control={control}
                                        name="formFields.clients_ID"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="clients_ID"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value}
                                                onChange={onChange}
                                                showSearch
                                                options={clientList}
                                                filterOption={(input, option) => (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='name'
                                                fieldNames={{ label: 'name', value: 'clients_ID' }}
                                            />
                                        }
                                    />
                                    :
                                    <p>Issue loading clients</p>
                                }

                                {/* <select className="ant-input" {...register('formFields.clients_ID')}>
                                    <option value={null} />
                                    {clientList?.map((client, idx) => <option key={idx} value={client.clients_ID}>{client.name}</option>)}
                                </select> */}
                            </div>
                        </>
                    }
                    {Globals.userInfo.userType === 'OrgAdmin' &&
                        <div className="form-group col-lg-6 col-12">
                            <label className="d-block">Keep this job confidential?</label>
                            <input type="checkbox" className="custom-checkbox" {...register('formFields.confidential')} />
                        </div>
                    }
                    <div className="horizontal-jobs-divider" />

                    <div className="form-group col-lg-6 col-12">
                        <label>Specialty <span className="text-danger">*</span></label>
                        {activeParentSpecialties?.length > 0 ?
                            <Controller
                                control={control}
                                name="formFields.specialty"
                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                    <Select
                                        id="specialty"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select"
                                        value={value}
                                        onChange={onChange}
                                        showSearch
                                        options={activeParentSpecialties}
                                        filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        optionFilterProp='specialty'
                                        fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                    />
                                }
                            />
                            :
                            <p>Issue loading specialties</p>
                        }
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Sub Specialty <span className="text-danger">*</span></label>
                        {getValues().formFields?.specialty ?
                            <Controller
                                control={control}
                                name="formFields.subSpecialty"
                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                    <Select
                                        id="subSpecialty"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select"
                                        value={value}
                                        onChange={onChange}
                                        showSearch
                                        options={subSpecialtiesByParent}
                                        filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                        optionFilterProp='specialty'
                                        fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                    />
                                }
                            />
                            :
                            <p><em>Please select a specialty first</em></p>
                        }
                    </div>
                    <div className="horizontal-jobs-divider" />

                    <div className="form-group col-lg-12">
                        <label>Country</label>
                        <select className="ant-input" {...register('formFields.country')} >
                            {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group col-lg-6 col-12">
                        <label>City</label>
                        <input maxLength="100" type="text" {...register('formFields.city')} className="ant-input" />
                    </div>
                    <div className="form-group col-lg-3 col-12">
                        <label>State</label>
                        <select className="ant-input" {...register('formFields.state')}>
                            <option></option>
                            {modifiedStatesList.map((state, idx) => <option key={idx} value={state.value}>{state.label}</option>)}
                        </select>
                    </div>
                    <div className="form-group col-lg-3 col-12">
                        <label>Zip</label>
                        <input maxLength="20" type="text" {...register('formFields.zip')} className="ant-input" />
                    </div>

                    <div className="form-group col-12">
                        <label>Geographic Location</label>
                        <input type="text" {...register('formFields.geographicLocation')} className="ant-input" />
                        <span className="help-block">
                            Please indicate what region of the state the city is in: Northern, Southern, Eastern, Western, Central, or Statewide or <mark>tell us in miles how far away the location is from another location.</mark> i.e. 30 miles from Atlanta.
                        </span>
                    </div>

                    <div className="form-group col-lg-3 col-12">
                        <label>Job Type</label>
                        <select className="ant-input" {...register('formFields.jobType')}>
                            <option></option>
                            {modifiedJobTypes.map((jt, idx) => <option key={idx} value={jt.value}>{jt.label}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-4 col-12">
                        {showDuration &&
                            <>
                                <label>Duration</label>
                                <input maxLength="255" {...register('formFields.duration')} className="ant-input" />
                            </>
                        }
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label className="d-block">Will Accept New Grads?</label>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...customRegister('formFields.acceptNewGrads')} value='yes' />
                            <label> Yes</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...customRegister('formFields.acceptNewGrads')} value='no' />
                            <label> No</label>
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label className="d-block">Allow Remote</label>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...customRegister('formFields.allowRemote')} value='yes' />
                            <label> Yes</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...customRegister('formFields.allowRemote')} value='no' />
                            <label> No</label>
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label className="d-block">Will Accept Visa?</label>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...customRegister('formFields.acceptVisa')} value='yes' />
                            <label> Yes</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...customRegister('formFields.acceptVisa')} value='no' />
                            <label> No</label>
                        </div>
                    </div>
                    {showVisaTypes ?
                        <div className="form-group col-lg-6 col-12">
                            <label>Visa Types</label>
                            <span className="help-block">
                                &nbsp;(Select all that apply)
                            </span>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Please select"
                                value={selectedVisaTypes}
                                onChange={updateSelectedVisaTypes}
                                options={activeVisaTypes.map(vt => ({ label: vt.visaType, value: vt.visaTypes_ID }))}
                                optionFilterProp='label'
                            />
                        </div>
                        :
                        <div className="form-group col-lg-6 col-0" />
                    }
                    <div className="form-group col-lg-3 col-12">
                        <label className="d-block">Priority</label>
                        <select className="ant-input" {...register('formFields.priority')}>
                            <option value='low'>Low</option>
                            <option value='normal'>Normal</option>
                            <option value='high'>High</option>
                        </select>
                    </div>

                    <div className="form-group col-12">
                        <label>Community Attractions</label>
                        <textarea rows="4" type="text" {...register('formFields.communityAttractions')} className="ant-input" />
                    </div>
                </div>
            </Drawer>
        </>
    );
}