import React, { useEffect, useRef } from "react";
import GoogleMapReact from 'google-map-react';
import { useState } from "react";
import SearchBox from "./SearchBox";

import Globals from '../../config/globals';

import { getCandidatesByRadius } from '../../services/CandidateService';
import $ from 'jquery';

export function MapCampaign(props) {

    const [state, setState] = useState({
        apiReady: false,
        map: null,
        googlemaps: null
    });

    const [placeSearched, setPlaceSearched] = useState(null);

    const [savedPlace, setSavedPlace] = useState(null);

    const defaultProps = {
        center: {
            lat: 41.850033,
            lng: -87.6500523
        },
        zoom: 4,
    };

    const [center, setCenter] = useState({
        lat: 37.6872,
        lng: -97.3301
    });

    const [isDraggable, setIsDraggable] = useState(true);

    var circle = useRef(null);

    useEffect(() => {

        $(document).ready();
        $(document.body).on('mousedown touchstart', function (event) {
            if (event.target?.classList?.contains('google-custom-search')) {
                setIsDraggable(false);
            } else {
                setIsDraggable(true);
            }
        });
    }, []);

    useEffect(() => {
        let map = state.map;

        map?.setOptions({ draggable: isDraggable, mapId: "custom-google-map" });
    }, [isDraggable]);

    useEffect(() => {
        if (state.map) {

            if (circle.current) {
                circle.current.setMap(null);
            }

            circle.current = new window.google.maps.Circle({
                strokeOpacity: 0.4,
                strokeWeight: 2,
                strokeColor: "#FF0000",
                fillColor: "#FF0000",
                fillOpacity: 0.2,
                map: state.map,
                center,
                radius: props.filterOptions.radius * 1609.34, //standard in meters. 1 mile = 1609.34 meters
                draggable: true,
                setEditable: true
            });

            var bounds = new window.google.maps.LatLngBounds();
         
            bounds.union(circle.current.getBounds());

            state.map.fitBounds(bounds);
        }
    }, [center, props.filterOptions]);

    useEffect(() => {
        handleSearch(placeSearched);
    }, [props.filterOptions.radius]);

    function myInput() {
        var controlUI = $('<input ref="input" class="ant-input" style="position: relative; z-index: 9999;" type="text" />');
        return controlUI;
    }

    function handleApiLoaded(map, maps) {
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(myInput);

        if (map && maps) {
            setState({
                apiReady: true,
                map: map,
                googlemaps: maps
            });
        }
    }

    function handleSearch(place) {

        setPlaceSearched(place);
       
        let zipObj, cityObj, stateObj, countyObj = {};

        if (place?.address_components) {
            zipObj = place.address_components.find(ac => ac.types.includes("postal_code"));
            cityObj = place.address_components.find(ac => ac.types.includes("locality"));

            stateObj = place.address_components.find(ac => ac.types.includes("administrative_area_level_1"));
            countyObj = place.address_components.find(ac => ac.types.includes("administrative_area_level_2"));
        }

        if (place?.geometry?.location) {
            var lng = place.geometry.location.lng();
            var lat = place.geometry.location.lat();
            var latlng = { lat, lng };
            setCenter(latlng);
        }

        let postdata = {
            city: cityObj?.short_name || "",
            state: stateObj?.short_name || "",
            county: countyObj?.short_name || "",
            zip: zipObj?.short_name || "",
            radius: props.filterOptions.radius,
            users_ID: Globals.userInfo.users_ID,
        }


        getCandidatesByRadius(postdata).then(data => {
            props.updateCandidates(data);
        });
    }

    return (
        <div style={{ height: '50vh', width: '100%' }} className="map-container">
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyB3yNp6n3wN5LzBzDkASp3oQrke9LB_f7A', libraries: ['places'] }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                defaultCenter={defaultProps.center}
                center={center}
                defaultZoom={defaultProps.zoom}
                className="mapContainer"
            >
                {state.apiReady && (<SearchBox
                    onPlacesChanged={(placeSearch) => { setSavedPlace(placeSearch); handleSearch(placeSearch); }} 
                    map={state.map}
                    googlemaps={state.googlemaps} />)}

            </GoogleMapReact>
        </div>
    );
}