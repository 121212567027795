import React, { useEffect, useState, useRef } from 'react';

import { Input, AutoComplete, Skeleton } from 'antd';

import { getCandidateKeywordSearch } from '../../services/CandidateService';
import { highlightContent } from '../../helpers/Highlight';

import { useNavigate } from "react-router-dom";

export function CustomSearch(props) {
    const navigate = useNavigate();

    const [state, setState] = useState({
        allCompanies: [],
        results: [],
        searchValue: ''
    });

    const [searchResults, setSearchResults] = useState({
        results: [],
        loaded: false
    });

    var timeout = useRef(null);

    function handleSearch_v2(value) {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        setState({
            ...state,
            searchValue: value
        });

        if (value) {
            timeout.current = setTimeout(() => performSearch(value), 500);
        } else {
            setSearchResults({
                results: [],
                loaded: true
            });
        }
    }

    function performSearch(value) {
        let results = [];

        getCandidateKeywordSearch(value).then(data => {
            var candidateObj = {
                label: <div className="candidate-text">Candidates</div>,
                options: data?.candidateResults?.map(item => renderCandidate(item, value))
            }

            results.push(candidateObj);

            setSearchResults({
                results,
                loaded: true
            });
        });
    }

    function renderCandidate(candidate, searchValue) {

        var highlight = highlightContent(candidate.candidates.firstName + " " + candidate.candidates.lastName, searchValue);

        return {
            value: candidate.candidates.candidates_ID.toString(),
            candidatesid: candidate.candidates.candidates_ID,
            candidateinfo: candidate,
            type: 'candidate',
            label: (
                <div>
                    {highlight ? highlight.map((item, idx) => <span key={'c' + idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>) : candidate.candidates.firstName + " " + candidate.candidates.lastName}
                    <div className="row">
                        {(!highlight || highlight.length === 1) && <>{candidate.candidates?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) && <div className="col"><sub>Specialty: {highlightContent(candidate.candidates.firstName, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub></div>}</>}
                        {(!highlight || highlight.length === 1) && <>{candidate.candidates?.lastName?.toLowerCase().includes(searchValue.toLowerCase()) && <div className="col"><sub>Specialty: {highlightContent(candidate.candidates.lastName, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub></div>}</>}
                        {(!highlight || highlight.length === 1) && <>{candidate.parentSpecialty?.toLowerCase().includes(searchValue.toLowerCase()) && <div className="col"><sub>Specialty: {highlightContent(candidate.parentSpecialty, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub></div>}</>}
                        {(!highlight || highlight.length === 1) && <>{candidate.subSpecialty?.toLowerCase().includes(searchValue.toLowerCase()) && <div className="col"><sub>Sub Specialty: {highlightContent(candidate.subSpecialty, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub></div>}</>}
                        {(!highlight || highlight.length === 1) && <>{candidate.candidates.city?.toLowerCase().includes(searchValue.toLowerCase()) && <div className="col"><sub>City: {highlightContent(candidate.candidates.city, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub></div>}</>}
                        {(!highlight || highlight.length === 1) && <>{candidate.candidates.state?.toLowerCase().includes(searchValue.toLowerCase()) && <div className="col"><sub>State: {highlightContent(candidate.candidates.state, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight-for-search' : ''}>{item.text}</span>)}</sub></div>}</>}
                    </div>
                </div>
            )
        }
    }

    //function renderCompany(company, searchValue) {
    //    var highlight = highlightContent(company.name, searchValue);

    //    return {
    //        value: company.name,
    //        companyid: company.companyId,
    //        type: 'company',
    //        label: (
    //            <div>
    //                {highlight ? highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>) : company.name}
    //                {(!highlight || highlight.length === 1) && <div>{company.mailingName?.includes(searchValue) && <sub>Mailing Name: {highlightContent(company.mailingName, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}</sub>}</div>}
    //            </div>
    //        )
    //    }
    //}

    //function renderInvoice(invoice, searchValue) {
    //    var highlight = highlightContent(invoice.invoiceNo.toString(), searchValue);

    //    return {
    //        value: invoice.invoiceNo.toString(),
    //        companyid: invoice.companyId,
    //        type: 'invoice',
    //        label: (
    //            <div>
    //                {highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}
    //            </div>
    //        )
    //    }
    //}

    //function renderAlternateName(item, searchValue) {
    //    var highlight = highlightContent(item.alternateName.toString(), searchValue);

    //    return {
    //        value: item.alternateCompanyNamesId.toString(),
    //        companyid: item.companyId,
    //        type: 'alternateName',
    //        label: (
    //            <div>
    //                {highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}
    //                <span className="float-right a hover"><u>Match</u></span>
    //            </div>
    //        )
    //    }
    //}

    //function renderCompanyContact(companyContact, searchValue) {
    //    var highlight = highlightContent(companyContact.name, searchValue);

    //    return {
    //        value: companyContact.name,
    //        companyid: parseInt(companyContact.companyId),
    //        type: 'companyContact',
    //        label: (
    //            <div>
    //                {highlight ? highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>) : companyContact.name}
    //                {(!highlight || highlight.length === 1) && <div>{companyContact.email?.includes(searchValue) && <sub>Email: {highlightContent(companyContact.email, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}</sub>}</div>}
    //            </div>
    //        )
    //    }
    //}

    //function renderCompanyPlan(companyPlan, searchValue) {
    //    var highlight = highlightContent(companyPlan.planName, searchValue);

    //    return {
    //        value: companyPlan.planName,
    //        companyid: companyPlan.companyId,
    //        type: 'companyPlan',
    //        label: (
    //            <div>
    //                {highlight ? highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>) : companyPlan.planName}
    //                {(!highlight || highlight.length === 1) && <div>{companyPlan.ein?.includes(searchValue) && <sub>EIN: {highlightContent(companyPlan.ein, searchValue).map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}</sub>}</div>}
    //            </div>
    //        )
    //    }
    //}

    function handleSelect(option, value) {
        if(props.closeSider) {

            props.closeSider();
        }
        navigate(`/candidate-dashboard/${value.candidateinfo.candidates.candidates_ID}`,
            {
                replace: true
            });
    }

    const renderTitle = (title) => (
        <span>
            {title}
        </span>
    );

    const renderItem = (item) => ({
        value: item.candidates_ID.toString(),
        label: (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {item.name}
            </div>
        ),
    });

    return (
        <>
            <AutoComplete
                popupClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                style={{
                    width: '100%',
                }}
                options={searchResults.results}
                onSearch={handleSearch_v2}
                notFoundContent="No Results"
                onSelect={handleSelect}
                value={state.searchValue}
                defaultActiveFirstOption={true}
                onBlur={() => setState({ ...state, searchValue: '' })}
                htmlFor="search"
                id="search"
            >
                <Input.Search size="large" placeholder="Search for candidates..." />
            </AutoComplete>
        </>
    );
}