import React, { useState, useEffect, useRef } from 'react';
import { Table, Drawer, Modal, Spin, Alert } from 'antd';
import { toast } from '@rickylandino/react-messages';
import Globals from '../../config/globals';
import Moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';
import { getWorkflowByOrganization } from '../../services/WorkflowItemsService';
import { activeUserJobsState, getMatchedJobs } from '../../services/JobsService';
import { getEntityWithPlacementByCandidateAndJob, insertPlacement, updatePlacement } from '../../services/PlacementsService';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import DatePicker from 'react-datepicker';
import { insertPlacementWorkflow, updatePlacementWorkflow } from '../../services/PlacementsWorkflowService';
import { activeUserCandidatesState } from '../../services/CandidateService';
import { LoadingOutlined } from '@ant-design/icons';
import { cityStateFormatter } from '../../helpers/Location';

export function PlacementWorkflowPane(props) {
    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch, formState } = useForm();

    const uInfo = useRecoilValue(activeUserInfo);

    const [show, setShow] = useState(false);
    const [showStepModal, setShowStepModal] = useState(false);

    const [workflowItem, setWorkflowItem] = useState([]);
    const [workflowItems, setWorkflowItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updatingPlacement, setUpdatingPlacement] = useState(false);

    const [differentOwner, setDifferentOwner] = useState(false);

    const setActiveUserCandidates = useSetRecoilState(activeUserCandidatesState);
    const setActiveUserJobs = useSetRecoilState(activeUserJobsState);

    const [selectedStep, setSelectedStep] = useState({});
    const [formFields, setFormFields] = useState({});

    var isDirty = useRef(false);

    useEffect(() => {
        setShow(props.show);
        if (props.show && (props.workflowItem?.placements?.users_ID)) {
            handleSetWorkflow(props.workflowItem);
            if ((props.workflowItem?.placements?.users_ID && props.workflowItem?.placements?.users_ID !== uInfo.users_ID)) {
                setDifferentOwner(true);
            } else {
                setDifferentOwner(false);
            }
        }
    }, [props.show]);

    function handleSetWorkflow(localWorkflowItem) {
        let pformFields = {
            placementStatus: localWorkflowItem?.placements?.placementStatus,
            placementNotes: localWorkflowItem?.placements?.notes
        }

        setValue('pFormFields', pformFields);

        let localWorkflowItems = localWorkflowItem?.workflowItems;
        setWorkflowItem(localWorkflowItem);

        setWorkflowItems([...localWorkflowItems]);

        if (localWorkflowItems?.length > 0) {
            var set = false;

            for (var i = 0; i < localWorkflowItems.length; i++) {
                let wfi = localWorkflowItems[i];
                if (wfi.placementsWorkflowModel !== null && wfi.placementsWorkflowModel.workflowStatus === 'In Progress') {
                    set = true;
                    setSelectedStep(wfi);
                    setFormFields(wfi.placementsWorkflowModel);
                    break;
                }
            }

            if (!set) {
                setSelectedStep(localWorkflowItems[0]);
                setFormFields(localWorkflowItems[0].placementsWorkflowModel);
            }
        }

        setLoaded(true);
    }

    function handleClose() {
        performClose();
    }

    function performClose() {
        props.hidePane();
        if (props.triggerRefresh) props.triggerRefresh();
        setShow(false);
        isDirty.current = false;
        Globals.isDirtyPage = false;
    }

    function handlePlacementStatusChange(event) {
        setUpdatingPlacement(true);
        let placementsModel = {
            placements_ID: workflowItem.placements.placements_ID,
            jobs_ID: workflowItem.placements.jobs_ID,
            candidates_ID: workflowItem.placements.candidates_ID,
            users_ID: uInfo.users_ID,
            placementStatus: getValues().pFormFields.placementStatus,
            notes: getValues().pFormFields.placementNotes
        }

        let postdata = {
            placementsModel,
            users_ID: uInfo.users_ID,
            placementStatus: props.placementStatus
        }

        updatePlacement(postdata).then(data => {
            setUpdatingPlacement(false);
            isDirty.current = false;
            Globals.isDirtyPage = false;

            toast.success("Placement has been updated.");

            setActiveUserCandidates(data.userCandidates);
            setActiveUserJobs(data.userJobs);
        });
    }

    function selectStep(row) {
        setSelectedStep(row);
        setValue('formFields', row.placementsWorkflowModel !== null ? row.placementsWorkflowModel : { workflowStatus: 'Not Started', date: null, notes: '' });
        setShowStepModal(true);
    }

    function handleUpdate() {
        setUpdating(true);
        let placementsWorkflowModel = {
            placements_ID: workflowItem.placements.placements_ID,
            workflowItems_ID: selectedStep.workflowItemsModel.workflowItems_ID,
            notes: getValues().formFields.notes,
            date: Moment(getValues().formFields.date).toDate(),
            workflowStatus: getValues().formFields.workflowStatus
        };

        let postdata = {
            placementsWorkflowModel,
            users_ID: uInfo.users_ID,
            placementStatus: props.placementStatus
        }

        if (selectedStep.placementsWorkflowModel !== null) {
            postdata.placementsWorkflowModel.placementsWorkflow_ID = selectedStep.placementsWorkflowModel.placementsWorkflow_ID;

            updatePlacementWorkflow(postdata).then(data => {
                setLoaded(false);
                setShowStepModal(false);
                setUpdating(false);

                let localWorkflowItem = {
                    ...workflowItem,
                    workflowItems: data.placementsWorkflow
                };

                setActiveUserCandidates(data.userCandidates);
                setActiveUserJobs(data.userJobs);

                handleSetWorkflow(localWorkflowItem);

                isDirty.current = false;
                Globals.isDirtyPage = false;

                toast.success("Workflow task has been updated.");
            });
        } else {
            insertPlacementWorkflow(postdata).then(data => {
                setLoaded(false);
                setShowStepModal(false);

                setUpdating(false);

                let localWorkflowItem = {
                    ...workflowItem,
                    workflowItems: data.placementsWorkflow
                };

                setActiveUserCandidates(data.userCandidates);
                setActiveUserJobs(data.userJobs);

                handleSetWorkflow(localWorkflowItem);

                isDirty.current = false;
                Globals.isDirtyPage = false;

                toast.success("Workflow task has been added.");
            });
        }
    }

    const columns = [{
        dataIndex: ['workflowItemsModel', 'workflowItem'],
        title: 'Workflow Task'
    }, {
        dataIndex: ['placementsWorkflowModel', 'workflowStatus'],
        title: 'Status',
    }, {
        dataIndex: ['placementsWorkflowModel', 'date'],
        title: 'Status Date',
        render: cell => cell && Moment(new Date(cell)).format('L'),
    }, {
        dataIndex: ['placementsWorkflowModel', 'notes'],
        title: 'Notes'
    }];

    const returnCandidateName = () => {
        let firstName = '';
        let lastName = '';

        if(props.candidate) {
            if(props.candidate.candidateFirstName) {
                firstName = props.candidate.candidateFirstName;
                lastName = props.candidate.candidateLastName;
            } else {
                firstName = props.candidate.firstName;
                lastName = props.candidate.lastName;
            }
        } else if(props.candidates) {
            if(props.candidates.candidateFirstName) {
                firstName = props.candidates.candidateFirstName;
                lastName = props.candidates.candidateLastName;
            } else {
                firstName = props.candidates.firstName;
                lastName = props.candidates.lastName;
            }
        }

        if(firstName || lastName) {
            return `${firstName} ${lastName}`;
        }

        return '';
    }

    const returnJobTitle = () => {
        if (props.job) {
            return props.job.jobTitle;
        } else if (props.jobs) {
            return props.jobs.jobTitle;
        } else {
            return '';
        }
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Placement Workflow'
            placement={'right'}
            onClose={handleClose}
            maskClosable={false}
            open={show}
            key='slider'
            width='100%'
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
            className={props.from === 'candidates' ? 'candidate' : 'job'}
        >
            <div className="row">
                {differentOwner &&
                    <Alert className="mb-3" message={`This placement is owned by ${workflowItem.placementOwner}`} type="info" showIcon />
                }

                <div className="col-lg-4 col-12 form-group">
                    <h4 className="padding-top-15 candidate-text">Candidate: {returnCandidateName()}</h4>
                    <p>Location: {cityStateFormatter(props.candidates || props.candidate)}</p>
                </div>

                <div className="col-lg-4 col-12 form-group">
                    <h4 className="padding-top-15 job-text">Opportunity: {returnJobTitle()}</h4>
                    <p>Location: {cityStateFormatter(props.jobs || props.job)}</p>
                </div>

                <div className="col-lg-4 col-12 form-group">
                    <h4 className="padding-top-15 client-text">Client: {props.clientName}</h4>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-lg-3 col-12">
                    <label>Placement Status</label>
                    <select className="ant-input" {...register('pFormFields.placementStatus')} disabled={differentOwner}>
                        <option>Active</option>
                        <option value="Inactive">On Hold/Inactive</option>
                        <option>Terminated</option>
                        <option>Completed</option>
                    </select>
                </div>
                <div className="form-group col-12">
                    <label>Placement Notes</label>
                    <input className="ant-input" {...register('pFormFields.placementNotes')} disabled={differentOwner} />
                </div>

                {!differentOwner &&
                    <div className="form-group col-12 px-3 py-2">
                        {updatingPlacement ?
                            <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                            :
                            <button className="ant-btn ant-btn-primary" onClick={handlePlacementStatusChange}>Update Placement</button>
                        }

                    </div>
                }
                <div className="horizontal-jobs-divider" />
            </div>
            <div className="row">
                {loaded &&
                    <>
                        <MediaQuery maxWidth={1223}>
                            <ul className="workflow-list">
                                {workflowItems?.length > 0 && workflowItems.map((item, idx) => (
                                    <li onClick={() => differentOwner ? <></> : selectStep(item)} key={idx}><span>
                                        {item.placementsWorkflowModel ? item.placementsWorkflowModel.workflowStatus === 'Completed' ? < i className="fa-solid fa-check text-success fa-1x"></i> : <i className="fa-solid fa-repeat fa-1x"></i> : <i className="fa-solid fa-smile fa-1x text-transparent"></i>}

                                    </span>{item.workflowItemsModel.workflowItem} {!differentOwner && <div className="a hover ms-5">Edit</div>}
                                    </li>
                                ))}

                            </ul>
                        </MediaQuery>

                        <MediaQuery minWidth={1224}>
                            {workflowItems?.length > 0 &&
                                <Table
                                    rowKey={(record) => record.workflowItemsModel.workflowItems_ID}
                                    dataSource={workflowItems}
                                    columns={columns}
                                    size='small'
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { differentOwner ? <></> : selectStep(record) }
                                        };
                                    }}
                                />
                            }
                        </MediaQuery>
                    </>
                }
            </div>

            <Modal
                open={showStepModal}
                title="Process Selected Item"
                footer={[
                    <div className="text-start" key="1">
                        {updating ?
                            <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                            :
                            <button className="ant-btn ant-btn-primary text-start" onClick={handleUpdate}>Update</button>
                        }
                        <button className="ant-btn ant-btn-default text-start" onClick={() => setShowStepModal(false)}>Cancel</button>
                    </div>
                ]}
            >
                {selectedStep?.workflowItemsModel &&
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Task Description: </label>
                            <h5>{selectedStep.workflowItemsModel.workflowItem}</h5>
                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col-12">
                        <label>Task Status</label>
                        <select className="ant-input" {...register('formFields.workflowStatus')}>
                            <option>Not Started</option>
                            <option>In Progress</option>
                            <option>Completed</option>
                        </select>
                    </div>
                    <div className="col-8" />
                    <div className="form-group col-lg-7">
                        <label>Status Date</label>
                        <Controller
                            control={control}
                            name="formFields.date"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <DatePicker
                                    selected={value && Moment(value).toDate()}
                                    onChange={onChange}
                                    className="ant-input"
                                />
                            }
                        />
                    </div>
                    <div className="col-7">
                        <label>Notes</label>
                        <textarea className="ant-input" {...register('formFields.notes')} />
                    </div>
                </div>

            </Modal>
        </Drawer>
    );
}