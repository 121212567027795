import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Table, Tabs, Input, Empty, Divider, Alert } from 'antd';
import { filterTable, filterTableFromColumns } from '../../helpers/FilterTable';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { orgInfoLoading } from '../../services/OrganizationsService';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { activeUserClientsState, clientFilterFields, getClientsByOrganization } from '../../services/ClientsService';
import { AddClient } from './AddClient';
import Marquee from 'react-fast-marquee';

export function ClientsHome(props) {

    const navigate = useNavigate();

    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const [loading, setLoading] = useState(false);

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [clientsToFilterAgainst, setClientsToFilterAgainst] = useState([]);

    const [activeTab, setActiveTab] = useState('myClients');

    const [clientLists, setClientLists] = useState({
        allUserClients: [],
        filterUserClients: [],
        allClients: [],
        loaded: false
    });

    const [showAddClient, setShowAddClient] = useState(false);

    useEffect(() => {
        var alive = true;

        combineLatest(
            orgInfoLoading
        ).pipe(takeWhile(() => alive)).subscribe(([orgInfoLoading]) => {
            setLoading(orgInfoLoading);
        });

        return () => { alive = false; }
    }, []);

    // useEffect(() => {
    //     if (candidatesToFilterAgainst?.length > 0) {
    //         performFilter(candidatesToFilterAgainst);
    //     }
    // }, [filterFields, candidatesToFilterAgainst]);

    useEffect(() => {
        setLoading(true);

        getClientsByOrganization(uInfo.organizations_ID, uInfo.users_ID).then(data => {
            let clientsToSet = data.userClients ? [...data.userClients] : [];

            setClientLists({
                allUserClients: data.userClients ? [...data.userClients] : [],
                filterUserClients: clientsToSet,
                allClients: data.companyClients ? [...data.companyClients] : [],
                loaded: true
            });

            setClientsToFilterAgainst(clientsToSet);

            setLoading(false);
        });

    }, []);

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);

        let clientsToSet = clientLists.allUserClients ? [...clientLists.allUserClients] : [];

        if (activeKey === 'companyClients') {
            clientsToSet = [...clientLists.allClients];
        }

        setClientLists({
            ...clientLists,
            filterUserClients: clientsToSet
        });
    }

    function navigateToClientDashboard(row) {
        navigate(`/client-dashboard/${row.clients.clients_ID}`,
            {
                replace: true
            });
    }

    var clientColumns = [
        {
            title: 'Client Name',
            dataIndex: ['clients', 'name'],
            key: 'name'
        },
        {
            title: 'Email Address',
            dataIndex: ['clients', 'emailAddress'],
            key: 'emailAddress'
        },
        {
            title: 'Recruiter',
            dataIndex: ['users', 'firstName'],
            key: 'firstName',
            render: (cell, row) => row.users.firstName + ' ' + row.users.lastName,
        },
    ];

    function handleSearch(value) {


        let newFilterUserClients = [...clientLists.allUserClients];

        if (activeTab === 'companyClients') {
            newFilterUserClients = [...clientLists.allClients];
        }

        if (value) {
            const filterUserClients = filterTableFromColumns(newFilterUserClients,
                value,
                clientColumns
            );

            setClientLists({
                ...clientLists,
                filterUserClients
            });
        } else {
            setClientLists({
                ...clientLists,
                filterUserClients: newFilterUserClients,
            });
        }
    }

    const columns = clientColumns.filter(c => !c.hide);

    var tabs = [
        {
            label: <div className="client-text">My Clients</div>,
            key: 'myClients',
            children: (
                <>
                    {loading ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>

                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider>
                            {clientLists.filterUserClients?.length > 0 ?
                                <>

                                    <Table
                                        rowKey={(record) => record.clients.clients_ID}
                                        dataSource={clientLists.filterUserClients}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { event.target.id === 'myCheckbox' ? console.log("checkbox clicked") : navigateToClientDashboard(record) }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no clients - to begin adding a client, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Client'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        }, {
            label: <div className="client-text">Organizational Clients</div>,
            key: 'companyClients',
            children: (
                <>
                    {loading ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider>
                            {clientLists.filterUserClients?.length > 0 ?
                                <>

                                    <Table
                                        rowKey={(record) => record.clients.clients_ID}
                                        dataSource={clientLists.filterUserClients}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { event.target.id === 'myCheckbox' ? console.log("checkbox clicked") : navigateToClientDashboard(record) }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no clients - to begin adding a client, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Client'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        }
    ];

    if (uPermissions.users_ID && uPermissions.readClients !== 'company') {
        let companyClientsIndex = tabs.findIndex(item => item.key === 'companyClients');
        tabs.splice(companyClientsIndex, 1);
    }

    return (
        <>
            {(uPermissions.users_ID && uPermissions.readClients === 'none') ?
                <Empty description={<p>You do you have the appropriate access to view clients. Please contact your system administrator if you believe this is a mistake.</p>} />
                :
                <>
                    <div className="d-flex justify-content-start">
                        <Alert className="float-start mb-3" message="Full client functionality is still in progress. More robust features are on the way." type="info" showIcon closable />
                    </div>
                    {uPermissions?.users_ID && uPermissions?.createClients &&
                        <div className="d-flex justify-content-end">

                            <button className="ant-btn ant-btn-primary float-end" onClick={() => setShowAddClient(true)}>
                                <i className="far fa-plus-square"></i>
                                &nbsp;
                                Add Client
                            </button>
                        </div>
                    }
                    <div className="card-container">
                        <Tabs activeKey={activeTab} type="card" items={tabs} onChange={handleTabChange} />
                    </div>

                    <AddClient show={showAddClient} from='clients-dashboard' hidePane={() => setShowAddClient(false)} />
                </>
            }
        </>
    );
}