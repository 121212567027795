import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export default class SearchBox extends React.Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        placeholder: PropTypes.string,
        onPlacesChanged: PropTypes.func
    }

    render() {
        return <input ref="input" className="ant-input google-custom-search" style={{ position: 'absolute', top: 0 }} placeholder="Search For a US Based Location" type="text" />;
    }
    onPlacesChanged = () => {

        const place = this.searchBox.getPlace();

        if (place.address_components || place?.geometry?.location) {
            if (this.props.onPlacesChanged) {
                this.props.onPlacesChanged(this.searchBox.getPlace());
            }
        }
        //else {
        //    this.service.getQueryPredictions({ input: place.name }, this.displaySuggestions);
        //}
    }
    componentDidMount() {
        var input = ReactDOM.findDOMNode(this.refs.input);

        this.props.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        var options = {
            componentRestrictions: { country: 'us' }
        };

        this.searchBox = new window.google.maps.places.Autocomplete(input, options);

        this.searchBox.addListener('place_changed', this.onPlacesChanged);
        this.service = new window.google.maps.places.AutocompleteService();
        this.s2 = new window.google.maps.places.PlacesService(this.props.map);
    }
    componentWillUnmount() {
        if (this.searchBox?.removeListener) {
            this.searchBox.removeListener('place_changed', this.onPlacesChanged);
        }

    }

    displaySuggestions = (predictions, status) => {
        if (predictions?.length > 0) {
            if (predictions[0].place_id) {
                var request = {
                    placeId: predictions[0].place_id
                };

                this.s2.getDetails(request, this.callback);
            }
        }
    };

    callback = (place, status) => {
        if (place) {
            if (this.props.onPlacesChanged) {
                this.props.onPlacesChanged(place);
            }
        }
    }

}