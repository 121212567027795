import React, { useEffect, useState } from 'react';
import { Layout, Avatar } from 'antd';
import {
    UserOutlined,
    NotificationOutlined,
    MobileOutlined
} from '@ant-design/icons';

import { useThemeSwitcher } from "react-css-theme-switcher";

import recruiterbalmLogo from '../../img/RecruiterBalmLogo.jpg';
import nchcrLogo from '../../img/nchcr_brand_banner.png';

import './navigation.css';
import Globals from '../../config/globals';
import { confirm } from '@rickylandino/react-messages';
import { useNavigate } from "react-router-dom";
import { CustomSearch } from './CustomSearch';
import { getColorShade } from '../../helpers/ColorShades';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { userInfo } from '../../services/UsersService';

import $ from 'jquery';
import { logoutAudit } from '../../services/AuditLoginService';

const AntSider  = Layout.Sider;

export function Sider(props) {
    const navigate = useNavigate();
    const { currentTheme } = useThemeSwitcher();

    const [collapsed, setCollapsed] = useState(true);
    const [uInfo, setUInfo] = useState({})

    const [cName, setCName] = useState('slide-in')

    useEffect(() => {
        if (!props.collapsed) {
            setCName('slide-in');
        } else {
            setCName('slide-out');
        }

        var timeout = 0;
        if (props.collapsed) {
            timeout = 600;
        }
        setTimeout(() => setCollapsed(props.collapsed), timeout);

    }, [props.collapsed]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo]) => {
            if (userInfo.users_ID) {
                setUInfo(userInfo);
            }
        });

        return () => alive = false;
    }, []);


    function handleNavClick(e) {
        props.closeSider();
        switch (e.target.id) {
            case 'candidates':
                navigate("/candidates", { replace: true });
                break;
            case 'clients':
                navigate("/clients", { replace: true });
                break;
            case 'jobs':
                navigate("/jobs", { replace: true });
                break;
            case 'sms':
                navigate("/sms-dashboard", { replace: true });
                break;
            case 'campaigns':
                navigate("/campaigns", { replace: true });
                break;
            case 'settings':
                navigate("/account-settings", { replace: true });
                break;
            default:
                break;
        }
    }

    function handleLogout() {
        confirm({
            title: "You are about to logout",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                logoutAudit();

                navigate("/", { replace: true });
                return 0;
            } else {
                return 0;
            }
        });
    }

    return (
        <>

            <AntSider id="slider" className={cName} defaultCollapsed collapsed={collapsed} onCollapse={(isCollapsed, type) => setCollapsed(isCollapsed)} theme={currentTheme}>

                <div>
                    <CustomSearch closeSider={() => props.closeSider()}/>
                </div>

                <div className="candidate-text candidate-hover nav-item" id="candidates" onClick={handleNavClick}>
                    <i className="fa-regular fa-user" id="candidates" onClick={handleNavClick}></i>
                    &nbsp;
                    Candidates
                </div>

                <div className="client-text client-hover nav-item" id="clients" onClick={handleNavClick}>
                    <i className="fa-regular fa-building text-clients" id="clients" onClick={handleNavClick}></i>
                    &nbsp;
                    Clients
                </div>

                <div className="job-text job-hover nav-item" id="jobs" onClick={handleNavClick}>
                    <i className="fa-regular fa-id-badge text-jobs" id="jobs" onClick={handleNavClick}></i>
                    &nbsp;
                    Jobs
                </div>

                <div className="campaign-text campaign-hover nav-item d-flex align-items-center" id="campaigns" onClick={handleNavClick}>
                    <NotificationOutlined id="campaigns" onClick={handleNavClick} />
                    &nbsp;
                    Campaigns
                </div>

                <div className="campaign-text campaign-hover nav-item d-flex align-items-center" id="sms" onClick={handleNavClick}>
                    <MobileOutlined className="fa-regular fa-user" id="sms" onClick={handleNavClick} />
                    &nbsp;
                    Text
                </div>

                <div className="campaign-text campaign-hover nav-item d-flex align-items-center" id="settings" onClick={handleNavClick}>
                    <Avatar style={{ color: uInfo.color, backgroundColor: getColorShade(uInfo.color) }} src={uInfo.profilePicture ? `data:image/jpeg;base64,${uInfo.profilePicture}` : null} icon={<UserOutlined />}></Avatar>
                    &nbsp;
                    Profile
                </div>

                <div className='text-danger d-flex align-items-center' onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt d-flex" style={{ fontSize: '1.5rem' }} onClick={handleLogout}></i>
                </div>

            </AntSider>
        </>    
    );
}