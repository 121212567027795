import React, { useEffect, useState, useRef } from 'react';
import { Table, Empty, Tag, Modal } from 'antd';
import { convertHtmlToText } from '../../helpers/ManipulateHTML';
import JoditEditor from 'jodit-react';
import Moment from 'moment';
import { getEmailHistoryItem } from '../../services/EmailService';
import { toast } from '@rickylandino/react-messages';

export function EmailHistory(props) {
    const editor = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    function openHistoryModal(record) {
        toast.loading("Fetching email...", { key: 'loading' });

        getEmailHistoryItem(record.emailHistory.emailHistory_ID).then(data => { 
            setSelectedItem(data);
            setIsOpen(true);
            toast.destroy('loading');
        });
    }

    const columns = [
        {
            title: 'Subject',
            dataIndex: ['emailHistory' ,'subject'],
            key: 'subject',
        },
        {
            title: 'Email Preview',
            dataIndex: 'truncatedContentString',
            key: 'content',
            render: cell => convertHtmlToText(cell),
            ellipsis: true
        },
        {
            title: 'Sent To',
            dataIndex: ['emailHistory', 'sentTo'],
            key: 'sentTo',
            ellipsis: true,
            render: (cell) => {
                const emails = cell?.split(';');

                return (
                    <>
                        {emails?.map((e) => {
                            if (e) {
                                return (
                                    <Tag key={e}>
                                        {e}
                                    </Tag>
                                );
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'Date Sent',
            dataIndex: ['emailHistory', 'dateSent'],
            key: 'dateSent',
            render: (cell) => Moment(cell).format("L LT")
        }
    ];

    return (
        <>
            {props.emailHistory?.length > 0 ?
                <div>
                    <Table
                        rowKey={(record) => record.emailHistory.emailHistory_ID}
                        dataSource={props.emailHistory}
                        columns={columns}
                        size='small'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => openHistoryModal(record), // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }} />
                </div>
                :
                <Empty description="No Email History" />
            }

            <Modal
                title="Email History"
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-default m-1" onClick={() => setIsOpen(false)}>Close</button>
                        </div>
                    </div>
                }
                width={1000}
            >
                {selectedItem &&
                    <div className="row">

                        <div className="form-group col-12">
                            <label>Sent To</label>
                            <div>
                                {selectedItem.emailHistory.sentTo?.split(';').map((e) => {
                                    if (e) {
                                        return (
                                            <Tag key={e}>
                                                {e}
                                            </Tag>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        <div className="form-group col-12">
                            <JoditEditor
                                ref={editor}
                                value={selectedItem.emailHistory.contentString}
                                tabIndex={1} // tabIndex of textarea
                                config={{
                                    height: "500",
                                    allowResizeY: true,
                                    readonly: true,
                                    toolbar: false
                                }}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}