import React, { useState, useEffect, useRef } from 'react';
import { Table, Drawer, Select, Alert, Input, Empty, Modal, Spin } from 'antd';
import Globals from '../../config/globals';
import Moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';
import { getWorkflowByOrganization } from '../../services/WorkflowItemsService';
import { getMatchedJobs } from '../../services/JobsService';
import { getEntityWithPlacementByCandidateAndJob, insertPlacement } from '../../services/PlacementsService';
import { PlacementWorkflowPane } from './PlacementWorkflowPane';
import { activeParentSpecialtiesState, activeSubSpecialtiesState, activeWorkflowItemsState } from '../../services/OrganizationsService';
import { activeUserCandidatesState, getCandidatesWithPlacementsByUser, getMatchedCandidates } from '../../services/CandidateService';
import SkeletonTable from '../../helpers/SkeletonTable';
import { LoadingOutlined } from '@ant-design/icons';
import { cityStateFormatter } from '../../helpers/Location';

export function MatchPane(props) {
    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch, formState } = useForm({
        defaultValues: {
            externalJob: {
                jobTitle: '',
                addToMyJobs: true
            }
        }
    });

    const uInfo = useRecoilValue(activeUserInfo);

    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const [workflowItems, setWorkflowItems] = useState([]);
    const [activeWorkflowItems, setActiveWorkflowItems] = useRecoilState(activeWorkflowItemsState);

    const [matchedJobs, setMatchedJobs] = useState([]);
    const [matchedCandidates, setMatchedCandidates] = useState([]);

    const [showExternalJobModal, setShowExternalJobModal] = useState(false);
    const [addingExternalJob, setAddingExternalJob] = useState(false);

    const setActiveUserCandidates = useSetRecoilState(activeUserCandidatesState);

    const activeParentSpecialties = useRecoilValue(activeParentSpecialtiesState);
    const activeSubSpecialties = useRecoilValue(activeSubSpecialtiesState);

    const [workflowStuff, setWorkflowStuff] = useState({
        showWorkflowPane: false,
        selectedJob: null,
        selectedClient: null,
        selectedCandidate: null,
        workflowItem: null
    });

    var isDirty = useRef(false);

    useEffect(() => {
        setShow(props.show);
        if (props.show && uInfo.organizations_ID) {
            setLoading(true);

            if (props.from === 'candidates') {
                getMatchedJobs(props.entity.candidates_ID, uInfo.users_ID).then(data => {
                    setMatchedJobs(data.openJobs);
                    setLoading(false);
                });
            } else if (props.from === 'jobs') {
                getMatchedCandidates(props.entity.jobs_ID, uInfo.users_ID).then(data => {
                    setMatchedCandidates(data.openCandidates);
                    setLoading(false);
                });
            }
        }
    }, [props.show, uInfo]);

    function handleClose() {
        performClose();
    }

    function performClose() {
        setShow(false);
        props.hidePane();
        if(props.triggerRefresh) props.triggerRefresh();
        isDirty.current = false;
        Globals.isDirtyPage = false;
    }

    function hidePlacementPane() {
        setWorkflowStuff({
            showWorkflowPane: false,
            selectedJob: null,
            selectedClient: null,
            selectedCandidate: null,
            workflowItem: null
        });
    }

    function beginWorkflowFromCandidates(job, idx) {
        if (job.placements?.placements_ID) {
            getEntityWithPlacementByCandidateAndJob(job.jobs.jobs_ID, props.entity.candidates_ID, job.jobs.users_ID).then(data => {
                setWorkflowStuff({
                    showWorkflowPane: true,
                    selectedJob: job.jobs,
                    selectedClient: job.clients,
                    selectedCandidate: data.candidates,
                    workflowItem: data
                });
            });
        } else {
            let postdata = {
                placementsModel: {
                    jobs_ID: job.jobs.jobs_ID,
                    candidates_ID: props.entity.candidates_ID,
                    status: 'Active',
                    users_ID: uInfo.users_ID
                },
                workflowItemsModel: activeWorkflowItems.find(element => element.order === 1),
                placementStatus: props.placementStatus
            }

            insertPlacement(postdata).then(data => { 
                setWorkflowStuff({
                    showWorkflowPane: true,
                    selectedJob: job.jobs,
                    selectedClient: job.clients,
                    selectedCandidate: data.candidates,
                    workflowItem: data
                });

                let localMatchedJobs = [...matchedJobs];
                let modifiedJob = localMatchedJobs[idx];
                modifiedJob.placements = data.placements;
                localMatchedJobs[idx] = modifiedJob;
                setMatchedJobs(localMatchedJobs);

                if(props.triggerRefresh) props.triggerRefresh();
            });
        }
    }

    function beginWorkflowFromJobs(candidate, idx) {
        if (candidate.placements?.placements_ID) {
            getEntityWithPlacementByCandidateAndJob(props.entity.jobs_ID, candidate.candidates.candidates_ID, candidate.candidates.users_ID).then(data => {
                setWorkflowStuff({
                    showWorkflowPane: true,
                    selectedJob: props.entity,
                    selectedClient: candidate.clients,
                    selectedCandidate: data.candidates,
                    workflowItem: data
                });
            });
        } else {
            let postdata = {
                placementsModel: {
                    jobs_ID: props.entity.jobs_ID,
                    candidates_ID: candidate.candidates.candidates_ID,
                    status: 'Active',
                    users_ID: uInfo.users_ID
                },
                workflowItemsModel: activeWorkflowItems.find(element => element.order === 1),
                placementStatus: props.placementStatus
            }

            insertPlacement(postdata).then(data => { 
                setWorkflowStuff({
                    showWorkflowPane: true,
                    selectedJob: props.entity,
                    selectedClient: candidate.clients,
                    selectedCandidate: data.candidates,
                    workflowItem: data
                });

                let localMatchedCandidates = [...matchedCandidates];
                let modifiedCandidate = localMatchedCandidates[idx];
                modifiedCandidate.placements = data.placements;
                localMatchedCandidates[idx] = modifiedCandidate;
                setMatchedCandidates(localMatchedCandidates);

                if(props.triggerRefresh) props.triggerRefresh();
            });
        }
    }

    function fullNameFormatter(row) {
        return (
            <span>{row.firstName} {row.lastName}</span>
        );
    }

    function jobMatchFormatter(cell, row, idx) {
        return (
            <>
                {row.placements?.placements_ID ?
                    <>
                        <button className="ant-btn ant-btn-default btn-sm" onClick={(job) => beginWorkflowFromCandidates(row)}><i className="fa-solid fa-shuffle"></i> View Existing Workflow</button>
                    </>
                    :
                    <>
                        <button className="ant-btn ant-btn-primary btn-sm" onClick={(job) => beginWorkflowFromCandidates(row, idx)}>Start Placement Workflow</button>
                    </>
                }
                {uInfo?.users_ID !== row.users_ID && 
                    <div><em>This <span className="candidate-text">candidate</span> is owned by {row.candidateRecruiter}</em></div>
                }    
                {uInfo?.users_ID !== props.entity?.users_ID && 
                    <div><em>This <span className="job-text">job</span> is owned by {row.jobRecruiter}</em></div>
                }
            </>
        );
    }

    function candidateMatchFormatter(cell, row, idx) {
        return (
            <>
                {row.placements?.placements_ID ?
                    <>
                        <button className="ant-btn ant-btn-default btn-sm" onClick={(cand) => beginWorkflowFromJobs(row)}><i className="fa-solid fa-shuffle"></i> View Existing Workflow</button>
                    </>
                    :
                    <>
                        <button className="ant-btn ant-btn-match btn-sm" onClick={(cand) => beginWorkflowFromJobs(row, idx)}>Start Placement Workflow</button>
                    </>
                }
                {uInfo?.users_ID !== row.users_ID && 
                    <div><em>This <span className="candidate-text">candidate</span> is owned by {row.candidateRecruiter}</em></div>
                }    
                {uInfo?.users_ID !== props.entity?.users_ID && 
                    <div><em>This <span className="job-text">job</span> is owned by {row.jobRecruiter}</em></div>
                }
            </>
        );
    }

    function specialtyFormatter(cell, row) {
        var entity;

        if(props.from === 'candidates') {
            entity = row.jobs;
        } else if(props.from === 'jobs') {
            entity = row.candidates;
        }
        return activeParentSpecialties.find(spec => spec.specialties_ID === entity?.specialty)?.specialty;
    }

    function subSpecialtyFormatter(cell, row) {
        var entity;

        if(props.from === 'candidates') {
            entity = row.jobs;
        } else if(props.from === 'jobs') {
            entity = row.candidates;
        }

        return activeSubSpecialties[entity?.specialty]?.find(spec => spec.specialties_ID === entity?.subSpecialty)?.specialty
    }

    function handleLinkExternalJob() {
        setAddingExternalJob(true);

        let postdata = {
            placementsModel: {
                candidates_ID: props.entity.candidates_ID,
                status: 'Active',
                users_ID: uInfo.users_ID
            },
            externalJob: getValues().externalJob,
            workflowItemsModel: activeWorkflowItems.find(element => element.order === 1),
            placementStatus: props.placementStatus
        }

        insertPlacement(postdata).then(data => { 

            // getCandidatesWithPlacementsByUser(uInfo.organizations_ID, uInfo.users_ID).then(activeCandidates => {
            //     setActiveUserCandidates(activeCandidates);
            // });

            setWorkflowStuff({
                showWorkflowPane: true,
                selectedJob: data.jobs,
                selectedClient: null,
                selectedCandidate: props.entity,
                workflowItem: data
            });

            let newJob = {
                jobs: data.jobs,
                placements: data.placements
            }

            let localMatchedJobs = [...matchedJobs];
            
            localMatchedJobs.push(newJob);

            setMatchedJobs(localMatchedJobs);

            setValue('externalJob.jobTitle', '');
            setValue('externalJob.addToMyJobs', true);
            setShowExternalJobModal(false);
            setAddingExternalJob(false);

            if(props.triggerRefresh) props.triggerRefresh();
        });
    }

    const jobColumns = [{
        dataIndex: ['clients', 'name'],
        title: 'Client'
    }, {
        dataIndex: ['jobs', 'jobTitle'],
        title: 'Job Title'
    }, {
        dataIndex: ['jobs', 'specialty'],
        title: 'Specialty',
        render: specialtyFormatter
    },
    {
        dataIndex: ['jobs', 'subSpecialty'],
        title: 'Sub Specialty',
        render: subSpecialtyFormatter
    }, {
        dataIndex: ['jobs', 'city'],
        title: 'Location',
        render: (cell, row) => cityStateFormatter(row.jobs)
    },
    {
        render: jobMatchFormatter,
        title: 'Match'
    }];

    const candidateColumns = [{
        dataIndex: ['clients', 'name'],
        title: 'Client'
    }, {
        dataIndex: ['candidates', 'firstName'],
        title: 'Candidate Name',
        render: (cell, row) => fullNameFormatter(row.candidates)
    }, {
        dataIndex: ['candidates', 'specialty'],
        title: 'Specialty',
        render: specialtyFormatter
    },
    {
        dataIndex: ['candidates', 'subSpecialty'],
        title: 'Sub Specialty',
        render: subSpecialtyFormatter
    },
    {
        dataIndex: ['candidates', 'city'],
        title: 'Location',
        render: (cell, row) => cityStateFormatter(row.candidates)
    },
    {
        render: candidateMatchFormatter,
        title: 'Match'
    }];

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title={props.from === 'candidates' ? `Matched Jobs For Candidate ${props.entity?.candidateFirstName} ${props.entity?.candidateLastName}` : `Matched Candidates For Job ${props.entity?.jobTitle}`}
            placement={'right'}
            onClose={handleClose}
            maskClosable={false}
            open={show}
            key='slider'
            width='75%'
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
            className={props.from === 'candidates' ? 'candidate' : 'job'}
        >
            {props.from === 'candidates' ? <p className="candidate-text">Candidate: {props.entity?.candidateFirstName} {props.entity?.candidateLastName}</p> : <p className="job-text">Job: {props.entity?.jobTitle}</p>}
        
            <p>Specialty: {props.entity?.parentSpecialty}</p>
            <p>Sub-Specialty: {props.entity?.subSpecialty}</p>

            {(props.from === 'candidates') && <button className="ant-btn ant-btn-jobs float-end mb-3" onClick={() => setShowExternalJobModal(true)}>Link External Job</button>}
            {loading ?
                <SkeletonTable columns={props.from === 'candidates' ? jobColumns : candidateColumns} rowCount={5} />
                :
                <>
                    {((props.from === 'candidates' && matchedJobs?.length > 0) || props.from === 'jobs' && matchedCandidates?.length > 0) ?
                    <Table
                        rowKey={(record) => props.from === 'candidates' ? record.jobs.jobs_ID : record.candidates.candidates_ID}
                        dataSource={props.from === 'candidates' ? matchedJobs : matchedCandidates}
                        columns={props.from === 'candidates' ? jobColumns : candidateColumns}
                        size='small'
                        pagination={{defaultPageSize: 15}}
                    />
                    :
                    <Empty description={props.from === 'candidates' ? <p>No Jobs Match This Candidate</p> : <p>No Candidates Match This Job</p>} />
                    }
                    
                </>
            }

            <Modal
                open={showExternalJobModal}
                title="External Job Workflow Creation"
                footer={[
                    <div className="text-start" key="1">
                        {addingExternalJob ?
                            <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                            :
                            <button className="ant-btn ant-btn-primary text-start" onClick={handleLinkExternalJob}>Add</button>
                        }
                        <button className="ant-btn ant-btn-default text-start" onClick={() => { setShowExternalJobModal(false); setAddingExternalJob(false); }}>Cancel</button>
                    </div>
                ]}
            >
                <div className="row">
                    <div className="col-12">
                        <label>Job Title</label>
                        <input className="ant-input" {...register('externalJob.jobTitle')} />
                    </div>

                    <div className="form-group col-lg-4 col-12">
                        <label className="d-block">Add Job to My Jobs</label>
                        <input type="checkbox" className="custom-checkbox" {...register('externalJob.addToMyJobs')} />
                    </div>
                </div>
            </Modal>

            <PlacementWorkflowPane show={workflowStuff?.showWorkflowPane} hidePane={hidePlacementPane} workflowItem={workflowStuff?.workflowItem} job={workflowStuff?.selectedJob} candidate={workflowStuff?.selectedCandidate} clientName={workflowStuff?.selectedClient?.name} placementStatus={props.placementStatus} triggerRefresh={() => {props.triggerRefresh && props.triggerRefresh()}} />
        </Drawer>
    );
}