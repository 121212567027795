import React, { useEffect, useState, useRef } from 'react';
import { Drawer, Radio, List, Slider, Result, Modal, Popover } from 'antd';
import { useRecoilValue } from 'recoil';

import { MobileOutlined } from '@ant-design/icons'
import { CandidateSearch } from './CandidateSearch';
import { MessageCampaignMembers } from './MessageCampaignMembers';
import { MapCampaign } from './MapCampaign';

import { useForm } from "react-hook-form";

import Globals from '../../config/globals';
import { insertCampaignAndMembers, getCampaignMembersByCampaign, updateCampaignAndMembers, userCampaigns, deleteCampaign } from '../../services/CampaignService';
import { getCandidatesByCampaign, getCandidatesByGroup } from '../../services/CandidateService';

import { getSMSOnLoadInfoForCampaign } from '../../services/SmsService';

import { toast, confirm } from '@rickylandino/react-messages';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { getUsersByOrganization } from '../../services/OrganizationsService';

export function ManageCampaign(props) {
    const { register, watch, setValue, getValues } = useForm();

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const [radioOption, setRadioOption] = useState('Candidates');

    const [availableCandidates, setAvailableCandidates] = useState([]);

    const [members, setMembers] = useState({
        selectedCandidates: [],
        selectedGroups: [],
        selectedUsers: [],
        selectedOrganizations: []
    });

    const [showMessenger, setShowMessenger] = useState(false);

    const [filterOptions, setFilterOptions] = useState({
        radius: 10
    });

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [canUpdate, setCanUpdate] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const [campaignId, setCampaignId] = useState(props.selectedCampaign?.campaigns_ID || null);

    const [CONSTANTS, setConstants] = useState({
        entity: 'Candidate',
        entityPlural: 'Candidates',
        groupOrOrg: 'Group',
        groupOrOrgPlural: 'Groups'
    });

    //const [selectedCandidates, setSelectedCandidates] = useState([]);

    const [syncModal, setSyncModal] = useState({
        show: false
    });

    const [removeGroupModal, setRemoveGroupModal] = useState({
        show: false,
        groupToRemove: {}
    });

    var isDirty = useRef(false);

    useEffect(() => {
        if (props.showModal) {
            if (props.selectedCampaign) {
                setValue('campaignName', props.selectedCampaign.campaignName);
                getCampaignMembersByCampaign(props.selectedCampaign.campaigns_ID).then(data => {
                    console.log(data);
                    setMembers({
                        selectedCandidates: data.candidates || [],
                        selectedUsers: data.users || [],
                        selectedOrganizations: data.organizations || []
                        //selectedGroups: data.groups
                    });

                    //setSelectedCandidates(data.candidates);
                });
            }

            setModalInfo({
                show: true
            });

            setValue('radius', 10);

            const canBeUpdated = (uPermissions?.updateCampaigns === 'company' || (uPermissions?.updateCampaigns === 'personal' && uInfo?.users_ID === props?.selectedCampaign?.users_ID)) || (uPermissions?.createCampaigns && !props?.selectedCampaign?.campaigns_ID);
            const canBeDeleted = (uPermissions?.deleteCampaigns === 'company' || (uPermissions?.deleteCampaigns === 'personal' && uInfo?.users_ID === props?.selectedCampaign?.users_ID));

            setCanUpdate(canBeUpdated);
            setCanDelete(canBeDeleted);
        } else {
            setModalInfo({
                show: false
            });
        }
    }, [props.showModal]);

    useEffect(() => {
        switch (uInfo.userType) {
            case 'SuperAdmin':
                setConstants({
                    entity: 'User',
                    entityPlural: 'Users',
                    groupOrOrg: 'Organization',
                    groupOrOrgPlural: 'Organizations'
                });
                break;
            default:
                break;
        }
    }, [uInfo]);

    useEffect(() => {
        let ac = availableCandidates.map(val => ({
            ...val,
            isSelected: members?.selectedCandidates.find(arr1Obj => arr1Obj.candidates.candidates_ID === val.candidates.candidates_ID) ? true : false
        }));

        setAvailableCandidates(ac);
    }, [members]);

    const subscription = watch((value, { name, type }) => {

        if (name === 'radius') {
            setFilterOptions({ ...filterOptions, radius: value.radius });
        }

        if (name === 'campaignName') {
            if (type)
                isDirty.current = true;
        }
    });

    function handleSubmit() {

        //let mergedMembers = members?.selectedCandidates.concat(members.selectedGroups);
        //let mergedMembers = members?.selectedCandidates.concat([]).concat([members.selectedUsers]);
        let mergedMembers = [...members?.selectedCandidates, ...members?.selectedUsers, ...members?.selectedOrganizations];

        let campaignMembers = mergedMembers.map(item => {
            if (item.candidates?.candidates_ID) {
                return {
                    entity_ID: item.candidates.candidates_ID,
                    entityType: "Candidate",
                    groups_ID: item.groups_ID ? item.groups_ID : null,
                    toRemove: item.toRemove ? true : false
                };
            } else if (item.user?.users_ID) {
                return {
                    entity_ID: item.user.users_ID,
                    entityType: "User",
                    organizations_ID: item.organizations_ID ? item.organizations_ID : null,
                    toRemove: item.toRemove ? true : false
                }
            } else if (item.organizations_ID) {
                return {
                    entity_ID: item.organizations_ID,
                    entityType: "Organization",
                    groups_ID: item.groups_ID ? item.groups_ID : null,
                    toRemove: item.toRemove ? true : false
                }
            } else {
                return {
                    entity_ID: item.groups_ID,
                    entityType: "Group",
                    toRemove: item.toRemove ? true : false
                }
            }
        });

        if (getValues().campaignName) {
            let postdata = {
                campaignModel: {
                    campaignName: getValues().campaignName,
                    users_ID: Globals.userInfo.users_ID
                },
                campaignMembers
            }

            if (props.selectedCampaign) {
                postdata.campaignModel.campaigns_ID = props.selectedCampaign.campaigns_ID;
                updateCampaignAndMembers(postdata).then(data => {

                    if (data === 'failed') {
                        toast.error("Something went wrong");
                    } else if (data === 'duplicate') {
                        toast.error("This campaign name already exists.");
                    } else {
                        if (data?.campaigns) {
                            userCampaigns.next(data.campaigns);
                            toast.success("Campaign has been updated");

                            isDirty.current = false;
                        }
                        if (data?.campaignMembers) {
                            setMembers({
                                ...members,
                                selectedCandidates: data.campaignMembers.candidates
                                //selectedGroups: data.groups
                            });
                        } else {
                            userCampaigns.next(data?.campaigns);
                            toast.success("Campaign has been added");
                            setModalInfo({
                                ...modalInfo,
                                show: false
                            });

                            setTimeout(() => props.hideModal(), 500);
                        }
                    }
                });
            } else {
                insertCampaignAndMembers(postdata).then(data => {
                    if (data === 'failed') {
                        toast.error("Something went wrong");
                    } else if (data === 'duplicate') {
                        toast.error("This campaign name already exists.");
                    } else {
                        if (data?.campaigns) {
                            userCampaigns.next(data.campaigns);
                            toast.success("Campaign has been added");

                            isDirty.current = false;
                        }
                        if (data?.campaignMembers) {
                            setMembers({
                                ...members,
                                selectedCandidates: data.campaignMembers.candidates
                                //selectedGroups: data.groups
                            });
                        } else {
                            userCampaigns.next(data?.campaigns);
                            toast.success("Campaign has been added");
                            setModalInfo({
                                ...modalInfo,
                                show: false
                            });

                            setTimeout(() => props.hideModal(), 500);
                        }
                        if (data?.campaigns_ID) {
                            setCampaignId(data.campaigns_ID);
                        }

                    }
                });
            }
        } else {
            toast.warning("Please fill in all required fields");
        }
        

    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You are about to lose all changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    setModalInfo({
                        show: false
                    });

                    setTimeout(() => {
                        props.hideModal();
                    }, 1000);

                    return 0;
                } else {
                    return 0;
                }
            });
        } else {
            setModalInfo({
                show: false
            });

            setTimeout(() => {
                props.hideModal();
            }, 1000);
        }
    }

    function handleRadioChange({ target: { value } }) {
        setRadioOption(value);
    };

    function updateCandidatesFromMap(candidateList) {
        let ac = [];

        if (candidateList) {
            ac = candidateList?.map(val => ({
                ...val,
                isSelected: members?.selectedCandidates.find(arr1Obj => arr1Obj.candidates.candidates_ID === val.candidates.candidates_ID) ? true : false
            }));
        }

        isDirty.current = true;

        setAvailableCandidates(ac);
        //setAvailableCandidates(candidateList);
    }

    function openMessenger() {
        getSMSOnLoadInfoForCampaign(campaignId);

        setShowMessenger(true);
    }

    function addCandidate(item, from = null) {
        isDirty.current = true;

        console.log(item);

        if (item.groupinfo) {
            getCandidatesByGroup(item.groupsid).then(data => {
                var ids = new Set(members.selectedCandidates.map(c => c.candidates.candidates_ID));
                var groupCandidateIds = new Set(data.map(c => c.candidates.candidates_ID));
                var dataWithGroups = data.map(obj => ({ ...obj, groups_ID: item.groupsid }));

                var selectedCandidates = [...members.selectedCandidates, ...dataWithGroups.filter(d => !ids.has(d.candidates.candidates_ID))];
                //var selectedCandidates = [...members.selectedCandidates, ...dataWithGroups];

                selectedCandidates.forEach(sc => {
                    if (groupCandidateIds.has(sc.candidates.candidates_ID)) {
                        sc.groups_ID = item.groupsid;
                    }
                });

                console.log(selectedCandidates);
                
                setMembers({
                    ...members,
                    selectedCandidates
                });
            });
        } else if (item.userinfo || item.user) {
            if (from === 'orgSync') {
                let userList = syncModal.notSelectedButInGroup;
                userList.forEach(c => {
                    c.added = true;
                });

                setSyncModal({
                    ...syncModal,
                    notSelectedButInGroup: userList
                });

                setMembers({
                    ...members,
                    selectedUsers: [
                        ...members.selectedUsers,
                        item
                    ]
                });
            } else {
                setMembers({
                    ...members,
                    selectedUsers: [
                        ...members.selectedUsers,
                        item.userinfo
                    ]
                });
            }
        } else if (item.orginfo) {
            //perform similar to groupinfo above
            getUsersByOrganization(item.orgid).then(data => {
                var ids = new Set(members.selectedUsers?.map(o => o.organizations_ID));
                var groupOrgIds = new Set(data.map(o => o.organizations_ID));
                var dataWithGroups = data.map(obj => ({ ...obj, organizations_ID: item.orgid }));

                var selectedUsers = [...members.selectedUsers, ...dataWithGroups.filter(d => !ids.has(d.organizations_ID))];

                selectedUsers.forEach(so => {
                    if (groupOrgIds.has(so.organizations_ID)) {
                        so.organizations_ID = item.orgid;
                    }
                });

                setMembers({
                    ...members,
                    selectedOrganizations: [
                        ...members.selectedOrganizations,
                        item.orginfo
                    ],
                    selectedUsers
                });
            });
        } else {
            if (from === 'groupSync') {
                let candList = syncModal.notSelectedButInGroup;
                candList.forEach(c => {
                    c.added = true;
                });

                setSyncModal({
                    ...syncModal,
                    notSelectedButInGroup: candList
                });

                setMembers({
                    ...members,
                    selectedCandidates: [
                        ...members.selectedCandidates,
                        item
                    ]
                });
            } else {
                setMembers({
                    ...members,
                    selectedCandidates: [
                        ...members.selectedCandidates,
                        item.candidateinfo
                    ]
                });
            }
        }
    }

    function removeCandidate(item) {
        isDirty.current = true;

        let selectedCandidates = [...members?.selectedCandidates];

        item.toRemove = true;
        let idx = selectedCandidates.findIndex(sc => sc.candidates.candidates_ID === item.candidates.candidates_ID);
        selectedCandidates[idx] = item;

        setMembers({
            ...members,
            selectedCandidates
        });
        
    }

    function removeAllCandidatesFromModal() {

        let candList = syncModal.inGroupAndSelected;
        let selectedCandidates = [...members?.selectedCandidates];

        candList.forEach(c => {
            c.toRemove = true;
            let idx = selectedCandidates.findIndex(sc => sc.candidates.candidates_ID === c.candidates.candidates_ID);
            selectedCandidates[idx] = c;
        });

        setMembers({
            ...members,
            selectedCandidates
        });

        setSyncModal({
            ...syncModal,
            inGroupAndSelected: candList
        });
    }

    function addAllCandidatesFromModal() {
        isDirty.current = true;

        let candList = syncModal.notSelectedButInGroup;
        let selectedCandidates = [...members?.selectedCandidates];
        let selectedUsers = [...members?.selectedUsers];

        candList.forEach(c => {
            c.added = true;

            selectedCandidates = [
                ...selectedCandidates,
                c
            ]

            selectedUsers = [
                ...selectedUsers,
                c
            ]
        });

        setSyncModal({
            ...syncModal,
            notSelectedButInGroup: candList
        });

        setMembers({
            ...members,
            selectedCandidates,
            selectedUsers
        });
    }

    function syncAllFromModal() {
        let notSelectedList = syncModal.notSelectedButInGroup;
        let selectedList = syncModal.inGroupAndSelected;
        let selectedCandidates = [...members?.selectedCandidates];

        selectedList.forEach(c => {
            c.toRemove = true;
            let idx = selectedCandidates.findIndex(sc => sc.candidates.candidates_ID === c.candidates.candidates_ID);
            selectedCandidates[idx] = c;
        });

        notSelectedList.forEach(c => {
            c.added = true;

            selectedCandidates = [
                ...selectedCandidates,
                c
            ]
        });

        setSyncModal({
            ...syncModal,
            notSelectedButInGroup: notSelectedList,
            inGroupAndSelected: selectedList,
            show: false
        });

        setMembers({
            ...members,
            selectedCandidates
        });
    }

    function syncGroup(item) {

        getCandidatesByGroup(item.groups_ID).then(data => {
            var selectedIds = new Set(members.selectedCandidates.filter(sc => !sc.toRemove).map(c => c.candidates.candidates_ID));
            var groupIds = new Set(data.map(c => c.candidates.candidates_ID));

            setSyncModal({
                show: true,
                notSelectedButInGroup: data.filter(d => !selectedIds.has(d.candidates.candidates_ID)),
                inGroupAndSelected: members.selectedCandidates.filter(d => groupIds.has(d.candidates.candidates_ID))
            });
        });
    }

    function syncOrganization(item) {

        getUsersByOrganization(item.organizations_ID).then(data => {
            var selectedIds = new Set(members.selectedUsers.filter(sc => !sc.toRemove).map(c => c.user.users_ID));
            var groupIds = new Set(data.map(o => o.user.users_ID));

            setSyncModal({
                show: true,
                notSelectedButInGroup: data.filter(d => !selectedIds.has(d.user.users_ID)),
                inGroupAndSelected: members.selectedUsers.filter(d => groupIds.has(d.user.users_ID))
            });
        });
    }

    function removeGroup(e) {
        isDirty.current = true;

        let item = removeGroupModal.groupToRemove;
        //let selectedGroups = [...members?.selectedGroups];
        let selectedCandidates = [...members?.selectedCandidates];
        let selectedUsers = [...members?.selectedUsers];

        if (e.target.id === 'removeAll') {
            selectedCandidates.forEach(sc => {
                if (sc.groups_ID === item.groups_ID) {
                    sc.toRemove = true
                }
            });

            selectedUsers.forEach(su => {
                if (su.user.organizations_ID === item.organizations_ID) {
                    su.toRemove = true
                }
            });
        }

        item.toRemove = true;
        //let idx = selectedGroups.findIndex(sc => sc.groups_ID === item.groups_ID);
        //selectedGroups[idx] = item;

        setMembers({
            ...members,
            selectedCandidates,
            selectedUsers
        });

        setRemoveGroupModal({
            show: false,
            groupToRemove: {}
        });
    }

    function handleDeleteCampaign() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this campaign.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = {
                    id: props.selectedCampaign.campaigns_ID,
                    id2: uInfo.users_ID
                }
                deleteCampaign(postdata).then(data => {
                    if (data) {
                        userCampaigns.next(data);

                        toast.success("Campaign has been deleted");
                    } else {
                        toast.error("Something went wrong");
                    }

                    isDirty.current = false;
                    handleClose();
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    // const options = [
    //     {
    //         label: CONSTANTS.entityPlural,
    //         value: CONSTANTS.entityPlural,
    //     },
    //     {
    //         label: 'Location',
    //         value: 'Location',
    //     }
    // ];

    const options = [
        {
            label: CONSTANTS.entityPlural,
            value: CONSTANTS.entityPlural,
        }
    ];

    if (uInfo.userType === 'SuperAdmin') {
        let idx = options.findIndex(o => o.label === 'Location');
        options.splice(idx, 1);
    }
    

    //function getGroupName(groups_ID) {
    //    return members?.selectedGroups?.find(g => g.groups_ID === groups_ID)?.group
    //}


    var disableMessageButton = !campaignId ? true : false;
    var disableButtonText = !campaignId ? 'Please save this campaign first' : '';

    if (uInfo.userType === 'SuperAdmin') {
        if (!disableMessageButton && members?.selectedUsers?.filter(sc => sc.user.cellPhone).length === 0) {
            disableMessageButton = true;
        }

        
    } else {
        if (!disableMessageButton && members?.selectedCandidates?.filter(sc => sc.smsNumber).length === 0) {
            disableMessageButton = true;
        }

        if (disableButtonText === '' && members?.selectedCandidates?.filter(sc => sc.smsNumber).length === 0) {
            disableButtonText = 'No candidates in this group can currently be messaged.';
        }
    }

    var disableButtonText = (members?.selectedCandidates?.filter(sc => sc.smsNumber).length > 0 ? '' : 'No candidates in this group can currently be messaged.');

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Manage Campaign'
            placement={'right'}
            onClose={handleClose}
            open={modalInfo.show}
            key='campaignSlider'
            width={'100%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        {canUpdate && <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>}
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>

                    {canDelete &&
                        <div className="float-end">
                            <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteCampaign}><i className='far fa-trash-alt'></i> &nbsp; Delete this Campaign</button>
                        </div>
                    }
                </div>
            }
        >
                <div className="row align-items-center">

                    <div className="form-group col-12">
                        <label className="form-label">Campaign Name <span className="text-danger">*</span></label>
                        <input maxLength="250" type="text" className="ant-input" {...register("campaignName")} />
                    </div>

                    <div className="horizontal-campaigns-divider" />

                    <div className="col-12">
                    <div>
                        {uInfo.userType !== 'SuperAdmin' &&
                            <div>
                                <h3>Search By</h3>
                                <Radio.Group options={options} onChange={handleRadioChange} value={radioOption} />
                            </div>
                        }
                            {radioOption === 'Candidates' &&
                            <div className=" pt-4">
                                <CandidateSearch selectedCandidates={members?.selectedCandidates} selectedCampaign={props.selectedCampaign} selectedGroups={[]} selectedUsers={members?.selectedUsers} selectedOrganizations={members?.selectedOrganizations} addCandidate={addCandidate} users_ID={props.users_ID} />
                                </div>
                            }

                            {radioOption === 'Location' &&
                                <>
                                    <div className="pt-4">
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-label">Filter By</div>
                                        <div className="horizontal-campaigns-divider" />
                                                <div className="form-group">
                                                    <div className="form-label">Radius (Miles)</div>
                                                    <div className="d-flex">
                                                        <Slider min={5} max={100} step={5} className="w-75" onChange={(value) => setValue('radius', value)} value={filterOptions.radius} />
                                                        <input type="number" min={5} max={100} className="ant-input w-25" {...register('radius')} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                <div className="horizontal-campaigns-divider" />

                                        <div className="row">
                                            <div className="col col-lg-4 col-12">
                                                <List
                                                    size="small"
                                                    header={<div>Search Results <span className="float-right">{availableCandidates.length} Candidates</span></div>}
                                                    style={{ height: '50vh', overflowY: 'auto' }}
                                                    bordered
                                                    rowKey="candidates.candidates_ID"
                                                    dataSource={availableCandidates}
                                                    // renderItem={item => <List.Item>{item.candidates.firstName}</List.Item>}
                                                    renderItem={item => (
                                                        <List.Item
                                                            actions={item.isSelected ? [<em key="1" className="text-success">Selected</em>] : [<a onClick={() => {
                                                                setMembers({
                                                                    ...members,
                                                                    selectedCandidates: [
                                                                        ...members.selectedCandidates,
                                                                        item
                                                                    ]
                                                                })
                                                            }} key="list-loadmore-edit" className="a hover">Select</a>]}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={item?.smsNumber && <MobileOutlined className="icon-sm text-success" />}
                                                                title={<span>{item.candidates.firstName} {item.candidates.lastName}</span>}
                                                                description={(item.candidates.city && item.candidates.state) && item.candidates.city + ", " + item.candidates.state}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </div>
                                            <div className="col col-lg-8 col-12">
                                                <MapCampaign updateCandidates={updateCandidatesFromMap} filterOptions={filterOptions} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                </div>

                    <div className="horizontal-campaigns-divider" />

                {uInfo.userType === 'SuperAdmin' ?
                    <>
                    <div className="col-lg-8">
                        <List
                            itemLayout="horizontal"
                            rowKey="usres.users_ID"
                            size="small"
                            pagination={{
                                pageSize: 20,
                                position: 'bottom'
                            }}
                            header={<div key="1" className="d-flex justify-content-between">
                                <div>
                                    <h3 key="1">
                                        <em className="text-users">Selected Users: {members?.selectedUsers.filter(sc => !sc.toRemove).length}</em>
                                    </h3>
                                    <div>
                                        <MobileOutlined className="icon-sm text-success" /> &nbsp; = This user has a valid cell # that can be used for SMS.
                                    </div>
                                </div>
                                <button className="ant-btn"
                                    onClick={openMessenger}
                                    disabled={disableMessageButton}
                                    title={disableButtonText}
                                >
                                    Message Selected Users
                                </button>
                            </div>}
                            dataSource={members?.selectedUsers}
                            renderItem={item => !item?.toRemove && (
                                <List.Item
                                    key={item?.users?.users_ID}
                                    actions={[<a key="list-loadmore-more" className="a hover" onClick={() => removeCandidate(item)}>Remove</a>]}
                                >
                                    <List.Item.Meta
                                        avatar={item?.user.cellPhone && <MobileOutlined className="icon-sm text-success" />}
                                        title={<><b className={!item?.user.cellPhone ? 'ml-4' : ''}>{item?.user?.firstName + " " + item?.user?.lastName}</b> <span></span></>}
                                    />
                                </List.Item>
                            )}
                        />
                        </div>
                        <div className="col-lg-4 align-self-start">
                            <List
                                itemLayout="horizontal"
                                rowKey="organizations_ID"
                                size="small"
                                pagination={{
                                    pageSize: 20,
                                }}
                                header={<h3 className="d-flex justify-content-between">
                                    <em className="text-groups">Associated Organizations: {members?.selectedOrganizations?.filter(sg => !sg.toRemove).length}</em>

                                    <Popover placement="right" content={<p>If users are added to an organization, it will not update the selected users in the associated campaign.
                                        You can check manually for changes by clicking on 'Sync', for each associated organization.</p>}>
                                            <i className="fa-solid fa-circle-info text-warning float-right hover"></i>
                                        </Popover>
                                </h3>}
                                dataSource={members?.selectedOrganizations}
                                renderItem={item => !item?.toRemove && (
                                    <List.Item
                                        key={item?.organizations_ID}
                                        actions={[<a key="list-loadmore-edit" className="a hover" onClick={() => syncOrganization(item)}>Sync</a>, <a key="list-loadmore-more" className="a hover" onClick={() => setRemoveGroupModal({ show: true, groupToRemove: item })}>Remove</a>]}
                                    >
                                        <List.Item.Meta
                                            title={<b>{item?.name}</b>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </>
                    :
                    <div className="col-lg-8">
                        <List
                            itemLayout="horizontal"
                            rowKey="candidates.candidates_ID"
                            size="small"
                            pagination={{
                                pageSize: 20,
                                position: 'bottom'
                            }}
                            header={<div key="1" className="d-flex justify-content-between">
                                <div>
                                    <h3 key="1">
                                        <em className="text-candidates">Selected Candidates: {members?.selectedCandidates.filter(sc => !sc.toRemove).length}</em>
                                    </h3>
                                    <div>
                                        <MobileOutlined className="icon-sm text-success" /> &nbsp; = This candidate has a valid cell # that can be used for SMS.
                                    </div>
                                </div>
                                <button className="ant-btn"
                                    onClick={openMessenger}
                                    disabled={disableMessageButton}
                                    title={disableButtonText}
                                >
                                    Message Selected Candidates
                                </button>
                            </div>}
                            dataSource={members?.selectedCandidates}
                            renderItem={item => !item?.toRemove && (
                                <List.Item
                                    key={item?.candidates?.candidates_ID}
                                    actions={[<a key="list-loadmore-more" className="a hover" onClick={() => removeCandidate(item)}>Remove</a>]}
                                >
                                    <List.Item.Meta
                                        avatar={item?.smsNumber && <MobileOutlined className="icon-sm text-success" />}
                                        title={<><b className={!item?.smsNumber ? 'ml-4' : ''}>{item?.candidates?.firstName + " " + item?.candidates?.lastName}</b> <span></span></>}
                                        description={(item.parentSpecialty && item.subSpecialty) && <span className={!item?.smsNumber ? 'ml-4' : ''}>{item?.parentSpecialty + ", " + item?.subSpecialty}</span>}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                }
                    
                </div>

            {showMessenger && <MessageCampaignMembers hideModal={() => setShowMessenger(false)} showModal={showMessenger} selectedCandidates={members?.selectedCandidates} selectedUsers={members?.selectedUsers} />}

            <Modal
                open={syncModal.show}
                title={`${CONSTANTS.groupOrOrg} & ${CONSTANTS.entity} Sync`}
                onCancel={() => setSyncModal({ show: false })}
                footer={[
                    //<span key="sync" className="a hover float-left" style={{ marginTop: '10px' }} onClick={syncAllFromModal}>Sync All</span>,
                    <button key="cancel" className="ant-btn btn-outline-primary" onClick={() => setSyncModal({show: false})}>Close</button>
                ]}
                width={750}
            >
                <div className="d-flex justify-content-center">
                    {syncModal?.notSelectedButInGroup?.filter(c => !c.added).length > 0 &&
                        <List
                            size="small"
                            className="w-50"
                            header={<div><u>{CONSTANTS.groupOrOrg} {CONSTANTS.entityPlural} not selected</u><a className="float-end a hover" onClick={addAllCandidatesFromModal}>Select All</a></div>}
                            bordered
                            dataSource={syncModal?.notSelectedButInGroup}
                            renderItem={item => !item?.added && 
                                (
                                <>
                                    {uInfo.userType === 'SuperAdmin' ?
                                        <List.Item actions={[<a key="list-loadmore-edit" className="a hover" onClick={() => addCandidate(item, 'orgSync')}>Select</a>]}>
                                            {item.user.firstName} {item.user.lastName}
                                        </List.Item>
                                        :
                                        <List.Item actions={[<a key="list-loadmore-edit" className="a hover" onClick={() => addCandidate(item, 'groupSync')}>Select</a>]}>
                                            {item.candidates.firstName} {item.candidates.lastName}
                                        </List.Item>        
                                        }
                                
                                    </>
                            )
                            }
                        />
                    }

                    {(syncModal?.notSelectedButInGroup?.filter(c => !c.added).length <= 0) &&
                        <Result
                        status="success"
                        subTitle={`This ${CONSTANTS.groupOrOrg} is in sync`}
                        />
                    }
                </div>
            </Modal>

            <Modal
                open={removeGroupModal.show}
                title={`Confirm Remove ${CONSTANTS.groupOrOrg}`}
                onCancel={() => setRemoveGroupModal({ show: false })}
                width={700}
                footer={[
                    <button id="removeAll" key="removeAll" className="ant-btn ant-btn-primary float-start" onClick={removeGroup}>Yes, remove {CONSTANTS.groupOrOrg} and members</button>,
                    <button id="removeGroup" key="removeGroup" className="ant-btn ant-btn-primary float-start" onClick={removeGroup}>No, remove {CONSTANTS.groupOrOrg} ONLY</button>,
                    <button key="cancel" className="ant-btn" onClick={() => setRemoveGroupModal({ show: false })}>Cancel</button>
                ]}
            >
                <p>
                    Would you like to remove this {CONSTANTS.groupOrOrg} and all of the {CONSTANTS.groupOrOrg} members?
                </p>
            </Modal>
        </Drawer>
    );
}