import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Route, useLocation, Routes } from 'react-router-dom';
import './App.less';

import { Login } from './components/Auth/Login';
import { toast } from '@rickylandino/react-messages';

import { Dashboard } from './components/Dashboard/Dashboard';
import { Layout } from './components/Layout';
import { CandidatesHome } from './components/Candidates/CandidatesHome';
import Globals from './config/globals';
import { getOrganizationInfo, updateOrgInfo } from './services/OrganizationsService';
import { CandidateDashboard } from './components/Candidates/CandidateDashboard';
import DetectActivity from './helpers/DetectActivity';
import { Messenger } from './components/SMS/Messenger';
import { CampaignDashboard } from './components/Campaigns/CampaignDashboard';
import { AccountSettingsHome } from './components/AccountSettings/AccountSettingsHome';
import { activeRoute, userInfo, activeUserInfo, userPermissions, activeUserPermissions } from './services/UsersService';
import { getRecruiterbalmNumbers, bandwidthNumbers } from './services/SmsService';
import { validateUserByUid } from './services/TokenService';
import { ResetPassword } from './components/Auth/ResetPassword';
import { OrganizationsHome } from './components/Organizations/OrganizationsHome';
import { OrganizationDashboard } from './components/Organizations/OrganizationDashboard';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { getVersion, insertNewVersion } from './services/CachingService';
import { ReleaseNotes } from './components/AccountSettings/ReleaseNotes';
import { EmailDashboard } from './components/Email/EmailDashboard';
import { AdminHome } from './components/Admin/AdminHome';
import { ClientsHome } from './components/Clients/ClientsHome';
import { ClientDashboard } from './components/Clients/ClientDashboard';
import { JobsHome } from './components/Jobs/JobsHome';
import { JobDashboard } from './components/Jobs/JobDashboard';
import FAQ from './components/FAQ/faq';

function App(props) {
    const location = useLocation();
    //const [uInfo, setUInfo] = useState({});
    const [refreshCache, setRefreshCache] = useState(false);

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const [uPermissions, setUPermissions] = useRecoilState(activeUserPermissions);
    const setBandwidthNumbers = useSetRecoilState(bandwidthNumbers);


    useEffect(() => {
        getVersionInformation();

        window.sessionStorage.setItem("theme", "light");
        if (window.sessionStorage.getItem("uid")) {
            validateUserByUid(window.sessionStorage.getItem("uid")).then(data => {

                if (data?.tokenResponse) {
                    Globals.bearer = data.tokenResponse.accessToken;
                }

                if (data?.user) {
                    Globals.userInfo = data.user.user;
                    userInfo.next(data.user.user);
                    userPermissions.next(data.user.userPermissions);

                    //recoiljs implementation
                    setUInfo(data.user.user);
                    setUPermissions(data.user.userPermissions);
                }

                if (data?.organizationInfo) {
                    updateOrgInfo(data.organizationInfo);
                }

                if (data?.colors) {
                    Globals.colors = data.colors;
                }

                if (data?.bandwidthNumbers) {
                    setBandwidthNumbers(data.bandwidthNumbers);
                }

                // Globals.userInfo = data.user.user;
                // userInfo.next(data.user.user);
                // userPermissions.next(data.user.userPermissions);
                // Globals.colors = data.colors;

                // if (data?.bandwidthNumbers) {
                //     setBandwidthNumbers(data.bandwidthNumbers);
                // }

                // //Globals.bearer = data.token;

                // setUInfo(data.user.user);
                // setUPermissions(data.user.userPermissions);

                // updateOrgInfo(data.organizationInfo);
            });
        }

    }, []);

    //useEffect(() => {
    //    var alive = true;

    //    combineLatest(
    //        userInfo
    //    ).pipe(takeWhile(() => alive)).subscribe(([userInfo]) => {
    //        if (userInfo.users_ID) {
    //            setUInfo(userInfo);
    //        }
    //    });

    //    return () => alive = false;
    //}, []);

    useEffect(() => {
        activeRoute.next(location.pathname);
    }, [location]);

    async function getVersionInformation() {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response?.json();

        if (!window.sessionStorage.getItem("cacheRefreshed")) {
            getVersion().then(data => {
                if (data && !data.isAxiosError) {
                    if (`${meta?.version}` !== data?.cacheVersion?.version) {
                        toast.loading("Update in progress");
                        setRefreshCache(true);

                        let postdata = {
                            version: meta.version,
                            webVersion: data.cacheVersion?.webVersion
                        }

                        insertNewVersion(postdata).then(data2 => {
                            window.sessionStorage.setItem("cacheRefreshed", true);
                            props.emptyCache();
                        });
                    }
                    else if (window?.localStorage?.APP_VERSION && (window.localStorage.APP_VERSION !== data.cacheVersion?.version && !props?.isLatestVersion)) {
                        toast.loading("Update in progress");
                        setRefreshCache(true);

                        window.sessionStorage.setItem("cacheRefreshed", true);
                        props.emptyCache();
                    }

                } else {
                    toast.warning("Cache Refresh Failed");
                }
            }).catch(e => {
                toast.warning("Cache Refresh Failed");
            });
        } else {
            console.log("Cache Refreshed");
        }
        
    }

    function getRBNumbers() {
        getRecruiterbalmNumbers().then(data => {
            setBandwidthNumbers(data);
        });
    }
    
    return (
        <div className="App main fade-in h-100">
            {(location.pathname === '/' || location.pathname === '/reset-password') ?
                (
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                    </Routes>
                ) :
                <>
                    {uInfo.users_ID &&
                        <Layout>
                            <DetectActivity>
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                    <Route path="/candidates" element={<CandidatesHome />} />
                                    <Route path="/candidate-dashboard/:candidates_ID" element={<CandidateDashboard />} />
                                    <Route path="/clients" element={<ClientsHome />} />
                                    <Route path="/client-dashboard/:clients_ID" element={<ClientDashboard />} />
                                    <Route path="/jobs" element={<JobsHome />} />
                                    <Route path="/job-dashboard/:jobs_ID" element={<JobDashboard />} />
                                    <Route path="/sms-dashboard" element={<Messenger />} />
                                    <Route path="/campaigns" element={<CampaignDashboard />} />
                                    <Route path="/account-settings" element={<AccountSettingsHome />} />
                                    <Route path="/organizations" element={<OrganizationsHome />} />
                                    <Route path="/organization-dashboard" element={<OrganizationDashboard />} />
                                    <Route path="/release-notes" element={<ReleaseNotes />} />
                                    <Route path="/email-dashboard" element={<EmailDashboard />} />
                                    <Route path="/admin-settings" element={<AdminHome />} />
                                    <Route path="/faq" element={<FAQ />} />
                                </Routes>

                            </DetectActivity>
                        </Layout>
                    }
                </>
            }

            {refreshCache && <div className="refresh-overlay" />}
        </div>
    );
}

export default App;
